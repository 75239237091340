import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Base64 } from "js-base64";
import moment from "moment";
import config from "@client.config";
import DeviceCaptureStatuses from "@client.enums/deviceCaptureStatuses";
import Loader from "@client.components/Loader";
import "./DeviceSlider.scss";
import Icon from "@client.components/Icon";
import ServiceProvider from "@client.services/provider";
import { MODAL_EVENT } from "@client.components/Modal";

const DeviceSlider = ({ data, status }) => {
  const [sliderValue, setSliderValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState("");
  const [imageAlt, setImageAlt] = useState();

  const imageData = data.results;

  const handleSliderChange = (event) => {
    setSliderValue(event.target.value);
  };

  useEffect(() => {
    if (!data.loading) {
      setIsLoading(true);
      const currentItem = imageData[sliderValue];
      const base64 =
        status === DeviceCaptureStatuses.NotProcessed
          ? currentItem.beforeBase64
          : currentItem.afterBase64;
      try {
        if (base64) {
          const decoded = Base64.toUint8Array(base64);
          const blob = new Blob([decoded], { type: "image/jpeg" });
          const imageUrl = URL.createObjectURL(blob);

          // Update state with new image URL, device name, and status
          setImageUrl(imageUrl);
          setImageAlt("Image");
        }
      } catch (error) {
        console.error("Error decoding Base64 data:", error);
      } finally {
        setIsLoading(false); // Set loading to false when data is fetched
      }
    }
  }, [sliderValue, imageData, data.loading]);

  if (data.loading) {
    return (
      <div className="device-slider-container">
        <Loader loading={true} />
      </div>
    );
  }

  return (
    <div className="device-slider-container">
      <div className="image-container">
        {isLoading ? (
          <Loader loading={true} className="image-loader" />
        ) : (
          <>
            <Icon
              name="expand"
              onClick={() => {
                ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
                  show: true,
                  title: moment(imageData[sliderValue].createdAt).format(
                    config.dateTimeSecondsFormat
                  ),
                  className: "preview-image-modal",
                  component: <img src={imageUrl} alt={imageAlt} />,
                });
              }}
            />
            <img
              src={imageUrl}
              alt={imageAlt}
              className="container-fluid fixed-size-image"
            />
            <div className="slider">
              <input
                type="range"
                min="0"
                max={
                  imageData && imageData.length > 0 ? imageData.length - 1 : 0
                }
                value={sliderValue}
                onChange={handleSliderChange}
                className="form-range"
              />
            </div>
          </>
        )}
      </div>
      <div className="device-info-container">
        {[
          {
            title: "Recived At",
            value: moment(imageData[sliderValue].createdAt).format(
              config.dateTimeSecondsFormat
            ),
          },
          {
            title: "Existing Faces",
            value:
              imageData[sliderValue].existingFaces === null
                ? "N/A"
                : imageData[sliderValue].existingFaces,
          },
          {
            title: "Direct Faces",
            value:
              imageData[sliderValue].directFaces === null
                ? "N/A"
                : imageData[sliderValue].directFaces,
          },
          {
            title: "Average View Time",
            value:
              imageData[sliderValue].averageViewTime === null
                ? "N/A"
                : `${imageData[sliderValue].averageViewTime} sec`,
          },
          {
            title: "Detected People",
            value:
              imageData[sliderValue].detectedPeople === null
                ? "N/A"
                : imageData[sliderValue].detectedPeople,
          },
        ].map((v) => (
          <div className="card" key={v.title}>
            <h2 className="card-title">{v.title}</h2>
            <p className="card-description">{`${v.value}`}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
DeviceSlider.propTypes = {
  data: PropTypes.object.isRequired,
  status: PropTypes.number.isRequired,
};

export default DeviceSlider;
