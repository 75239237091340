import React from "react";

const CheckboxRenderer = () => {
  return <input type="checkbox" readOnly />;
};

export const getColumns = (profile, t) => [
  {
    field: "index",
    tooltipField: "index",
    headerName: "",
    valueGetter: (params) => params.node.childIndex + 1, // Adding 1 to start index from 1
    cellRenderer: CheckboxRenderer,
    maxWidth: 80,
  },
  {
    field: "date",
    tooltipField: "date",
    headerName: "Date",
    cellRenderer: (params) => <div>{params.value}</div>,
  },

  {
    field: "name",
    cellRenderer: (params) => <div>{params.data.displayName}</div>,
    tooltipField: "campaignName",
    headerName: t("pages.profile.billing.columns.name"),
  },

  {
    field: "creditCard",
    headerName: t("pages.profile.billing.columns.creditCard"),
    cellClass: "data-table-cell cell-status",
  },
  {
    field: "price",
    headerName: t("pages.profile.billing.columns.price"),
    cellRenderer: (params) => (
      <div>
        {params.valueFormatted}
        {profile.currency}
      </div>
    ),
  },
  {
    colId: "invoice",
    headerName: "",
    cellRenderer: () => <>invoice</>,
    maxWidth: 70,
  },
  {
    colId: "receipt",
    headerName: "",
    cellRenderer: () => <>receipt</>,
    maxWidth: 70,
  },
];
