import React from "react";
import PropTypes from "prop-types";
import "./RenderXYZ.scss";

const RenderXYZ = ({ x, y, z }) => {
  return (
    <div className="xyz">
      <span className="key">X:</span>
      <span className="val">{x || "N/A"}</span>
      <span className="key">Y:</span>
      <span className="val">{y || "N/A"}</span>
      <span className="key">Z:</span>
      <span className="val">{z || "N/A"}</span>
    </div>
  );
};

RenderXYZ.propTypes = {
  x: PropTypes.string,
  y: PropTypes.string,
  z: PropTypes.string,
};

export default RenderXYZ;
