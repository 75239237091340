import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import SvgIcon from "@client.components/SvgIcon";
import "./Tabs.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Tabs = ({ tabs, value, onSelect, className }) => {
  return (
    <div className={classNames("tabs", className)}>
      {tabs.map((tab) => (
        <div
          key={tab.id}
          className={classNames("tab", { active: tab.id === value })}
          onClick={() => {
            if (tab.id !== value) {
              onSelect(tab.id);
            }
          }}
        >
          {console.log(tab.prefix)}
          {tab.icon.prefix ? (
            <FontAwesomeIcon icon={tab.icon} />
          ) : (
            <SvgIcon name={tab.icon} />
          )}
          {/* <SvgIcon name={tab.icon} /> */}
          {tab.name}
        </div>
      ))}
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSelect: PropTypes.func,
  className: PropTypes.string,
};

export default Tabs;
