import BaseModel from "./base";
import { get } from "lodash";

class VpnServerModel extends BaseModel {
  constructor(model = {}, msg = {}) {
    super(msg);

    this.serverName = get(model, "serverName", "");
    this.location = get(model, "location", "");
    this.ipAddress = get(model, "ipAddress", "");
    this.sshPort = get(model, "sshPort", "");
    this.userName = get(model, "userName", "");
    this.password = get(model, "password", "");

    this.validationRules = {
      serverName: {
        presence: { allowEmpty: false, message: msg.serverName },
      },
      location: {
        presence: { allowEmpty: false, message: msg.location },
      },
      ipAddress: {
        presence: { allowEmpty: false, message: msg.ipAddress },
      },
      sshPort: {
        presence: { allowEmpty: false, message: msg.sshPort },
      },
      userName: {
        presence: { allowEmpty: false, message: msg.userName },
      },
      password: {
        presence: { allowEmpty: false, message: msg.password },
      },
    };
  }

  get fields() {
    return {
      serverName: this.serverName,
      location: this.location,
      sshPort: this.sshPort,
      username: this.userName,
      password: this.password,
    };
  }
}

export default VpnServerModel;
