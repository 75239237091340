import BaseService from "@client.services/baseService";

export default class VpnService extends BaseService {
  constructor() {
    super("vpn", true);
  }

  async getAll(allFields = false) {
    return super.post("", {
      query: allFields
        ? `query {
        fetchVpnServers {
          availableProfiles
          createdAt
          deletedAt
          ipAddress
          isActive
          isDeleted
          location
          lookupId
          password
          serverName
          sshPort
          status
          updatedAt
          username
        }
      }`
        : `query {
        fetchVpnServers {
          lookupId
          serverName
        }
    }`,
    });
  }

  async create(model) {
    return super.post("", {
      query: `mutation CreateNewVpnServer($fields: VPNServerInputType) {
        createVpnServer(fields: $fields) {
          message
        }
      }`,
      variables: {
        fields: model.fields,
      },
    });
  }
}
