import React from "react";
import PropTypes from "prop-types";
import "./RadioGroup.scss";

export const RadioGroup = ({ items, groupName, onChange, value }) => {
  return (
    <div className="radio-group">
      {items.map((item) => {
        return (
          <label key={item.value}>
            <input
              type="radio"
              className="input-radio"
              name={groupName}
              value={item.value}
              checked={item.value === value}
              onChange={() => onChange(item.value)}
            />
            {item.label}
          </label>
        );
      })}
    </div>
  );
};

RadioGroup.propTypes = {
  value: PropTypes.any,
  groupName: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
};
