import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "@client.services/provider";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";

const initialState = {
  error: null,
  loading: false,
};

export const saveUserProfileAsync = createAsyncThunk(
  "profile/save",
  async (user, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      return await ServiceProvider.User.save(user);
    } finally {
      thunkAPI.dispatch(setLoading(false));
    }
  }
);

export const changeUserPasswordAsync = createAsyncThunk(
  "profile/changePassword",
  async ({ password, userId }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      await ServiceProvider.User.changePassword(userId, password);
    } finally {
      thunkAPI.dispatch(setLoading(false));
    }
  }
);

export const profile = createSlice({
  name: "profile",
  initialState,
  reducers: {
    clearProfileError: (state) => {
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = get(action, "payload", false);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveUserProfileAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(saveUserProfileAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      })
      .addCase(changeUserPasswordAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(changeUserPasswordAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      });
  },
});

export const { setLoading, clearProfileError } = profile.actions;

export const makeProfileError = (state) => state.profile.error;
export const makeProfileLoading = (state) => state.profile.loading;

export default profile.reducer;
