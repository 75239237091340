import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CircleIcon from "@client.components/CircleIcon";
import "./AdvertiserCircleName.scss";

const AdvertiserCircleName = ({ advertiser, className }) => {
  return (
    advertiser && (
      <div className={classNames("advertiser-circle-name", className)}>
        <CircleIcon name={advertiser.fullName} index={advertiser.index || 0} />
        {advertiser.fullName}
      </div>
    )
  );
};

AdvertiserCircleName.propTypes = {
  advertiser: PropTypes.object,
  className: PropTypes.string,
};

export default React.memo(AdvertiserCircleName);
