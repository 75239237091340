import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SvgIcon from "@client.components/SvgIcon";
import SectionDescription from "@client.pages/CreateCampaign/SectionDescription";
import Button from "@client.components/Button";
import { useDispatch, useSelector } from "react-redux";
import { makeProfile } from "@client.pages/Login/reducer";
import Pages from "@client.enums/pages";
import {
  getCampaignDurationTimeSlotsAsync,
  getCampaignLocationsAsync,
  makeCampaignLoading,
  makeSelectedCampaign,
  saveCampaignAsync,
} from "@client.pages/Campaign/reducer";
import moment from "moment";
import config from "@client.config";
import classNames from "classnames";
import Loader from "@client.components/Loader";
import { saveCampaignDraftAsync } from "@client.pages/Advertiser/reducer";
import MediaFile from "@client.components/MediaFile";
import useToast from "@client.hooks/useToast";
import Dropdown from "@client.components/Dropdown";
import "./CampaignInfo.scss";

const PaymentProps = Object.freeze({
  4654654654654654: "4654654654654654",
  8419887487352765: "8419887487352765",
});
const CREDIT_CARD_DATASOURCE = Object.keys(PaymentProps).map((k) => ({
  id: parseInt(k),
  name: PaymentProps[k],
}));

const CampaignInfo = ({ model, estimation }) => {
  const { t } = useTranslation();
  const { showToastAfterRequest } = useToast();
  const [creditCard, setCreditCard] = useState(CREDIT_CARD_DATASOURCE[0].id);
  const profile = useSelector(makeProfile);
  const selectedCampaign = useSelector(makeSelectedCampaign);
  const isCampaignLoading = useSelector(makeCampaignLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentDate = new Date();
  const [endate, setEndate] = useState(false);
  const [startdate, setStartdate] = useState(false);
  const [daysLeft, setDaysLeft] = useState();
  const [daysAgo, setDaysAgo] = useState();

  useEffect(() => {
    if (!estimation) {
      dispatch(getCampaignDurationTimeSlotsAsync());
      dispatch(getCampaignLocationsAsync());

      if (selectedCampaign.loading) {
        return <Loader loading={selectedCampaign.loading} />;
      }
    }
  }, [dispatch, estimation]);
  useEffect(() => {
    if (!estimation) {
      const startdate = new Date(model.startDate);
      const endDate = new Date(model.endDate);
      const daysLeft = Math.floor(
        (endDate - currentDate) / (1000 * 60 * 60 * 24)
      );
      const daysAgo = Math.floor(
        (currentDate - endDate) / (1000 * 60 * 60 * 24)
      );

      // Check if the endDate is in the past relative to the current date
      const isStartdateStarted = startdate > currentDate;
      const isEndDatePast = endDate < currentDate;
      setDaysLeft(daysLeft);
      setDaysAgo(daysAgo);
      setEndate(isEndDatePast);
      setStartdate(isStartdateStarted);
    }
  }, [currentDate, estimation]);

  const price = estimation ? estimation.price : model.totalSpent;
  const plays = estimation ? estimation.plays : model.totalViews;
  const pricePerView = price === 0 || plays === 0 ? 0 : price / plays;

  const renderPaymentSection = () => {
    if (isCampaignLoading) {
      return <Loader loading={true} />;
    }
    return (
      <>
        <Button
          text={t("pages.CreateCampaign.saveDraft")}
          onClick={async () => {
            const resp = dispatch(saveCampaignDraftAsync(model));
            showToastAfterRequest(
              resp,
              "pages.CreateCampaign.successDraftCreation"
            ).then(() => {
              navigate(`${Pages.Advertiser}/${model.userId}`);
            });
          }}
        />
        <SectionDescription title="pages.CreateCampaign.paymentMethod" />
        <Dropdown
          items={CREDIT_CARD_DATASOURCE}
          valueKey="id"
          payment={true}
          labelClassName="credit-card"
          defaultValue={creditCard}
          onPrepareValue={(v) => parseInt(v)}
          labelKey="name"
          placeholder="●●●● ●●●● ●●●● ●●●●"
          label={t("pages.CreateCampaign.creditCards")}
          onSelect={setCreditCard}
        />
        <Button
          text={t("pages.CreateCampaign.launchCampaign")}
          disabled={creditCard === null || !model.isValid()}
          onClick={async () => {
            if (!model.userId) {
              model.userId = profile.lookupId;
            }
            const resp = await dispatch(saveCampaignAsync(model));
            showToastAfterRequest(
              resp,
              "pages.CreateCampaign.successCampaignCreation"
            ).then(() => {
              navigate(`${Pages.Advertiser}/${model.userId}`);
            });
          }}
        />
      </>
    );
  };

  // const renderCampaignInfo = () => {
  //   if (locations.length === 0 || timeSlots.length === 0) {
  //     return null;
  //   }
  //   return (
  //     <>
  //       <SectionDescription
  //         title="components.CampaignInfo.duration"
  //         description="components.CampaignInfo.duration.info"
  //         descriptionDict={{
  //           dateFrom: moment(model.startDate).format(config.dateFormat),
  //           dateTo: moment(model.endDate).format(config.dateFormat),
  //         }}
  //       />
  //       <SectionDescription
  //         title="components.CampaignInfo.showtime"
  //         description="components.CampaignInfo.showtime.info"
  //         descriptionDict={{
  //           durationDaysType: getDurationDay(model.durationDaysType || "1", t),
  //           durationTimeRanges: timeSlots
  //             .filter((d) =>
  //               model.durationTimeRanges
  //                 ? model.durationTimeRanges.indexOf(d.id) > -1
  //                 : d.id === "1"
  //             )
  //             .map((t) => t.name)
  //             .join(", "),
  //         }}
  //       />
  //       <SectionDescription
  //         title="components.CampaignInfo.locations"
  //         description="components.CampaignInfo.locations.info"
  //         descriptionDict={{
  //           city: model.locationCity,
  //           selectedArea: model.locations
  //             .map(
  //               (d, index) =>
  //                 d.displayName ||
  //                 `${t("pages.Campaign.locationDefaultName")} ${index + 1}`
  //             )
  //             .join(", "),
  //         }}
  //       />
  //     </>
  //   );
  // };

  return (
    <div className="campaign-info">
      {!estimation && <div className="name">{model.displayName}</div>}
      {!estimation && (
        <div className="d-flex justify-content-between ">
          <span
            className={`status ${
              startdate ? "pending" : endate ? "ended" : "live"
            }`}
          >
            {startdate ? "Pending" : endate ? "End" : "Live"}
          </span>
          {!startdate && !endate ? (
            <span>Ends in {daysLeft} days</span>
          ) : (
            <span>Ended {daysAgo} days ago</span>
          )}
        </div>
      )}
      <div className={classNames("bag", { "bag-with-name": !estimation })}>
        <div style={{ height: 256, width: 291 }}></div>
        <div className="placeholder">
          <div className="thumb-img">
            <MediaFile
              key={model.file.lookupId}
              src={model.file.locationPath}
              className="thumb-img"
              fallbackElement={() => <SvgIcon name="file-not-found" />}
            />
          </div>
        </div>
      </div>

      {estimation && (
        <SectionDescription title="pages.CreateCampaign.costEstimetor" />
      )}
      <div className="payment-details">
        <div className="section-payment">
          <div className="price">{plays.toLocaleString()}</div>
          <div className="description">
            {t("pages.CreateCampaign.totalPlays")}
          </div>
        </div>
        <div className="section-payment">
          <div className="price">
            {profile.currency} {pricePerView.toLocaleString()}
          </div>
          <div className="description">
            {t("pages.CreateCampaign.pricePerPlay")}
          </div>
        </div>
        <div className="section-payment">
          <div className="price">
            {profile.currency} {pricePerView.toLocaleString()}
          </div>
          <div className="description">{t("pages.CreateCampaign.Spent")}</div>
        </div>
        <div className="section-payment no-border">
          <div className="price">
            {profile.currency} {price.toLocaleString()}
          </div>
          <div className="description">{t("pages.CreateCampaign.total")}</div>
        </div>
      </div>
      {!estimation ? (
        <div className="area">
          <div>
            {t("pages.Campaign.Overview.duration")}
            <div className="d-flex flex-row justify-content-between">
              <span className="description">
                {moment(model.startDate).format(
                  config.dateTimeSecondsFormatReversed
                )}
              </span>
              <span className="description">Until</span>
              <span className="description">
                {moment(model.endDate).format(
                  config.dateTimeSecondsFormatReversed
                )}
              </span>
            </div>
          </div>
          <div>
            {t("pages.Campaign.Overview.showtime")}
            <span className="description">
              {model.selectedDays ? model.selectedDays : "No showTime"}
            </span>
          </div>
          <div>
            {t("pages.Campaign.Overview.locations")}
            {model.locations ? (
              model.locations.map((locate) => {
                return (
                  <span key={locate.lookupId} className="description">
                    {locate.displayName}
                  </span>
                );
              })
            ) : (
              <span className="description">No Locations</span>
            )}
          </div>
        </div>
      ) : (
        renderPaymentSection()
      )}
      {/* {estimation ? renderPaymentSection() : renderCampaignInfo()} */}
    </div>
  );
};

CampaignInfo.propTypes = {
  model: PropTypes.any,
  estimation: PropTypes.any,
};

export default CampaignInfo;
