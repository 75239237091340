import React, { useCallback, useEffect, useState } from "react";
import Title from "@client.components/Title";
import LocationManagement from "./LocationManagement";
import DataTable from "@client.components/DataTable";
import { getColumns } from "./consts";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeProfile } from "@client.pages/Login/reducer";
import {
  deleteLocationAsync,
  getAllLocationsAsync,
  makeLocations,
} from "./reducer";
import useToast from "@client.hooks/useToast";
import "./Locations.scss";

const LocationsPage = () => {
  const { t } = useTranslation();
  const { showToastAfterRequest } = useToast();
  const profile = useSelector(makeProfile);
  const locations = useSelector(makeLocations);

  const [selectedRow, setSelectedRow] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllLocationsAsync());
  }, [dispatch]);

  const onEdit = useCallback((d) => {
    if (selectedRow && selectedRow.id === d.id) {
      // alert("You already chose this location.");
    } else {
      setSelectedRow(d);
    }
  }, [selectedRow]);
  const onView = useCallback((d) => {
setSelectedRow(d)
if (selectedRow && selectedRow.id === d.id) {
  // alert("You already chose this location.");
} else {
  setSelectedRow(d);
}

  }, [selectedRow])

  const onDelete = useCallback(
    (locationId) => {
      dispatch(deleteLocationAsync(locationId)).then((resp) => {
        showToastAfterRequest(resp, "pages.Locations.locationDeleted");
        setSelectedRow(null);
      });
    },
    [dispatch, showToastAfterRequest]
  );

  return (
    <div className="locations-page">
      <Title text="components.NavigationBar.locations" />
      <div className="content">
        <DataTable
          columns={getColumns(t, profile.currency, onView, onDelete, onEdit)}
          data={locations.locations}
          isLoading={locations.loading}
        />
        <LocationManagement
          location={selectedRow}
          onReset={() => {
            setSelectedRow(null);
          }}
        />
      </div>
    </div>
  );
};

export default LocationsPage;
