import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import {
  GoogleMap,
  Marker,
  Polygon,
  HeatmapLayer,
  Circle,
  DrawingManager,
  MarkerClusterer,
} from "@react-google-maps/api";
import useAvailableColors from "@client.hooks/useAvailableColors";
import playicon from "../../assets/images/play (1).png";
import onlineIcon from "../../assets/images/online.png";
import offlineIcon from "../../assets/images/offline.png";
import "./GoogleMap.scss";

const center = {
  lat: 0,
  lng: 0,
};

const GoogleMapComponent = ({
  className,
  width,
  height,
  location,
  markers,
  polygons,
  heatmapData,
  radiusInKm,
  children,
  polymarks,
  onMapClick,
  drawingManagerRef,
  onDrawingManagerPolygonComplete,
  drawingManagerKey,
  interval,
  ads,
  selectedPolygon,
  recenterMapTimestamp,
  onClusterClick,
  onMarkerClick,
}) => {
  const [map, setMap] = React.useState(null);
  const [getSubsequentColor] = useAvailableColors();
  const createCircleIcon = (color) => {
    return {
      url: playicon,
      fillColor: color,
      fillOpacity: 1,
      strokeColor: "#000",
      strokeWeight: 0,

      origin: new window.google.maps.Point(0, 0), // origin
      anchor: new window.google.maps.Point(0, 0),
      scaledSize: new window.google.maps.Size(15, 15), // Adjust the scale as needed
    };
  };

  const offlineOnlineIcon = (url) => {
    return {
      url: url,
      fillOpacity: 1,
      strokeColor: "#000",
      strokeWeight: 0,
      origin: new window.google.maps.Point(0, 0), // origin

      scaledSize: new window.google.maps.Size(35, 35), // Adjust the scale as needed
    };
  };

  const formattedData = heatmapData.map(({ latitude, longitude }) => ({
    lat: latitude,
    lng: longitude,
  }));

  useEffect(() => {
    if (markers.length === 0 || !map || recenterMapTimestamp === undefined)
      return;
    const bounds = new window.google.maps.LatLngBounds();

    markers.forEach((marker) => {
      bounds.extend(marker);
    });

    map.fitBounds(bounds);
  }, [markers, markers, recenterMapTimestamp]);

  // Effect to fit the map bounds to ads
  useEffect(() => {
    // Check if `ads` exists and has a length greater than 0
    if (!ads || ads.length === 0 || !map) return;

    const bounds = new window.google.maps.LatLngBounds();
    ads.forEach((marker) => {
      bounds.extend(marker);
    });

    // Define the interval function
    const intervalFunction = () => {
      // If interval is true or ads exist, set zoom to 17
      map.fitBounds(bounds);
      if (interval) {
        setTimeout(() => {
          map.setZoom(2);
        }, 1000); // Set the delay to 1000 milliseconds (1 second)
      }
    };

    // If interval is true or ads exist, start the timeout
    if (interval) {
      intervalFunction(); // Call intervalFunction immediately
    } else {
      // If interval is false and ads don't exist, immediately fit bounds without waiting for the timeout
      map.fitBounds(bounds);
    }

    // Cleanup function to clear the timeout when the component unmounts or when the dependency changes
    return () => clearTimeout();
  }, [ads, map, interval]);

  // Effect to fit the map bounds to markers
  useEffect(() => {
    if (markers.length === 0 || !map) return;

    const bounds = new window.google.maps.LatLngBounds();

    markers.forEach((marker) => {
      map.setZoom(12);
      bounds.extend(marker);
    });

    const intervalFunction = () => {
      map.fitBounds(bounds);
      map.setZoom(12);
      // If interval is true, set zoom to 17, otherwise use default zoom
      if (interval) {
        map.setZoom(17);
      }
    };

    // If interval is true, start the interval
    if (interval) {
      const intervalId = setInterval(intervalFunction, 1000);

      // Cleanup function to clear the interval when the component unmounts or when the dependency changes
      return () => clearInterval(intervalId);
    } else {
      // If interval is false, immediately fit bounds without waiting for the interval
      intervalFunction();
    }
  }, [markers, map, interval]);

  // Effect to fit the map bounds to location and radius
  useEffect(() => {
    if (map && location && Object.keys(location).length > 0) {
      const bounds = new window.google.maps.LatLngBounds(location);
      map.fitBounds(bounds);
      map.setZoom(12);
    }
  }, [map, radiusInKm, location]);

  const onPolygonComplete = (polygon) => {
    const paths = polygon
      .getPath()
      .getArray()
      .map((point) => {
        return { lat: point.lat(), lng: point.lng() };
      });
    const bounds = new window.google.maps.LatLngBounds();
    paths.forEach(({ lat, lng }) => {
      bounds.extend(new window.google.maps.LatLng(lat, lng));
    });
    map.fitBounds(bounds);
    onDrawingManagerPolygonComplete(paths);
  };
  useEffect(() => {
    if (map && (selectedPolygon || radiusInKm)) {
      const bounds = new window.google.maps.LatLngBounds();

      // Extend bounds to include polygon vertices
      if (selectedPolygon) {
        selectedPolygon.forEach(({ lat, lng }) => {
          bounds.extend(new window.google.maps.LatLng(lat, lng));
        });
      }

      // Extend bounds to include circle representing radius
      if (radiusInKm && location) {
        const circleBounds = new window.google.maps.Circle({
          center: location,
          radius: radiusInKm * 1000, // Convert km to meters
        }).getBounds();
        bounds.union(circleBounds);
      }

      map.fitBounds(bounds);
    }
  }, [map, selectedPolygon, radiusInKm, location]);

  const onLoad = React.useCallback((m) => {
    setMap(m);
  }, []);

  const optionalProps = className
    ? {
        mapContainerClassName: className,
      }
    : {
        mapContainerStyle: {
          width: width,
          height: height,
        },
      };

  const handleClusterClick = useCallback(
    (cluster) => {
      const markerData = cluster.getMarkers().map((marker) => marker.metaInfo);

      if (onClusterClick) {
        onClusterClick(markerData);
      }
    },
    [onClusterClick, markers]
  );

  const onLoadMarker = (marker, data) => {
    marker.metaInfo = data;
  };

  return (
    <div className="google-maps-container">
      <GoogleMap
        {...optionalProps}
        streetView={false}
        center={center || location}
        zoom={1}
        onLoad={onLoad}
        onUnmount={() => setMap(null)}
        options={MapOption}
        onClick={onMapClick}
      >
        {children}
        <MarkerClusterer
          averageCenter
          clusterClass="adport-cluster"
          enableRetinaIcons
          gridSize={60}
          zoomOnClick={false}
          onClick={handleClusterClick}
        >
          {(clusterer) =>
            markers.map((location, index) => (
              <Marker
                key={index}
                position={location}
                icon={
                  location.status
                    ? location.status == 1
                      ? offlineOnlineIcon(offlineIcon, 5)
                      : offlineOnlineIcon(onlineIcon, 10)
                    : offlineOnlineIcon(offlineIcon, 10)
                }
                clusterer={clusterer}
                customData={location}
                onClick={() => onMarkerClick(location)}
                onLoad={(markerInstance) =>
                  onLoadMarker(markerInstance, location)
                }
              />
            ))
          }
        </MarkerClusterer>

        {polygons.map((polygon, index) => (
          <>
            <Polygon
              key={index}
              paths={polygon} // Assuming each item in polygons is an array of coordinates
              options={{
                fillColor: "red",
                fillOpacity: 0.35,
                strokeColor: "red",
                strokeOpacity: 1,
                strokeWeight: 2,
              }}
            />
          </>
        ))}
        {polymarks &&
          polymarks.map((polygonData, index) => {
            const polygon = JSON.parse(polygonData.polygon);
            const color = getSubsequentColor(index);
            const paths = polygon.map((coord) => ({
              lat: parseFloat(coord[0]),
              lng: parseFloat(coord[1]),
            }));
            return (
              <Polygon
                key={index}
                paths={paths}
                options={{
                  fillColor: color,
                  fillOpacity: 0.35,
                  strokeColor: color,
                  strokeOpacity: 1,
                  strokeWeight: 2,
                }}
              />
            );
          })}

        {ads &&
          ads.length > 0 &&
          ads.map((m, index) => (
            <Marker
              key={index}
              position={m}
              icon={createCircleIcon(1, 2)}
            ></Marker>
          ))}
        {formattedData.length > 0 && (
          <HeatmapLayer
            data={formattedData.map(
              (d) => new window.google.maps.LatLng(d.lat, d.lng)
            )}
          />
        )}
        {radiusInKm > 0 && Object.keys(location).length > 0 && (
          <Circle
            options={{
              strokeColor: getSubsequentColor(0),
              strokeOpacity: 0.8,
              strokeWeight: 2,

              fillColor: getSubsequentColor(0),
              fillOpacity: 0.35,
              center: location || location,
              radius: radiusInKm * 1000,
            }}
          />
        )}

        {drawingManagerRef && (
          <DrawingManager
            key={drawingManagerKey}
            onPolygonComplete={onPolygonComplete}
            onOverlayComplete={(e) => {
              e.overlay.setMap(null);
            }}
            onLoad={(ref) => {
              drawingManagerRef(ref);
            }}
            options={{
              drawingControlOptions: {
                drawingModes: ["polygon"],
              },
              polygonOptions: {
                editable: true,
                draggable: true,
                fillColor: getSubsequentColor(1),
                strokeColor: getSubsequentColor(1),
                zIndex: 2,
              },
            }}
          />
        )}
      </GoogleMap>
    </div>
  );
};

const MapOption = {
  disableDefaultUI: true,
  fullscreenControl: false,
  zoomControl: true,
};

GoogleMapComponent.defaultProps = {
  markers: [],
  polygons: [],
  heatmapData: [],
  options: {
    fullscreenControl: false,
    disableDefaultUI: true,
  },
  drawingManagerRef: null,
};

GoogleMapComponent.propTypes = {
  drawingManagerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  ads: PropTypes.any,
  selectedPolygon: PropTypes.any,
  onDrawingManagerPolygonComplete: PropTypes.func,
  drawingManagerKey: PropTypes.string,
  interval: PropTypes.func.isRequired,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  location: PropTypes.any,
  polymarks: PropTypes.any,
  markers: PropTypes.any,
  polygons: PropTypes.any,
  heatmapData: PropTypes.array,
  radiusInKm: PropTypes.number,
  onMarkerClick: PropTypes.func,
  onMapClick: PropTypes.func,
  onClusterClick: PropTypes.func,
  children: PropTypes.node,
  recenterMapTimestamp: PropTypes.number,
  options: PropTypes.shape({
    fullscreenControl: PropTypes.bool,
    disableDefaultUI: PropTypes.bool,
  }),
};

export default React.memo(GoogleMapComponent);
