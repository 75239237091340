import BaseModel from "./base";
import { get } from "lodash";

class DeviceModel extends BaseModel {
  constructor(device = {}, msg = {}) {
    super(msg);

    this.lookupId = get(device, "lookupId", "");
    this.deviceName = get(device, "deviceName", "");
    this.hardwareVersion = parseFloat(get(device, "hardwareVersion", 0));
    this.softwareVersion = parseFloat(get(device, "softwareVersion", 0));
    this.countryId = get(device, "countryId", "");
    this.assignedToId = get(device, "assignedToId", "");
    this.vpnServerId = get(device, "vpnServerId", "");
    this.boardTypeId = get(device, "boardTypeId", "");

    this.validationRules = {
      hardwareVersion: {
        presence: { allowEmpty: false, message: msg.hardwareVersion },
        numericality: { greaterThanOrEqualTo: 0, message: msg.hardwareVersion },
      },
      softwareVersion: {
        presence: { allowEmpty: false, message: msg.softwareVersion },
        numericality: { greaterThanOrEqualTo: 0, message: msg.softwareVersion },
      },
      countryId: {
        presence: { allowEmpty: false, message: msg.country },
      },
      boardTypeId: {
        presence: { allowEmpty: false, message: msg.typeId },
      },
      assignedToId: {
        presence: { allowEmpty: false, message: msg.accountId },
      },
      vpnServerId: {
        presence: { allowEmpty: false, message: msg.vpnId },
      },
    };

    if (this.lookupId) {
      this.validationRules.deviceName = {
        presence: { allowEmpty: false, message: msg.name },
      };
    }
  }

  get fields() {
    const data = {
      hardwareVersion: this.hardwareVersion,
      softwareVersion: this.softwareVersion,
      vpnServerId: this.vpnServerId,
      assignedToId: this.assignedToId,
      countryId: this.countryId,
      boardTypeId: this.boardTypeId,
    };

    if (this.lookupId) {
      data.deviceName = this.deviceName;
      data.lookupId = this.lookupId;
    }
    return data;
  }
}

export default DeviceModel;
