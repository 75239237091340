import BaseModel from "./base";
import { get } from "lodash";

class UserAsset extends BaseModel {
  constructor(asset = {}, msg) {
    super(msg);

    this.lookupId = get(asset, "lookupId", "");
    this.file = get(asset, "file", "");
    this.userLookupId = get(asset, "userLookupId", "");

    this.validationMsgs = msg;
    this.validationRules = {};
  }
}

export default UserAsset;
