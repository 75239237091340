import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "@client.services/provider";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";

const DEFAULT_SELECTED_CAMPAIGN = { campaign: null, loading: false };
const DEFAULT_SELECTED_CAMPAIGN_HEATMAP = { heatmap: [], loading: false };
const DEFAULT_SELECTED_CAMPAIGN_STATISTIC = {
  data: {
    fetchCampaignMonitoring: {
      graphData: [], // Ensure graphData has a default value
    },
  },
  loading: false,
  error: null,
};
const DEFAULT_ESTIMATION = { plays: 0, pricePerView: 0, price: 0 };
const DEFAULT_PERFORMANCE = { data: [], loading: false };
const initialState = {
  targets: [],
  timeSlots: [],
  locations: [],
  error: null,
  loading: false,
  estimation: DEFAULT_ESTIMATION,
  selected: DEFAULT_SELECTED_CAMPAIGN,
  selectedPerformance: DEFAULT_PERFORMANCE,
  selectedHeatmap: DEFAULT_SELECTED_CAMPAIGN_HEATMAP,
  selectedStatistic: DEFAULT_SELECTED_CAMPAIGN_STATISTIC,
  statisticOverview: [],
  activeCampaignLocations: [],
  activeCampaignHeatmaps: [],
};

const getExtractedDeviceTelemetry = (action, query, defaultValue) => {
  const devices = get(action, `payload.data.${query}`, []);

  return devices.length === 0
    ? defaultValue
    : devices.reduce((acc, curr) => {
        return acc.concat(get(curr, "device.allTelemetry", []));
      }, []);
};

export const getCampaignTargetAsync = createAsyncThunk(
  "campaign/getCampaignTargetAsync",
  async () => {
    const targets = await ServiceProvider.Campaign.getTargets();
    return targets;
  }
);

export const getCampaignDurationTimeSlotsAsync = createAsyncThunk(
  "campaign/getCampaignDurationTimeSlots",
  async () => {
    const timeSlots = await ServiceProvider.Campaign.getTimeSlots();
    return timeSlots;
  }
);

export const getCampaignLocationsAsync = createAsyncThunk(
  "campaign/getCampaignSelectArea",
  async () => {
    const locations = await ServiceProvider.Campaign.getLocations();
    return locations;
  }
);

export const saveCampaignAsync = createAsyncThunk(
  "campaign/saveCampaign",
  async (campaign, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      return await ServiceProvider.Campaign.saveCampaign(campaign);
    } finally {
      thunkAPI.dispatch(setLoading(false));
    }
  }
);

export const getCampaignByIdAsync = createAsyncThunk(
  "campaign/getById",
  async (campaignId, thunkAPI) => {
    thunkAPI.dispatch(setSelectedLoading(true));
    try {
      return await ServiceProvider.Campaign.getById(campaignId);
    } finally {
      thunkAPI.dispatch(setSelectedLoading(false));
    }
  }
);

export const getCampaignPerformanceAsync = createAsyncThunk(
  "campaign/getPerformance",
  async (campaignId, thunkAPI) => {
    thunkAPI.dispatch(setSelectedPerformanceLoading(true));
    try {
      return await ServiceProvider.Campaign.getPerformance(campaignId);
    } finally {
      thunkAPI.dispatch(setSelectedPerformanceLoading(false));
    }
  }
);
export const getCampaignMonitoringAsync = createAsyncThunk(
  "campaign/getMonitoring",
  async (campaignId, thunkAPI) => {
    thunkAPI.dispatch(setStatisticLoading(true));
    try {
      const response = await ServiceProvider.Campaign.getMonitoring(campaignId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    } finally {
      thunkAPI.dispatch(setStatisticLoading(false));
    }
  }
);
export const getCampaignHeatmapAsync = createAsyncThunk(
  "campaign/getHeatmap",
  async (campaignId) => {
    return await ServiceProvider.Campaign.getHeatmap(campaignId);
  }
);

// export const getCampaignStatistic = createAsyncThunk(
//   "campaign/getStatistic",
//   async (id, thunkAPI) => {
//     thunkAPI.dispatch(setStatisticLoading(true));
//     try {
//       return await ServiceProvider.Campaign.getStatistic(id );
//     } finally {
//       thunkAPI.dispatch(setStatisticLoading(false));
//     }
//   }
// );

export const getEstimationAsync = createAsyncThunk(
  "campaign/getEstimation",
  async (data) => {
    return await ServiceProvider.Campaign.getEstimation(data);
  }
);

export const getStatisticOverviewAsync = createAsyncThunk(
  "campaign/getStatisticOverview",
  async ({ id, dateFrom, dateTo }) => {
    return await ServiceProvider.Campaign.getStatisticOverview(
      id,
      dateFrom,
      dateTo
    );
  }
);

export const getActiveCampaignLocationsAsync = createAsyncThunk(
  "campaign/getActiveLocations",
  async () => {
    return await ServiceProvider.Campaign.getActiveLocations();
  }
);

export const getActiveCampaignHeatmapsAsync = createAsyncThunk(
  "campaign/getActiveHeatmaps",
  async () => {
    return await ServiceProvider.Campaign.getActiveHeatmaps();
  }
);

export const getUserAssetsAsync = createAsyncThunk(
  "campaign/getActiveHeatmaps",
  async () => {
    return await ServiceProvider.Campaign.getAssets();
  }
);

export const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    setSelectedLoading: (state, action) => {
      state.selected.loading = get(action, "payload", false);
    },
    setSelectedPerformanceLoading: (state, action) => {
      state.selectedPerformance.loading = get(action, "payload", false);
    },
    setStatisticLoading: (state, action) => {
      state.selectedStatistic.loading = get(action, "payload", false);
    },
    clearCampaignError: (state) => {
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = get(action, "payload", false);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStatisticOverviewAsync.fulfilled, (state, action) => {
        state.statisticOverview = get(action, "payload", []);
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getStatisticOverviewAsync.rejected, (state, action) => {
        state.statisticOverview = [];
        state.error = getResponseError(action);
      })
      // .addCase(getCampaignStatistic.fulfilled, (state, action) => {
      //   state.selectedStatistic = get(action, "payload", {
      //     ...DEFAULT_SELECTED_CAMPAIGN_STATISTIC,
      //   });
      //   state.error = getGraphqlResponseError(action);
      // })
      // .addCase(getCampaignStatistic.rejected, (state, action) => {
      //   state.selectedStatistic = {
      //     ...DEFAULT_SELECTED_CAMPAIGN_STATISTIC,
      //   };
      //   state.error = getResponseError(action);
      // })
      .addCase(getEstimationAsync.fulfilled, (state, action) => {
        state.estimation = get(action, "payload", {
          ...DEFAULT_ESTIMATION,
        });
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getEstimationAsync.rejected, (state, action) => {
        state.estimation = {
          ...DEFAULT_ESTIMATION,
        };
        state.error = getResponseError(action);
      })
      .addCase(getCampaignByIdAsync.fulfilled, (state, action) => {
        state.selected.campaign = get(
          action,
          "payload.data.fetchCampaign",
          null
        );
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getCampaignByIdAsync.rejected, (state, action) => {
        state.selected = {
          ...DEFAULT_SELECTED_CAMPAIGN,
        };
        state.error = getResponseError(action);
      })

      .addCase(getCampaignMonitoringAsync.fulfilled, (state, action) => {
        state.selectedStatistic = get(
          action,
          "payload",
          DEFAULT_SELECTED_CAMPAIGN_STATISTIC
        );
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getCampaignMonitoringAsync.rejected, (state, action) => {
        state.selectedStatistic = DEFAULT_SELECTED_CAMPAIGN_STATISTIC;
        state.error = getResponseError(action);
      })
      .addCase(getCampaignPerformanceAsync.fulfilled, (state, action) => {
        state.selectedPerformance.data = get(
          action,
          "payload.data.fetchCampaignCaptures",
          []
        );
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getCampaignPerformanceAsync.rejected, (state, action) => {
        state.selectedPerformance = {
          ...DEFAULT_PERFORMANCE,
        };
        state.error = getResponseError(action);
      })
      .addCase(getCampaignHeatmapAsync.pending, (state) => {
        state.selectedHeatmap.loading = true;
      })
      .addCase(getCampaignHeatmapAsync.fulfilled, (state, action) => {
        state.selectedHeatmap = {
          heatmap: get(action, "payload.data.fetchCampaignHeatmap", []),
          loading: false,
        };
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getCampaignHeatmapAsync.rejected, (state, action) => {
        state.selectedHeatmap = {
          ...DEFAULT_SELECTED_CAMPAIGN_HEATMAP,
        };
        state.error = getResponseError(action);
      })
      .addCase(getCampaignTargetAsync.fulfilled, (state, action) => {
        state.targets = get(action, "payload", []);
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getCampaignTargetAsync.rejected, (state, action) => {
        state.targets = [];
        state.error = getResponseError(action);
      })
      .addCase(getCampaignDurationTimeSlotsAsync.fulfilled, (state, action) => {
        state.timeSlots = get(action, "payload", []);
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getCampaignDurationTimeSlotsAsync.rejected, (state, action) => {
        state.timeSlots = [];
        state.error = getResponseError(action);
      })
      .addCase(getCampaignLocationsAsync.fulfilled, (state, action) => {
        state.locations = get(action, "payload.data.fetchLocations", []);
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getCampaignLocationsAsync.rejected, (state, action) => {
        state.locations = [];
        state.error = getResponseError(action);
      })
      .addCase(getActiveCampaignLocationsAsync.fulfilled, (state, action) => {
        const activeLocations = get(
          action,
          "payload.data.campaignLocations",
          []
        );
        state.activeCampaignLocations = activeLocations.map((a) =>
          JSON.parse(a.polygon)
        );
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getActiveCampaignLocationsAsync.rejected, (state, action) => {
        state.activeCampaignLocations = [];
        state.error = getResponseError(action);
      })
      .addCase(getActiveCampaignHeatmapsAsync.fulfilled, (state, action) => {
        state.activeCampaignHeatmaps = getExtractedDeviceTelemetry(
          action,
          "fetchActiveCampaignHeatmap",
          []
        );
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getActiveCampaignHeatmapsAsync.rejected, (state, action) => {
        state.activeCampaignLocations = [];
        state.error = getResponseError(action);
      })
      .addCase(saveCampaignAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(saveCampaignAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      });
  },
});

export const {
  setLoading,
  setSelectedLoading,
  setStatisticLoading,
  clearCampaignError,
  setSelectedPerformanceLoading,
} = campaignSlice.actions;

export const makeSelectedCampaign = (state) => state.campaign.selected;
export const makeSelectedPerformance = (state) =>
  state.campaign.selectedPerformance;
export const makeCampaignLoading = (state) => state.campaign.loading;
export const makeTargets = (state) => state.campaign.targets;
export const makeTimeSlots = (state) => state.campaign.timeSlots;
export const makeLocations = (state) => state.campaign.locations;
export const makeEstimation = (state) => state.campaign.estimation;
export const makeCampaignError = (state) => state.campaign.error;
export const makeCampaignHeatmap = (state) => state.campaign.selectedHeatmap;
export const makeActiveCampaignLocations = (state) =>
  state.campaign.activeCampaignLocations;
export const makeActiveCampaignHeatmaps = (state) =>
  state.campaign.activeCampaignHeatmaps;
export const makeCampaignMonitor = (state) => state.campaign.selectedStatistic;
export const makeSelectedStatisticOverview = (state) =>
  state.campaign.statisticOverview;

export default campaignSlice.reducer;
