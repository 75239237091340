import BaseService from "@client.services/baseService";

export default class CampaignService extends BaseService {
  async getAll(advertiserId) {
    return super.post("", {
      query: `query ($lookupId: String) {
        fetchUserCampaigns(id: $lookupId) {
          displayName
          totalViews
          totalSpent
          status
          lookupId
          totalPlays
          perPlayCalculation
          file {
            locationPath
          }
          endDate
          startDate
          isActive
        }
      }`,
      variables: {
        lookupId: advertiserId,
      },
    });
  }

  async getAllCampaigns({ endDate, startDate, status, limit, offset }) {
    return super.post("", {
      query: `query(
        $endDate: String
        $startDate: String
        $status: Int
        $limit: Int
        $offset: Int
      ) {
         fetchCampaigns(
          endDate: $endDate
          limit: $limit
          offset: $offset
          startDate: $startDate
          status: $status
          ) {
          count
          results {
            budgetAmount
            notes
            budgetLimit
            createdAt
            currentBalance
            deletedAt
            displayName
            endDate
            status
            isActive
            file {
              locationPath
            }
            totalPlays
            totalDisplays
            totalSpent
            totalViews
            account {
              companyName
            }
          }
        }
      }`,
      variables: {
        endDate,
        startDate,
        status,
        limit,
        offset,
      },
    });
  }

  async getById(id) {
    return super.post("", {
      query: `query($lookupId: String){
        fetchCampaign(id: $lookupId) {
          lookupId
          budgetAmount
          currentBalance
          displayName
          status
          totalSpent
          totalViews
          selectedDays
          endDate
          totalPlays
          startDate
          file {
            locationPath
          }
          locations {
            lookupId
            displayName
          }
        }
      }`,
      variables: {
        lookupId: id,
      },
    });
  }

  async getPerformance(id) {
    return super.post("", {
      query: `query ($lookupId: String) {
        fetchCampaignCaptures(lookupId: $lookupId) {
          createdAt
          latitude
          longitude
          views
        }
      }`,
      variables: {
        lookupId: id,
      },
    });
  }
  async getMonitoring(id) {
    return super.post("", {
      query: `query ($lookupId: String) {
        fetchCampaignMonitoring(lookupId: $lookupId) {
          campaign{
            lookupId
            displayName
          }
          totalPlays
          totalViews
          totalSpent
          graphData{
            date
            plays
            views
            spent
          }
        }
      }`,
      variables: {
        lookupId: id,
      },
    });
  }

  // eslint-disable-next-line no-unused-vars
  async getStatisticOverview(id, dateFrom, dateTo) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([
          {
            date: "11/15/2023",
            plays: 100,
            views: 230,
            spent: 20,
          },
          {
            date: "11/16/2023",
            plays: 111,
            views: 240,
            spent: 23,
          },
          {
            date: "11/17/2023",
            plays: 50,
            views: 100,
            spent: 10,
          },
          {
            date: "11/18/2023",
            plays: 500,
            views: 800,
            spent: 40,
          },
        ]);
      }, 500);
    });
  }

  // eslint-disable-next-line no-unused-vars
  async getEstimation(data) {
    return new Promise((resolve) => {
      resolve({
        plays: 1000,
        pricePerView: 0.3,
        price: 300,
      });
    });
  }

  async getTargets() {
    return Promise.resolve([
      {
        id: "1",
        name: "Google Search",
      },
      {
        id: "2",
        name: " Attract Customer to Location",
      },
      {
        id: "3",
        name: "Brand Awareness",
      },
      {
        id: "4",
        name: "Something Else",
      },
    ]);
  }

  async getTimeSlots() {
    return Promise.resolve([
      {
        id: "1",
        name: "All Day",
      },
      {
        id: "2",
        name: "Morning - 7am- 11am",
      },
      {
        id: "3",
        name: "Lunch - 11am- 2pm",
      },
      {
        id: "4",
        name: "Afternoon - 2pm - 5pm",
      },
      {
        id: "5",
        name: "Dinner - 5pm - 8pm",
      },
      {
        id: "6",
        name: "Evening - 8pm - 10pm",
      },
      {
        id: "7",
        name: "Night - 10pm - 7am",
      },
    ]);
  }

  async saveCampaign(campaign) {
    return super.post("", {
      query: `mutation($fields:CampaignInputType){
        createCampaign(fields: $fields){
          message
        }
      }`,
      variables: {
        fields: campaign.fields,
      },
    });
  }

  async getLocations() {
    return super.post("", {
      query: `query {
        fetchLocations{
          lookupId
          createdAt
          isActive
          polygon
          pricePerView
          displayName
          
        }
      }`,
    });
  }

  async getActiveLocations() {
    return super.post("", {
      query: `query {
        campaignLocations {
          lookupId
          polygon
        }
      }`,
    });
  }

  async getActiveHeatmaps() {
    return super.post("", {
      query: `query {
        fetchActiveCampaignHeatmap {
          lookupId
          device {
            allTelemetry {
              lat
              lng
            }
          }
        }
      }`,
    });
  }

  async getHeatmap(lookupId) {
    return super.post("", {
      query: `query($lookupId: String) {
        fetchCampaignHeatmap(id: $lookupId) {
          latitude
            longitude
        }
      }`,
      variables: {
        lookupId: lookupId,
      },
    });
  }
}
