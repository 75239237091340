import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "@client.components/DataTable";
import { getColumns } from "./consts";
import { useTranslation } from "react-i18next";

import Button from "@client.components/Button";
import {
  createAdvertiserAsync,
  getAllAdvertisersAsync,
  makeAdvertisers,
  makeAdvertisersLoading,
} from "../../pages/Advertiser/reducer";
import { useCallback } from "react";
import useToast from "@client.hooks/useToast";
import "./Users.scss";
import { useNavigate } from "react-router-dom";
import Pages from "@client.enums/pages";
import UsersForm from "./UsersForm";
import { toast } from "react-toastify";
import { motion, AnimatePresence } from "framer-motion";
import AdvertiserModel from "@client.models/advertiser";
import Input from "@client.components/Input";
import Title from "@client.components/Title";
import DateTimeRangePicker from "@client.components/DateTimeRangePicker/DateTimeRangePicker";
import RoleDropdown from "@client.components/RoleDropdown";

const UsersPage = () => {
  const { t } = useTranslation();
  const { showToastAfterRequest } = useToast();
  const advertisers = useSelector(makeAdvertisers);
  const isLoading = useSelector(makeAdvertisersLoading);
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const gridRef = useRef(null);
  const [filter, setFilter] = useState({
    query: "",
    startDate: "",
    endDate: "",
    roleId: "",
  });
  const navigate = useNavigate();
  const [model, setModel] = React.useState(new AdvertiserModel());
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    password: "",
    emailAddress: "",
    phoneNumber: "",
    companyName: "",
    roleId: "",
    currencyCode: "USD",
    currencySymbol: "$",
  });

  useEffect(() => {
    dispatch(getAllAdvertisersAsync());
  }, []);
  useEffect(() => {
    setModel(
      new AdvertiserModel(
        { ...model },
        {
          firstName: t("components.Advertisers.validation.firstName"),
          lastName: t("components.Advertisers.validation.lastName"),
          emailAddress: t("components.Advertisers.validation.emailAddress"),
          currencyCode: "USD",
          currencySymbol: "$",
          roleId: t("components.Advertisers.validation.roleId"),
          password: t("components.Advertisers.validation.password"),
          phoneNumber: t("components.Advertisers.validation.phoneNumber"),
          companyName: t("components.Advertisers.validation.companyName"),
          invalidEmail: t("components.Advertisers.validation.invalidEmail"),
        }
      )
    );
  }, [t]);
  const handleToggleForm = () => {
    setShowForm(!showForm);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Check if the input is for phoneNumber
    if (name === "phoneNumber") {
      const formatPhoneNumber = (value) => {
        // Remove all non-digit characters from the input value
        const phoneNumber = value.replace(/\D/g, "");

        // Check if the phone number is longer than 3 digits
        if (phoneNumber.length > 2) {
          // Insert a space after every 3 digits starting from the fourth digit
          return phoneNumber.replace(/(\d{2})(?!$)/g, "$1 ").trim();
        }

        return phoneNumber;
      };
      // Apply custom formatting function only to phoneNumber input
      const formattedValue = formatPhoneNumber(value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: formattedValue,
      }));
    } else if (name === "roleId") {
      // Parse roleId as float
      const parsedValue = parseFloat(value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: parsedValue,
      }));
    } else {
      // For other inputs, directly update the value without formatting
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  const changeModel = useCallback(
    (v, field) => {
      setModel(
        new AdvertiserModel({ ...model, [field]: v }, model.validationMsgs)
      );
    },
    [model]
  );
  const handleOptionSelect = (roleId) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      roleId: roleId,
    }));
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.firstName) {
      errors.firstName = "First name is required";
    }
    if (!formData.lastName) {
      errors.lastName = "Last Name is required";
    }
    if (!formData.phoneNumber) {
      errors.phoneNumber = "phone Number is required";
    }

    if (Object.keys(errors).length > 0) {
      // Concatenate error messages into a single message
      const errorMessage = Object.values(errors).join("\n");
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }
    toast.success("Form submitted successfully!", {
      position: toast.POSITION.TOP_CENTER,
    });
    return true;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (model.roleId && !validateForm()) {
      try {
        const resp = dispatch(createAdvertiserAsync({ fields: model.fields }));

        showToastAfterRequest(resp, "pages.Devices.deviceCreated");
        setShowForm(false); // Close the form after successful submission
      } catch (error) {
        // Handle any errors that might occur during the dispatch
        console.error("Error creating User:", error);
      }
    }
  };
  const onSelectionChanged = (d) => {
    switch (d.colDef.field) {
      case "Name":
      case "index":
        navigate(`${Pages.Advertiser}/${d.data.lookupId}`);
        break;
      default:
    }

    // if (d.column.colId == "Name") {
    //     navigate(`${Pages.Advertiser}/${d.data.lookupId}`)
    // }
  };

  const filteredUsers =
    filter.query.length < 2
      ? advertisers
      : advertisers.filter((user) =>
          user.fullName.toLowerCase().includes(filter.query.toLowerCase())
        );

  const createTxt = t("components.NavigationBar.createAdvertiser");
  return (
    <div className="users page">
      <Title text="components.NavigationBar.Users">
        <Button
          icon="plus"
          className="button-create"
          text={createTxt}
          onClick={handleToggleForm}
        />
      </Title>
      <div className="search">
        <Input
          label="Search"
          value={filter.query}
          onChange={(e) => {
            setFilter({
              ...filter,
              query: e,
            });
          }}
        />
        <DateTimeRangePicker
          dateFrom={filter.startDate}
          dateTo={filter.endDate}
          showTimeSelect={false}
          captionStart="Start Date"
          captionEnd="End Date"
          onChange={(startDate, endDate) => {
            setFilter({
              ...filter,
              startDate,
              endDate,
            });
          }}
        />
        <RoleDropdown
          label="Role"
          onSelect={(v) =>
            setFilter({
              ...filter,
              roleId: v,
            })
          }
        />
      </div>
      <div className="content">
        {showForm && (
          <div className="overlay">
            <AnimatePresence>
              <motion.div
                className="visible"
                initial={{ opacity: 0, y: -100 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -100 }}
                transition={{ duration: 0.3 }}
              >
                <UsersForm
                  setShowForm={setShowForm}
                  formData={formData}
                  model={model}
                  changeModel={changeModel}
                  onInputChange={handleInputChange}
                  onSubmit={handleSubmit}
                  firstName={formData.firstName}
                  lastName={formData.lastName}
                  phoneNumber={formData.phoneNumber}
                  companyName={formData.companyName}
                  handleOptionSelect={handleOptionSelect}
                />
              </motion.div>
            </AnimatePresence>
          </div>
        )}

        <DataTable
          ref={gridRef}
          columns={getColumns(t)}
          data={filteredUsers}
          isLoading={isLoading}
          onSelectionChanged={onSelectionChanged}
        />
      </div>
    </div>
  );
};

export default UsersPage;
