import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./Tag.scss";

const Tag = ({ name, value, onSelect, active }) => {
  return (
    <div
      className={classNames("tag", { active: active })}
      onClick={() => onSelect(value)}
    >
      {name}
    </div>
  );
};

Tag.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onSelect: PropTypes.func,
  active: PropTypes.bool,
};

export default Tag;
