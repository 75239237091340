import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ProfileMenu from "@client.components/ProfileMenu";
import SvgIcon from "@client.components/SvgIcon";
import { useSelector } from "react-redux";
import { makeSelectedAdvertiser } from "@client.pages/Advertiser/reducer";
import AdvertiserCircleName from "@client.components/AdvertiserCircleName/AdvertiserCircleName";
import Icon from "@client.components/Icon";
import "./TopBar.scss";

export const TopBar = ({ showAdvertiser, navigationLink }) => {
  const { t } = useTranslation();
  const selectedAdvertiser = useSelector(makeSelectedAdvertiser);
  const { advertiser } = selectedAdvertiser;
  return (
    <div className="top-bar" data-testid="TopBar">
      {showAdvertiser && advertiser ? (
        <AdvertiserCircleName advertiser={advertiser} />
      ) : (
        <div className="search-container">
          <SvgIcon name="search" onClick={() => alert("Under construction")} />
        </div>
      )}
      <div className="right-btns">
        {navigationLink && (
          <NavLink
            to={navigationLink.to}
            className="link"
            onClick={navigationLink.onClick}
          >
            <Icon name="back" />
            <span>{t(navigationLink.name)}</span>
          </NavLink>
        )}
        <SvgIcon
          name="gb"
          className="lang"
          onClick={() => alert("Under construction")}
        />
        <div className="help" onClick={() => alert("Under construction")}>
          <SvgIcon name="help" />
          <span>{t("components.TopBar.help")}</span>
        </div>
        <SvgIcon
          name="bell"
          className="notifications"
          onClick={() => alert("Under construction")}
        />
        <ProfileMenu />
      </div>
    </div>
  );
};

TopBar.defaultProps = {
  navigationLink: null,
};

TopBar.propTypes = {
  showAdvertiser: PropTypes.bool,
  navigationLink: PropTypes.shape({
    to: PropTypes.string,
    name: PropTypes.string,
    onClick: PropTypes.func,
  }),
};
