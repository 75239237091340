import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import GoogleMap from "@client.components/GoogleMap";
import Input from "@client.components/Input";
import LocationModel from "@client.models/location";
import Button from "@client.components/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  createLocationAsync,
  makeLocationsLoading,
  updateLocationAsync,
} from "./reducer";
import Loader from "@client.components/Loader";
import { validateModel } from "@client.utils/form";
import ToggleButton from "@client.components/ToggleButton";
import useToast from "@client.hooks/useToast";
import "./Locations.scss";

const LocationManagement = ({ location, onReset }) => {
  const { t } = useTranslation();
  const isLoading = useSelector(makeLocationsLoading);
  const dispatch = useDispatch();
  const { showToastAfterRequest } = useToast();

  const [polygonCoordinates, setPolygonCoordinates] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [model, setModel] = useState({});
  const [drawingManagerKey, setDrawingManagerKey] = useState(
    new Date().getTime().toString()
  );

  useEffect(() => {
    setIsDrawing(false);
    setModel(
      new LocationModel(location, {
        displayName: t("pages.Locations.validation.displayName"),
        ppvPrice: t("pages.Locations.validation.ppv"),
        polygon: t("pages.Locations.validation.polygon"),
      })
    );
    if (location && location.polygon) {
      setPolygonCoordinates(JSON.parse(location.polygon));
    } else {
      setPolygonCoordinates([]);
    }
  }, [t, location]);

  const changeModel = useCallback(
    (v, field) => {
   
      setModel(
        new LocationModel({ ...model, [field]: v }, model.validationMsgs)
      );
    },
    [model]
  );

  const setDrawingMode = useCallback(() => {
    const newIsDrawing = !isDrawing;
    setIsDrawing(newIsDrawing);
    if (!newIsDrawing) {
      setDrawingManagerKey(new Date().getTime().toString());
    }
  }, [isDrawing]);

  const saveLocation = useCallback(async () => {
    model.polygon = polygonCoordinates;
    const isValid = validateModel(model);
    if (isValid) {
      const isNewLocation = !model.lookupId;
      const resp = await dispatch(
        isNewLocation ? createLocationAsync(model) : updateLocationAsync(model)
      );
      showToastAfterRequest(
        resp,
        isNewLocation
          ? "pages.Locations.locationCreated"
          : "pages.Locations.locationUpdated"
      ).then(() => {
        setIsDrawing(false);
      });
    }
  }, [model, dispatch, polygonCoordinates, showToastAfterRequest]);

  if (isLoading) {
    return (
      <div className="location-management">
        <Loader loading={true} />
      </div>
    );
  }

  return (
    <div className="location-management">
      <GoogleMap
        options={{
          fullscreenControl: false,
          mapTypeControl: false,
        }}
        width={"100%"}
        height={"380px"}
        polygons={polygonCoordinates.length > 0 ? [polygonCoordinates] : []}
        drawingManagerKey={drawingManagerKey}
        drawingManagerRef={
          isDrawing
            ? (v) => {
                if (v) {
                  v.setDrawingMode("polygon");
                }
              }
            : null
        }
        onDrawingManagerPolygonComplete={(coordinates) => {
          setPolygonCoordinates(coordinates);
        }}
      >
        <div className="location-management-buttons">
          <ToggleButton
            text={t(
              isDrawing
                ? "pages.Locations.stopDrawing"
                : "pages.Locations.startDrawing"
            )}
            value={isDrawing}
            onChange={setDrawingMode}
          />
        </div>
      </GoogleMap>
      <div className="management-controls">
        <Input
          value={model.displayName}
          onChange={(v) => changeModel(v, "displayName")}
          label={t("pages.Locations.displayName")}
        />
        <Input
          value={model.pricePerView}
          onChange={(v) => changeModel(v, "pricePerView")}
          label={t("pages.Locations.ppv")}
          type={"number"}
          optional={{
            min: 0,
            max: 10000,
            step: 0.1,
          }}
        />
        <Button
          text={t(
            model.lookupId ? "pages.Locations.update" : "pages.Locations.create"
          )}
          onClick={saveLocation}
        />
        <Button
          text={t("pages.Locations.cancel")}
          onClick={() => {
            setPolygonCoordinates([]);
            setIsDrawing(false);
            if (!location) {
              setModel(new LocationModel({}, model.validationMsgs));
            }
            setDrawingManagerKey(new Date().getTime().toString());
            onReset();
          }}
        />
      </div>
    </div>
  );
};

LocationManagement.propTypes = {
  location: PropTypes.any,
  onReset: PropTypes.func.isRequired,
};

export default LocationManagement;
