import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ActiveCampaignsOverviews from "@client.components/ActiveCampaignsOverview/ActiveCampaignsOverview";
import { useDispatch, useSelector } from "react-redux";
import useStableParams from "@client.hooks/useStableParams";
import { getAdvertiserCampaignsAsync, makeSelectedCampaigns } from "./reducer";
import "./Campaigns.scss";

const ActiveCampaignsPage = () => {
  const location = useLocation();
  const info = location.state;
  function getLastPartOfString(str) {
    const parts = str.split(".");
    return parts[parts.length - 1];
  }

  const lastPart = info ? getLastPartOfString(info) : null;

  const dispatch = useDispatch();
  const selectedCampaigns = useSelector(makeSelectedCampaigns);

  const { id } = useStableParams();

  useEffect(() => {
    dispatch(getAdvertiserCampaignsAsync(id));
  }, [dispatch, id]);

  const statusLive = selectedCampaigns.campaigns.filter(
    (obj) => obj.status === 0
  );

  const renderCampaignOverview = () => {
    switch (lastPart) {
      // case "pausedCampaigns":
      //   return <PausedCampaignsOverview data={statusPaused} />;
      case "activeCampaigns":
        return <ActiveCampaignsOverviews data={statusLive} />;

      default:
        return <ActiveCampaignsOverviews data={statusLive} />;
    }
  };

  return <div className="active-campaigns">{renderCampaignOverview()}</div>;
};

export default ActiveCampaignsPage;
