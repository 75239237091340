import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeDeviceInfo } from "../reducer";

import config from "@client.config";
import moment from "moment";
import Icon from "@client.components/Icon";
import ServiceProvider from "@client.services/provider";
import { MODAL_EVENT } from "@client.components/Modal";
import { useTranslation } from "react-i18next";
import TelemetryDataTable from "./TelemetryDataTable/TelemetryDataTable";
import DeviceCaptures from "./DeviceCaptures";
import RenderXYZ from "@client.components/RenderXYZ/RenderXYZ";

const DeviceOverview = () => {
  const [isActive, setIsActive] = useState(true);

  const { t } = useTranslation();

  const overview = useSelector(makeDeviceInfo);

  const copyToClipboard = (txt) => {
    navigator.clipboard.writeText(txt);
  };

  const lastTelemetry = overview.device.lastTelemetry || {};

  return (
    <div className="device-data-card">
      <div className="device-data-card-row">
        <div className="device-data-column">
          <div className="caption">General Paramaters</div>
          {[
            { title: "Machine ID", value: overview.device.machineId },
            { title: "Device Name", value: overview.device.deviceName },
            { title: "Timezone", value: overview.device.timezoneCode },
            { title: "Country", value: overview.device.country },
            {
              title: "Hardware Version",
              value: overview.device.hardwareVersion,
            },
            {
              title: "Software Version",
              value: overview.device.softwareVersion,
            },
            {
              title: "Assigned To",
              value: overview.device.assignedTo.fullName,
            },
            {
              title: "VPN IP",
              value: overview.device.vpn
                ? overview.device.vpn.ipAddress
                : "N/A",
            },
          ].map((v) => (
            <React.Fragment key={v.title}>
              <div className="device-cell">{v.title}:</div>
              <div
                className="device-cell copy"
                title={v.value}
                onClick={() => copyToClipboard(v.value)}
              >
                {v.value}
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="device-data-column">
          <div className="caption">
            <div onClick={() => setIsActive(!isActive)}>Telemetry</div>
            <Icon
              name="info"
              onClick={() => {
                ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
                  show: true,
                  className: "telemetry-history-modal",
                  title: t("pages.Device.telemetry.history"),
                  component: <TelemetryDataTable />,
                });
              }}
            />
          </div>
          {[
            {
              title: "Recieved At",
              value: moment(overview.device.createdAt).format(
                config.dateTimeSecondsFormat
              ),
            },
            {
              title: "Core Utilization",
              value: lastTelemetry.latitude
                ? lastTelemetry.latitude + "%"
                : "N/A",
            },
            {
              title: "Memory Utilization",
              value: lastTelemetry.longitude
                ? lastTelemetry.longitude + "%"
                : "N/A",
            },
            {
              title: "Disk Utilization",
              value: lastTelemetry.longitude
                ? lastTelemetry.longitude + "%"
                : "N/A",
            },
            {
              title: "Device Uptime",
              value: lastTelemetry.battery ? lastTelemetry.battery : "N/A",
            },
            {
              title: "Avaliable Light",
              value: lastTelemetry.luxLight ? lastTelemetry.luxLight : "N/A",
            },
            {
              title: "Gyroscope",
              valueToCopy: `X: ${lastTelemetry.gyroscopeX || "N/A"} Y: ${
                lastTelemetry.gyroscopeY || "N/A"
              } Z: ${lastTelemetry.gyroscopeZ || "N/A"}`,
              value: (
                <RenderXYZ
                  x={lastTelemetry.gyroscopeX}
                  y={lastTelemetry.gyroscopeY}
                  z={lastTelemetry.gyroscopeZ}
                />
              ),
            },
            {
              title: "Acceleration",
              valueToCopy: `X: ${lastTelemetry.accelerometerX || "N/A"} Y: ${
                lastTelemetry.accelerometerY || "N/A"
              } Z: ${lastTelemetry.accelerometerZ || "N/A"}`,
              value: (
                <RenderXYZ
                  x={lastTelemetry.accelerometerX}
                  y={lastTelemetry.accelerometerY}
                  z={lastTelemetry.accelerometerZ}
                />
              ),
            },
          ].map((v) => (
            <React.Fragment key={v.title}>
              <div className="device-cell">{v.title}:</div>
              <div
                className="device-cell copy"
                title={v.value}
                onClick={() => copyToClipboard(v.valueToCopy || v.value)}
              >
                {v.value}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
      <DeviceCaptures />
    </div>
  );
};

export default DeviceOverview;
