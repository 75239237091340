import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "@client.services/provider";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";

const DEFAULT_LOCATIONS = { locations: [], loading: false };

const initialState = {
  locations: DEFAULT_LOCATIONS,
  error: null,
  loading: false,
};

export const getAllLocationsAsync = createAsyncThunk(
  "locations/getAll",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setLocationsLoading(true));
    try {
      return await ServiceProvider.Location.getAll();
    } finally {
      thunkAPI.dispatch(setLocationsLoading(false));
    }
  }
);

export const createLocationAsync = createAsyncThunk(
  "locations/create",
  async (location, thunkAPI) => {
    const resp = await ServiceProvider.Location.create(location);
    if (!resp.errors) {
      thunkAPI.dispatch(getAllLocationsAsync());
    }
    return resp;
  }
);

export const updateLocationAsync = createAsyncThunk(
  "locations/update",
  async (location, thunkAPI) => {
    const resp = await ServiceProvider.Location.update(location);
    if (!resp.errors) {
      thunkAPI.dispatch(getAllLocationsAsync());
    }
    return resp;
  }
);

export const deleteLocationAsync = createAsyncThunk(
  "locations/delete",
  async (locationId, thunkAPI) => {
    const resp = await ServiceProvider.Location.delete(locationId);
    if (!resp.errors) {
      thunkAPI.dispatch(getAllLocationsAsync());
    }
    return resp;
  }
);

export const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    clearLocationsError: (state) => {
      state.error = null;
    },
    setLocationsLoading: (state, action) => {
      state.locations.loading = get(action, "payload", false);
    },
    setLoading: (state, action) => {
      state.loading = get(action, "payload", false);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllLocationsAsync.fulfilled, (state, action) => {
        state.locations.locations = get(
          action,
          "payload.data.fetchLocations",
          []
        );
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getAllLocationsAsync.rejected, (state, action) => {
        state.locations = { ...DEFAULT_LOCATIONS };
        state.error = getResponseError(action);
      })
      .addCase(createLocationAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(createLocationAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      })
      .addCase(updateLocationAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(updateLocationAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      })
      .addCase(deleteLocationAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(deleteLocationAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      });
  },
});

export const { setLocationsLoading, clearLocationsError, setLoading } =
  locationsSlice.actions;

export const makeLocations = (state) => state.location.locations;
export const makeLocationsError = (state) => state.location.error;
export const makeLocationsLoading = (state) => state.location.loading;

export default locationsSlice.reducer;
