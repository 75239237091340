import React from "react";
import config from "@client.config";
import moment from "moment";
import GridManagementButtons from "@client.components/GridManagementButtons";

const getPPVGetter = (params, currency) => {
  return `${currency}${params.data.pricePerView}`;
};

const getCreatedAtGetter = (params) => {
  return params.value ? moment(params.value).format(config.dateTimeFormat) : "";
};

export const getColumns = (t, currency, onView, onDelete, onEdit) => [
  {
    field: "displayName",
    tooltipField: "displayName",
    headerName: t("pages.Locations.columns.name"),
  },
  {
    field: "pricePerView",
    maxWidth: 120,
    headerName: t("pages.Locations.columns.ppv"),
    valueGetter: (v) => getPPVGetter(v, currency),
    tooltipValueGetter: (v) => getPPVGetter(v, currency),
    valueFormatter: (v) => getPPVGetter(v, currency),
  },
  {
    field: "createdAt",
    maxWidth: 135,
    headerName: t("pages.Locations.columns.createdAt"),
    valueFormatter: getCreatedAtGetter,
    tooltipValueGetter: getCreatedAtGetter,
  },
  {
    headerName: "",
    cellRenderer: ({ data }) => (
      <GridManagementButtons item={data} onView={onView} onEdit={onEdit} onDelete={onDelete} />
    ),
    maxWidth: 70,
  },
];
