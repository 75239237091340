import { get } from "lodash";

const getResponseError = (action) => {
  
  return action && action.error ? JSON.parse(action.error.message) : null;
};

const getGraphqlResponseError = (action) => {
  return get(action, "payload.errors", null);
};

export { getResponseError, getGraphqlResponseError };
