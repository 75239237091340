import config from "@client.config";
import moment from "moment";

export const getDateTimeGetter = (params) => {
  return params.value ? moment(params.value).format(config.dateTimeFormat) : "";
};
const getDateFromDateTime = (dateTimeString) => {
  // Parse the DateTime string using moment.js
  const dateTime = moment(dateTimeString);

  // Format the DateTime to extract only the date part (YYYY-MM-DD)
  const date = dateTime.format("DD/MM/yyyy");

  return date;
};

const getTimeFromDateTime = (dateTimeString) => {
  // Log the DateTime string to check if it's correct

  // Parse the DateTime string using moment.js
  const dateTime = moment(dateTimeString);

  // Format the DateTime to extract only the time part
  const time = dateTime.format("HH:mm:ss");

  return time;
};

export const getColumns = (t) => [
  {
    field: "dateTime",
    headerName: t("pages.Campaign.PerformanceTable.columns.dateTime"),
    cellRenderer: (params) => getDateFromDateTime(params.data.dateTime),
  },
  {
    headerName: "Time",
    valueFormatter: (params) => getTimeFromDateTime(params.data.dateTime),
    tooltipValueGetter: (params) => getTimeFromDateTime(params.data.dateTime),
    cellRenderer: (params) => getTimeFromDateTime(params.data.dateTime),
  },
  {
    field: "lat",
    tooltipField: "lat",
    headerName: t("pages.Campaign.PerformanceTable.columns.lat"),
  },
  {
    field: "lng",
    tooltipField: "lng",
    headerName: t("pages.Campaign.PerformanceTable.columns.lng"),
  },
  {
    field: "estimatedViews",
    tooltipField: "estimatedViews",
    headerName: t("pages.Campaign.PerformanceTable.columns.estimatedViews"),
  },
];
