import DeviceStatuses from "@client.enums/deviceStatuses";
import BaseService from "@client.services/baseService";

export default class DeviceService extends BaseService {
  async getAll({
    limit = 10,
    offset = 0,
    accountLookupId = "",
    startDate = "",
    endDate = "",
    filterType = "",
    isOnline = "",
  }) {
    const response = await super.post("", {
      query: `
        query (
          $account_lookup_id: String
          $start_date: String
          $end_date: String
          $filter_type: [Int]
          $is_online: Boolean
          $order_by: String
          $limit: Int
          $offset: Int
        ) {
          fetchDevices(
            accountLookupId: $account_lookup_id
            startDate: $start_date
            endDate: $end_date
            filterType: $filter_type
            isOnline: $is_online
            orderBy: $order_by
            limit: $limit
            offset: $offset
          ) {
            count
            results {
              lookupId
              deviceName
              machineId
              isOnline
              vpnConfiguration
              defaultMedia
              country
              softwareVersion
              hardwareVersion
              account {
                lookupId
              }
              lastTelemetry {
                createdAt
                accelerometerX
                accelerometerY
                accelerometerZ
                gyroscopeX
                gyroscopeY
                gyroscopeZ
              }
            }
          }
        }
      `,
      variables: {
        account_lookup_id: accountLookupId || "",
        start_date: startDate || "",
        end_date: endDate || "",
        filter_type: filterType,
        is_online:
          isOnline === DeviceStatuses.All
            ? null
            : isOnline === DeviceStatuses.Online,
        order_by: "-last_message_date",
        limit,
        offset,
      },
    });

    // Log the response to check for issues
    return response;
  }
  async getApprovals() {
    const response = await super.post("", {
      query: `
        query{
          fetchPendingDevices{
            lookupId
            createdAt
            machineId
            verificationCode
            country
            timezoneCode
          }
        }
    `,
    });
    return response;
  }
  async approveDevice(lookupId) {
    return super.post("", {
      query: `mutation configureDeviceApproval($lookupId: String){
    configureDeviceApproval(lookupId: $lookupId){
    message
    }
  } `,
      variables: {
        lookupId: lookupId,
      },
    });
  }
  async getById(id) {
    return super.post("", {
      query: `query($lookupId: String){
        fetchDevice(id: $lookupId) {
          lookupId
          createdAt
          isActive
          updatedAt
          deviceName
          machineId
          defaultMedia
          verificationCode
          timezoneCode
          hardwareVersion
          softwareVersion
          country
          lastTelemetry {
            battery
            temperature
            coresUtilization
            memoryUtilization
            totalCores
            totalDisk
            uptime
            longitude
            latitude
            updatedAt
            luxLight
          }
          vpn{
            lookupId
            ipAddress
            vpnServer{
              serverName
            }
            vpnDeviceSingle{
              deviceName
            }
          }
          assignedTo{
            fullName
          }
        }
      }`,
      variables: {
        lookupId: id,
      },
    });
  }

  async getDeviceCaptures({
    limit = 10,
    offset = 0,
    lookupId = "",
    startDate = "",
    endDate = "",
    filterType = [],
  }) {
    return super.post("", {
      query: `query (
                $lookup_id: String
                $start_date: String
                $end_date: String
                $filter_type: [Int]
                $limit: Int
              ) {
                fetchDeviceCaptures(
                  endDate: $end_date
                  filterType: $filter_type
                  limit: $limit
                  lookupId: $lookup_id
                  offset: 0
                  startDate: $start_date
                ) {
                  count
                  results {
                    afterBase64
                    averageViewTime
                    beforeBase64
                    createdAt
                    deletedAt
                    deviceId
                    detectedPeople
                    directFaces
                    existingFaces
                    isActive
                    isDeleted
                    lookupId
                    status
                    updatedAt
                  }
                }
              }
      `,
      variables: {
        end_date: endDate,
        start_date: startDate,
        lookup_id: lookupId,
        limit,
        offset,
        filter_type: filterType,
      },
    });
  }

  async fetchDeviceTelemetry(limit, offset, lookupId) {
    return super.post("", {
      query: `query(
          $limit: Int
          $offset: Int
          $lookupId: String
          ) {
            fetchTelemetry(endDate: "", limit: $limit, lookupId: $lookupId, offset: $offset, startDate: "") {
              count
              results {
                battery
                coresUtilization
                createdAt
                diskUtilization
                fullSpectrumIrVisibleLight
                isActive
                infraredLight
                latitude
                longitude
                lookupId
                luxLight
                memoryUtilization
                signal
                temperature
                totalCores
                totalDisk
                totalMemory
                updatedAt
                uptime
                visibleLight
                accelerometerX
                accelerometerY
                accelerometerZ
                gyroscopeZ
                gyroscopeY
                gyroscopeX
              }
            }
          }`,
      variables: {
        limit,
        offset,
        lookupId,
      },
    });
  }

  async create(device) {
    return super.post("", {
      query: `mutation CreateNewDevice($fields: DeviceInputType) {
        createDevice(fields: $fields) {
          message
        }
      }`,
      variables: {
        fields: device.fields,
      },
    });
  }

  async update(model) {
    const { lookupId, ...deviceToUpdate } = model.fields;
    return super.post("", {
      query: `mutation updateDevice($lookupId: String, $fields: DeviceInputType) {
        updateDevice(fields: $fields, lookupId: $lookupId) {
          message
        }
      }`,
      variables: {
        fields: deviceToUpdate,
        lookupId: lookupId,
      },
    });
  }
  async updateDeviceMedia(lookupId, defaultMedia) {
    return super.post("", {
      query: `mutation updateDevice($id: String, $fields: DeviceInputType) {
        updateDevice(id: $id, fields: $fields) {
          message
        }
      }`,
      variables: {
        id: lookupId,
        fields: {
          defaultMedia: defaultMedia,
        },
      },
    });
  }

  async delete(deviceId) {
    return super.post("", {
      query: `mutation($id: String){
        deleteDevice(id: $id){
          message
        }
      } `,

      variables: {
        id: deviceId,
      },
    });
  }

  async flickrSignal(macAddress, signal) {
    return super.post("", {
      query: `mutation CloudFlicker($macAddress: String, $signal: String) {
        cloudFlicker(macAddress: $macAddress, signal: $signal) {
          message
        }
      }`,
      variables: {
        macAddress,
        signal,
      },
    });
  }
}
