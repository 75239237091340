import React from "react"
export const getColumns = () => [
    {
        field: "index",
        tooltipField: "index",
        headerName: "#",
        valueGetter: (params) => params.node.childIndex + 1, // Adding 1 to start index from 1
        cellRenderer: (params) => (
          <div>
          {params.value}
          
        </div>
        ),
        maxWidth: 80
      },
      {
        field: 'timestamp',
        headerName: 'Timestamp',
      },
      {
        field: 'value',
        headerName: 'Value',
      },
]