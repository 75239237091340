import { get } from "lodash";
import Loader from "@client.components/Loader";
import MediaFile from "@client.components/MediaFile";
import Uploader from "@client.components/Uploader";
import {
  getSelectedAssetsAsync,
  makeAdvertisersLoading,
  makeSelectedAssets,
  uploadUserAssetAsync,
} from "@client.pages/Advertiser/reducer";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Title from "@client.components/Title";
import UserAsset from "@client.models/userAsset";
import MediaActions from "@client.components/MediaActions/MediaActions";
import "./MediaAssets.scss";
import NoDataPlaceholder from "@client.components/NoDataPlaceholder";

const MediaAssets = ({ userId, selectedFileId }) => {
  const selectedAssets = useSelector(makeSelectedAssets);
  const isUploading = useSelector(makeAdvertisersLoading);
  const [assetsMetaData, setAssetsMetaData] = useState({});
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [model, setModel] = React.useState(
    new UserAsset({ userLookupId: userId, lookupId: selectedFileId })
  );
  useEffect(() => {
    dispatch(getSelectedAssetsAsync(userId));
  }, [dispatch, userId]);
  const handleUpdate = () => {
    dispatch(getSelectedAssetsAsync(userId));
  };
  const handleDelete = (assetId) => {
    alert(`Implement me ${assetId}`);
  };

  return (
    <div className="media-library page">
      <Title text="components.NavigationBar.mediaLibrary">
        {isUploading ? (
          <Loader loading={true} />
        ) : (
          <>
            <Uploader
              className="button-upload"
              onChange={(file) => {
                model.file = file;
                setModel(model);

                dispatch(uploadUserAssetAsync(model))
                  .then((resp) => {
                    const data = get(resp, "payload", {});

                    handleUpdate(data);
                    // Handle successful upload, e.g., update UI or notify user
                  })
                  .catch((error) => {
                    console.error("Upload failed:", error);
                    // Handle upload error, e.g., show error message
                  });
              }}
              isButton={true}
            />
          </>
        )}
      </Title>
      <div
        className={
          selectedAssets.loading ? "media-assets loading" : "media-assets"
        }
      >
        <Loader loading={selectedAssets.loading} />
        {!selectedAssets.loading && selectedAssets.assets.length === 0 && (
          <NoDataPlaceholder description="pages.MediaLibrary.noDataToShow" />
        )}
        {!selectedAssets.loading &&
          selectedAssets.assets.map((asset, index) => (
            <div className="asset" key={asset.lookupId}>
              <div className="image">
                <MediaFile
                  onLoad={({ width, height }) => {
                    assetsMetaData[asset.lookupId] = {
                      width,
                      height,
                    };
                    setAssetsMetaData({ ...assetsMetaData });
                  }}
                  src={asset.locationPath}
                />
              </div>
              <div className="footer">
                <div className="media-name">
                  <div className="file-size">
                    {t("components.UserAssets.defaultFileName", {
                      index: index + 1,
                    })}
                    <div className="description ">
                      {assetsMetaData[asset.lookupId]
                        ? `${assetsMetaData[asset.lookupId].width}x${
                            assetsMetaData[asset.lookupId].height
                          } Pixels`
                        : t("components.UserAssets.noMetaData")}
                    </div>
                  </div>
                  {/* <span style={{cursor: "pointer"}} onClick={() => alert("Under construction")}>Delete</span> */}
                </div>
                <div>
                  <MediaActions
                    mediaId={asset.lookupId}
                    handleDelete={handleDelete}
                  />
                </div>
              </div>
            </div>
          ))}

        {/* {!selectedAssets.loading &&
            selectedAssets.assets.map((asset, index) => (
              <div
              key={asset.lookupId}
              className={classNames("asset", {
                selected: asset.lookupId === model.lookupId,
              })}
              onClick={() => {
                changeModel(model, "fileId");
                onSelect(asset.lookupId, asset.locationPath);
              }}
              >
              <div className="image">
              <MediaFile
              src={asset.locationPath}
              onLoad={({ width, height }) => {
                assetsMetaData[asset.lookupId] = {
                  width,
                  height,
                };
                setAssetsMetaData({ ...assetsMetaData });
              }}
              />
              {asset.lookupId === model.lookupId && (
                <div className="img-badge">
                {t("components.UserAssets.selected")}
                </div>
              )}
              </div>
              <div className="footer">
              <div className="name">
              {t("components.UserAssets.defaultFileName", {
                index: index + 1,
              })}
              </div>
              <div className="description">
              {assetsMetaData[asset.lookupId]
                ? `${assetsMetaData[asset.lookupId].width}x${
                  assetsMetaData[asset.lookupId].height
                }`
                : t("components.UserAssets.noMetaData")}
                </div>
                </div>
                </div>
              ))} */}
      </div>
    </div>
  );
};
MediaAssets.propTypes = {
  userId: PropTypes.string.isRequired,
  selectedFileId: PropTypes.string.isRequired,
};

export default MediaAssets;
