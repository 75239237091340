import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CampaignList from "../CampaignList";
import { useDispatch, useSelector } from "react-redux";
import useStableParams from "@client.hooks/useStableParams";
import { getAdvertiserDraftsAsync, makeSelectedDrafts } from "../reducer";
import Button from "@client.components/Button";
import Pages from "@client.enums/pages";
import Title from "@client.components/Title";
import { useNavigate } from "react-router-dom";

const DraftsTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedDrafts = useSelector(makeSelectedDrafts);

  const { id } = useStableParams();
  useEffect(() => {
    dispatch(getAdvertiserDraftsAsync(id));
  }, [dispatch, id]);

  return (
    <>
      <div className="tab-title"></div>
      <div className=" create-campaign d-flex justify-content-between align-items-center">
        <Title text={t("pages.Advertiser.DraftsTab.title")} />

        {/* <Title text="components.NavigationBar.activeCampaigns"/> */}
        <div className="create-campaign">
          <Button
            icon="plus"
            text={t("components.NavigationBar.createCampaign")}
            onClick={() => {
              navigate(Pages.CreateCampaign);
            }}
          />
        </div>
      </div>
      <CampaignList
        advertiserId={id}
        loading={selectedDrafts.loading}
        campaigns={selectedDrafts.campaigns}
        isDraft={true}
      />
    </>
  );
};

export default DraftsTab;
