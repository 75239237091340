import BaseModel from "./base";
import { get } from "lodash";

class InviteUserModel extends BaseModel {
  constructor(user = {}, msg) {
    super(msg);

    this.firstName = get(user, "firstName", "");
    this.lastName = get(user, "lastName", "");
    this.email = get(user, "email", "");
    this.password = get(user, "password", "");
    this.phone = get(user, "phone", "");

    this.validationMsgs = msg;
    this.validationRules = {
      firstName: {
        presence: { allowEmpty: false, message: msg.firstName },
      },
      lastName: {
        presence: { allowEmpty: false, message: msg.lastName },
      },
      email: {
        presence: { allowEmpty: false, message: msg.email },
        email: { message: msg.invalidEmail },
      },
      phone: {
        presence: { allowEmpty: false, message: msg.phone },
      },
      password: {
        passwordComplexity: {
          message: msg.password,
        },
      },
    };
  }
}

export default InviteUserModel;
