import React, { useEffect } from "react";
import MediaAssets from "@client.components/MediaAssets";
import { getProfileAsync, makeProfile } from "@client.pages/Login/reducer";

import { useDispatch, useSelector } from "react-redux";
const MediaLibraryPage = () => {
  const profile = useSelector(makeProfile);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfileAsync());
  }, []);

  return (
    <div>
      <MediaAssets userId={profile.lookupId} selectedFileId={""} />
    </div>
  );
};

export default MediaLibraryPage;
