import BaseService from "@client.services/baseService";

export default class DashboardService extends BaseService {
  async getSystemLogs() {
    return super.post("", {
      query: `query {
                fetchSystemLogs {
                  count
                  results {
                    createdAt
                    deletedAt
                    description
                    icon
                    isActive
                    isDeleted
                    lookupId
                    title
                    unitId
                    unitType
                    updatedAt
                  }
                }
      }`,
      variables: {},
    });
  }
  async getPlatformDashboard(heatmap = false, locations = false, id = "") {
    return super.post("", {
      query: `query(
                $heatmap: Boolean
                $locations: Boolean
                $search: String
              ) {
                platformDashboard(heatmap: $heatmap, locations: $locations, search: $search) {
                  infoBoxes {
                    dailyBudgetSpent
                    dailyAdPlays
                    dailyAdViews
                    activeCampaigns
                  }
                  deviceMap {
                    lookupId
                    deviceName
                    latitude
                    longitude
                    modelType
                    status
                  }
                  deviceLocations {
                    lookupId
                    polygon
                  }
                  deviceHeatmap {
                    latitude
                    longitude
                  }
                  campaignMonitoring {
                    totalPlays
                    totalViews
                    totalSpent
                    graphData {
                      date
                      plays
                      views
                      spent
                    }
                  }
                  deviceStatus{
                    offlineCount
                    onlineCount
                  }
                }
      }`,
      variables: {
        heatmap,
        locations,
        search: id,
      },
    });
  }
}
