import BaseService from "@client.services/baseService";

export default class UserService extends BaseService {
  async login(emailAddress, password) {
    return super.post("", {
      query: `mutation($emailAddress: String, $password: String){
        login(emailAddress: $emailAddress, password: $password){
          message
          token
          profile{
            companyName
            selectedAccount {
              entityId
            }
            firstName
            lastName
            roleId
            lookupId
            emailAddress
            currencyCode
            currencySymbol
            avatarUrl
            phoneNumber
          }
        }
      }`,
      variables: {
        emailAddress,
        password,
      },
    });
  }

  async profile() {
    return super.post("", {
      query: `query {
        profile {
          companyName
          selectedAccount {
            entityId
          }
          firstName
          lastName
          roleId
          lookupId
          emailAddress
          currencyCode
          currencySymbol
          avatarUrl
          phoneNumber
          createdAt
          lastLoginTime
        }
      }`,
    });
  }

  async forgotPassword(emailAddress) {
    return super.post(`forgotPassword`, {
      emailAddress,
    });
  }

  async logout() {
    return super.post(`logout`);
  }

  async save(user) {
    return super.post("", {
      query: `mutation UpdateUser($id: String, $user: UserInputType){
        updateUser(id: $id, user: $user){
          message
        }
      }`,
      variables: {
        id: user.lookupId,
        user: user.fields,
      },
    });
  }

  async changePassword(userId, password) {
    return super.post("", {
      query: `mutation UpdateUser($id: String, $fields: UserInputType){
        updateUser(id: $id, fields: $fields){
          message
        }
      }`,
      variables: {
        id: userId,
        fields: {
          password: password,
        },
      },
    });
  }
}
