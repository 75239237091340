import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  getAdvertiserByIdAsync,
  makeSelectedAdvertiser,
} from "@client.pages/Advertiser/reducer";
import Loader from "@client.components/Loader";
import TopBoardOverview from "@client.components/TopBoardOverview";
import AdvertiserCircleName from "@client.components/AdvertiserCircleName/AdvertiserCircleName";
import Button from "@client.components/Button";
import { useNavigate } from "react-router-dom";
import Pages from "@client.enums/pages";

const AdvertiserOverview = ({ advertiserId }) => {
  const selectedAdvertiser = useSelector(makeSelectedAdvertiser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdvertiserByIdAsync(advertiserId));
  }, [dispatch, advertiserId]);

  if (selectedAdvertiser.loading) {
    return (
      <div className="advertiser-overview page-loading">
        <Loader loading={true} />
      </div>
    );
  }
  const { advertiser } = selectedAdvertiser;
  return (
    <>
      {!advertiser && "No advertiser found"}
      {advertiser && (
        <div className="advertiser-overview">
          <AdvertiserCircleName
            advertiser={advertiser}
            className="page-title"
          />

          <Button
            onClick={() => navigate(`${Pages.ProfileGeneral}/${advertiserId}`)}
            text="Settings"
          />
        </div>
      )}
      {advertiser && (
        <TopBoardOverview
          activeCampaigns={advertiser.activeCampaigns}
          adPlays={advertiser.adPlays}
          adViews={advertiser.adViews}
          budgetSpent={advertiser.budgetSpent}
        />
      )}
    </>
  );
};

AdvertiserOverview.propTypes = {
  advertiserId: PropTypes.string.isRequired,
};

export default React.memo(AdvertiserOverview);
