import React, { useCallback, forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import { validateModel } from "@client.utils/form";
import UserAsset from "@client.models/userAsset";
import Uploader from "@client.components/Uploader";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedAssetsAsync,
  makeAdvertisersLoading,
  makeSelectedAssets,
  uploadUserAssetAsync,
} from "@client.pages/Advertiser/reducer";
import Loader from "@client.components/Loader";
import MediaFile from "@client.components/MediaFile";
import "./UserAssets.scss";
import MediaActions from "@client.components/MediaActions/MediaActions";

const UserAssets = forwardRef(({ onSelect, userId, selectedFileId }, ref) => {
  const selectedAssets = useSelector(makeSelectedAssets);
  const isUploading = useSelector(makeAdvertisersLoading);
  const [assetsMetaData, setAssetsMetaData] = useState({});

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleDelete = (id) => {
    alert("id", id);
  };
  useEffect(() => {
    dispatch(getSelectedAssetsAsync(userId));
  }, [dispatch, userId]);

  const [model, setModel] = useState(
    new UserAsset({ userLookupId: userId, lookupId: selectedFileId })
  );

  React.useImperativeHandle(ref, () => ({
    managementItem: model,
    validate: () => {
      const isValid = validateModel(model, model.validationRules);
      return isValid;
    },
  }));

  const changeModel = useCallback(
    (v, field) => {
      setModel(new UserAsset({ ...model, [field]: v }, model.validationMsgs));
    },
    [model]
  );

  return (
    <div className="user-assets" ref={ref}>
      {isUploading ? (
        <Loader loading={true} />
      ) : (
        <Uploader
          onChange={(file) => {
            model.file = file;

            dispatch(uploadUserAssetAsync(model)).then((resp) => {
              const data = get(resp, "payload", {});

              onSelect(data.lookup_id, data.secure_url);
            });
          }}
        />
      )}

      <Loader loading={selectedAssets.loading} />
      {!selectedAssets.loading &&
        selectedAssets.assets.map((asset, index) => (
          <div
            key={asset.lookupId}
            className={classNames("asset", {
              selected: asset.lookupId === model.lookupId,
            })}
          >
            <div
              className="image"
              onClick={() => {
                changeModel(model, "fileId");
                onSelect(asset.lookupId, asset.locationPath);
              }}
            >
              <MediaFile
                src={asset.locationPath}
                onLoad={({ width, height }) => {
                  assetsMetaData[asset.lookupId] = {
                    width,
                    height,
                  };
                  setAssetsMetaData({ ...assetsMetaData });
                }}
              />
              {asset.lookupId === model.lookupId && (
                <div className="img-badge">
                  {t("components.UserAssets.selected")}
                </div>
              )}
            </div>
            <div className="footer">
              <div>
                <div className="name">
                  {t("components.UserAssets.defaultFileName", {
                    index: index + 1,
                  })}
                </div>
                <div className="description">
                  {assetsMetaData[asset.lookupId]
                    ? `${assetsMetaData[asset.lookupId].width}x${
                        assetsMetaData[asset.lookupId].height
                      }`
                    : t("components.UserAssets.noMetaData")}
                </div>
              </div>
              <div>
                <MediaActions
                  mediaId={assetsMetaData.lookupId}
                  handleDelete={handleDelete}
                />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
});

UserAssets.displayName = "UserAssets";

UserAssets.propTypes = {
  onSelect: PropTypes.func,
  userId: PropTypes.string.isRequired,
  selectedFileId: PropTypes.string,
};

export default UserAssets;
