import React from "react";
import PropTypes from "prop-types";
import ReactSlider from "react-slider";
import Input from "@client.components/Input";
import "./Slider.scss";

const Slider = ({ min, max, value, currency, onChange }) => {
  return (
    <div className="slider">
      <div className="value">
        {currency}
        <Input
          value={value}
          onChange={(v) => onChange(v)}
          type={"number"}
          optional={{
            min,
            max,
            step: 1,
          }}
        />
      </div>

      <ReactSlider
        min={min}
        max={max}
        className="horizontal-slider"
        thumbClassName="thumb"
        trackClassName="track"
        value={value}
        onChange={(value) => {
          onChange(value);
        }}
        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
      />
    </div>
  );
};

Slider.propTypes = {
  onChange: PropTypes.func,
  currency: PropTypes.string,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default Slider;
