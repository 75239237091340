import UserService from "./userService";
import CampaignService from "./campaignService";
import EventEmitterService from "./eventEmitter";
import GoogleMapService from "./googleMapService";
import AdvertiserService from "./advertiserService";
import StatisticService from "./statisticService";
import DeviceService from "./deviceService";
import LocalStorageService from "./localStorage";
import CampaignDraftService from "./campaignDraftService";
import LocationService from "./locationService";
import DashboardService from "./dashboardService";
import VpnService from "./vpnService";

export default class ServiceProvider {
  static User = new UserService();
  static Dashboard = new DashboardService();
  static Campaign = new CampaignService();
  static CampaignDraft = new CampaignDraftService();
  static EventEmitter = new EventEmitterService();
  static GoogleMap = new GoogleMapService();
  static Advertiser = new AdvertiserService();
  static Statistic = new StatisticService();
  static Device = new DeviceService();
  static LocalStorage = new LocalStorageService();
  static Location = new LocationService();
  static Vpn = new VpnService();
}
