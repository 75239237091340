import BaseService from "@client.services/baseService";

export default class LocationService extends BaseService {
  async getAll() {
    return super.post("", {
      query: `query {
        fetchLocations {
          lookupId
          
          createdAt
          displayName
          polygon
          pricePerView
        }
      }`,
    });
  }

  async create(model) {
    return super.post("", {
      query: `mutation CreateLocation($fields: LocationInputType){
        createLocation(fields: $fields) {
          message
        }
      }`,
      variables: {
        fields: model.fields,
      },
    });
  }

  async update(model) {
    return super.post("", {
      query: `mutation UpdateLocation($fields: LocationInputType, $lookupId: String){
        updateLocation(fields: $fields, lookupId: $lookupId) {
          message
        }
      }`,
      variables: {
        fields: model.fields,
        lookupId: model.lookupId,
      },
    });
  }

  async delete(lookupId) {
    return super.post("", {
      query: `mutation DeleteLocation($lookupId: String){
        deleteLocation(lookupId: $lookupId) {
          message
        }
      }`,
      variables: {
        lookupId: lookupId,
      },
    });
  }
}
