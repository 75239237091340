import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeProfile } from "@client.pages/Login/reducer";
import * as Popover from "@radix-ui/react-popover";
import Pages from "@client.enums/pages";
import MediaFile from "@client.components/MediaFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faRightFromBracket,
  faFileInvoice,
} from "@fortawesome/free-solid-svg-icons";
import "./ProfileMenu.scss";

export const ProfileMenu = () => {
  const [role, setRole] = useState();
  const [open, setOpen] = useState(false);

  const handleClosePopover = () => {
    setOpen(false);
  };

  const { t } = useTranslation();
  const profile = useSelector(makeProfile);

  const renderCustomContent = () => {
    return (
      <MediaFile
        src={profile.avatarUrl}
        className="avatar"
        fallbackElement={() => (
          <div className="profile-menu">
            {profile.firstName.charAt(0)}
            {profile.lastName.charAt(0)}
          </div>
        )}
      />
    );
  };
  useEffect(() => {
    const renderRoles = () => {
      switch (profile.roleId) {
        case 1:
          return "Platform";
        case 2:
          return "Advertiser";
        case 3:
          return "Partner";
        case 4:
          return "Technician";
        default:
          return "No Role";
      }
    };
    setRole(renderRoles());
  }, [profile.roleId]);
  const links = [
    {
      url: Pages.ProfileGeneral,
      name: t("components.ProfileMenu.myaccount"),
      icon: faUser,
    },
    {
      url: Pages.ProfileBilling,
      name: t("components.ProfileTabs.billing"),
      icon: faFileInvoice,
    },
    // {
    //   url: Pages.ProfilePayment,
    //   name: t("components.ProfileTabs.paymentmethod"),
    //   icon: faWallet,
    // },
    // {
    //   url: Pages.ProfileNotifications,
    //   name: t("components.ProfileTabs.notifications"),
    //   icon: faBell
    // },
    // {
    //   url: Pages.ProfileChangePassword,
    //   name: t("components.ProfileTabs.changePassword"),
    //   icon: faKey,
    // },
  ];
  return (
    <div className="profile-menu-container">
      <Popover.Root open={open} onOpenChange={(newOpen) => setOpen(newOpen)}>
        <Popover.Trigger asChild>
          <button className="popover-button">{renderCustomContent()}</button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className="profile-popover-content"
            sideOffset={10}
            alignOffset={110}
          >
            <div className="profile-menu-popover-content">
              <div className="profile-information">
                {renderCustomContent()}
                <div className="profile-info">
                  <div className="profile-full-name">{`${profile.firstName} ${profile.lastName}`}</div>
                  <div>{profile.emailAddress}</div>
                  <div>{role}</div>
                </div>
              </div>
              <div className="profile-pages">
                {links.map((item) => (
                  <NavLink
                    key={item.url}
                    to={item.url}
                    title={item.name}
                    onClick={handleClosePopover}
                  >
                    <FontAwesomeIcon icon={item.icon} />
                    {item.name}
                  </NavLink>
                ))}
              </div>
              <div className="divider" />
              <div className="profile-pages profile-logout">
                <NavLink
                  title={t("components.ProfileMenu.logout")}
                  to={Pages.Logout}
                >
                  <FontAwesomeIcon icon={faRightFromBracket} />
                  <span>{t("components.ProfileMenu.logout")}</span>
                </NavLink>
              </div>
            </div>
            <Popover.Arrow className="popover-arrow" />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};
