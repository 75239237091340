import ServiceProvider from "@client.services/provider";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  platformDashboard: null,
  loading: false,
  error: null,
  filter: {
    isUnknown: true,
    isDynamic: true,
    isStatic: true,
    isOnline: true,
    isOffline: true,
    enableHeatmap: false,
    enableLocations: false,
    id: "",
  },
};

// Create an async thunk action to fetch platform dashboard data
export const fetchPlatformDashboardAsync = createAsyncThunk(
  "dashboard/fetchPlatformDashboard",
  async (filter = {}, { dispatch }) => {
    // Dispatch loading state
    dispatch(setLoading(true));
    try {
      // Call the service method to fetch platform dashboard data
      const platformDashboard =
        await ServiceProvider.Dashboard.getPlatformDashboard(
          filter.enableHeatmap,
          filter.enableLocations,
          filter.id
        );
      return platformDashboard;
    } finally {
      // Dispatch loading state after fetching data
      dispatch(setLoading(false));
    }
  }
);

// Create a slice for the reducer
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setDashboardFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlatformDashboardAsync.pending, (state) => {
        // Update loading state when fetchPlatformDashboard action is pending
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPlatformDashboardAsync.fulfilled, (state, action) => {
        // Update state with platform dashboard data when fetchPlatformDashboard action is fulfilled
        state.loading = false;
        state.platformDashboard = action.payload;
      })
      .addCase(fetchPlatformDashboardAsync.rejected, (state, action) => {
        // Update error state when fetchPlatformDashboard action is rejected
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export actions and reducer
export const { setLoading, setError, setDashboardFilter } =
  dashboardSlice.actions;

// Export selector to retrieve platform dashboard data from the state
export const makeDashboardFilter = (state) => state.dashboard.filter;
export const selectPlatformDashboard = (state) =>
  state.dashboard.platformDashboard;
// Export selector to retrieve loading state from the state
export const selectLoading = (state) => state.dashboard.loading;
// Export selector to retrieve error state from the state
export const selectError = (state) => state.dashboard.error;

export default dashboardSlice.reducer;
