import React, { useRef, useState } from "react";
import Title from "@client.components/Title";
import ProfileTabs from "@client.components/ProfileTabs";
import DataTable from "@client.components/DataTable";
import { getColumns } from "./consts";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { makeProfile } from "@client.pages/Login/reducer";
import Select from "react-select";
import { faArrowCircleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useStableParams from "@client.hooks/useStableParams";
import "./ProfileBilling.scss";

const ProfileBillingPage = () => {
  const profile = useSelector(makeProfile);
  const [selectedTimeOption, setSelectedTimeOption] = useState(null);
  const { profileId } = useStableParams();

  const data = [
    {
      index: 1,
      date: "2024-04-01",
      displayName: "John Doe",
      creditCard: "Visa",
      price: 100,
    },
    {
      index: 2,
      date: "2024-04-02",
      displayName: "Jane Smith",
      creditCard: "Mastercard",
      price: 150,
    },
    {
      index: 3,
      date: "2024-04-03",
      displayName: "Alice Johnson",
      creditCard: "American Express",
      price: 200,
    },
  ];

  const gridRef = useRef(null);
  const { t } = useTranslation();
  const onDelete = () => {};
  const onEdit = () => {};
  const onView = () => {};
  const onSelectionChanged = () => {};
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#FF0066" : "white", // Change background color on hover
      color: state.isFocused ? "white" : "black",
      border: state.selected ? "#FF0066" : "white",
    }),
  };
  const timeOptions = [
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "quarterly", label: "Quarterly" },
    { value: "all", label: "All Time" },
  ];

  return (
    <div className="profile-billing">
      <Title text="pages.ProfileGeneral.myAccount" />
      <ProfileTabs profileId={profileId} />
      <div className="d-flex mb-3 align-items-center  justify-content-between ">
        <Select
          styles={customStyles}
          value={selectedTimeOption}
          onChange={setSelectedTimeOption}
          placeholder="All Time"
          options={timeOptions}
        />
        <button
          onClick={() => alert("under construction")}
          className="download-button"
        >
          <FontAwesomeIcon
            style={{ fontSize: "20px" }}
            icon={faArrowCircleDown}
          />
        </button>
      </div>
      <DataTable
        ref={gridRef}
        data={data}
        columns={getColumns(profile, t, onEdit, onView, onDelete)}
        loading={false}
        onSelectionChanged={onSelectionChanged}
      />
    </div>
  );
};

export default ProfileBillingPage;
