import config from "@client.config";
import moment from "moment";
import React from "react";
import Icon from "@client.components/Icon";

export const getColumns = (t, onApprove) => [
  {
    field: "createdAt",
    cellRenderer: (params) => (
      <div>{moment(params.value).format(config.dateTimeFormat)}</div>
    ),
    tooltipField: "DeviceName",
    headerName: t("pages.Devices.columns.recievedAt"),
  },
  {
    headerName: "MachineId",
    minWidth: 280,
    field: "machineId",
  },
  {
    headerName: "Code",
    field: "verificationCode",
  },
  {
    headerName: "Country",
    field: "country",
  },
  {
    headerName: "Timezone",
    field: "timezoneCode",
  },
  {
    headerName: "",
    maxWidth: 50,
    colId: "actions",
    cellRenderer: ({ data }) => (
      <Icon name="check" onClick={() => onApprove(data.lookupId)} />
    ),
  },
];
