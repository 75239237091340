import BaseModel from "./base";
import { get } from "lodash";

class AdvertiserModel extends BaseModel {
  constructor(advertiser = {}, msg = {}) {
    super(msg);
    this.firstName = get(advertiser, "firstName", "");
    this.lastName = get(advertiser, "lastName", "");
    this.emailAddress = get(advertiser, "emailAddress", "");
    this.password = get(advertiser, "password", "");
    this.roleId = get(advertiser, "roleId", null);
    this.phoneNumber = get(advertiser, "phoneNumber", "")
    this.companyName = get(advertiser, "companyName", "")
    this.currencyCode = get(advertiser, "currencyCode", "")
    this.currencySymbol = get(advertiser, "currencySymbol", "")

    this.validationMsgs = msg;
    this.validationRules = {
      firstName: {
        presence: { allowEmpty: false, message: msg.firstName || "" },
      },
      lastName: {
        presence: { allowEmpty: false, message: msg.lastName || "" },
      },
      emailAddress: {
        presence: { allowEmpty: false, message: msg.emailAddress || "" },
        // emailAddress: { message: msg.invalidEmail || "" },
      },
      roleId: {
        presence: { allowEmpty: false, message: msg.roleId || "" },
      },
      phoneNumber: {
        presence: { allowEmpty: false, message: msg.phoneNumber || "" },
      },
      companyName: {
        presence: { allowEmpty: false, message: msg.companyName || "" },
      },
      password: {
        presence: { allowEmpty: false, message: msg.password || "" },
      },
      currencyCode: {
        presence: { allowEmpty: false, message: msg.currencyCode || "USD"}
      },
      currencySymbol: {
        presence: { allowEmpty: false, message: msg.currencyCode || "$"}
      }
    };
  }

  get fields() {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      emailAddress: this.emailAddress,
      password: this.password,
      roleId: this.roleId,
      phoneNumber: this.phoneNumber,
      companyName: this.companyName,
      currencyCode: this.currencyCode,
      currencySymbol: this.currencySymbol
    };
  }
}

export default AdvertiserModel;
