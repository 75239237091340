import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDeviceCapturesAsync, makeCaptures } from "../reducer";

import DeviceSlider from "@client.components/DeviceSlider";
import Button from "@client.components/Button";

import useStableParams from "@client.hooks/useStableParams";

import Dropdown from "@client.components/Dropdown";
import Input from "@client.components/Input";
import DateTimeRangePicker from "@client.components/DateTimeRangePicker/DateTimeRangePicker";
import DeviceCaptureStatuses from "@client.enums/deviceCaptureStatuses";
import NoDataPlaceholder from "@client.components/NoDataPlaceholder";

const ITEMS_PER_PAGE = 5;

const DeviceCaptures = () => {
  const [maxResults, setMaxResults] = useState(ITEMS_PER_PAGE);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState(DeviceCaptureStatuses.Processed);

  const [filter, setFilter] = useState({
    startDate: "",
    endDate: "",
    limit: ITEMS_PER_PAGE,
    filterType: [],
  });

  const dispatch = useDispatch();

  const { id } = useStableParams();

  const imageOverview = useSelector(makeCaptures);

  useEffect(() => {
    dispatch(
      fetchDeviceCapturesAsync({
        lookupId: id,
        limit: filter.limit || ITEMS_PER_PAGE,
        offset: 0,
        startDate: filter.startDate,
        endDate: filter.endDate,
        filterType: filter.filterType,
      })
    );
  }, [dispatch, id, filter]);

  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption);
  };

  const statusOptions = [
    {
      id: DeviceCaptureStatuses.NotProcessed,
      name: "Not Processed",
    },
    {
      id: DeviceCaptureStatuses.Processed,
      name: "Processed",
    },
  ];

  return (
    <div className="device-data-card-row">
      <div className="device-data-column device-captures">
        <div className="caption">Device Captures</div>
        <div className="search-bar">
          <div className="search-inputs">
            <Dropdown
              items={statusOptions}
              valueKey="id"
              labelKey="name"
              placeholder="Status"
              label="Status"
              defaultValue={status}
              onPrepareValue={(v) => parseInt(v)}
              onSelect={handleStatusChange}
            />
            <DateTimeRangePicker
              onChange={(startDate, endDate) => {
                setStartDate(startDate || "");
                setEndDate(endDate || "");
              }}
            />
            <Input
              label="Max Results"
              type="number"
              value={maxResults}
              onChange={(e) => {
                setMaxResults(parseInt(e));
              }}
            />
          </div>
          <Button
            text="Submit"
            className="search-submit"
            onClick={() => {
              setFilter({
                limit: maxResults,
                filterType: [status],
                endDate,
                startDate,
              });
            }}
          />
        </div>
        {imageOverview.results.length === 0 && !imageOverview.loading ? (
          <NoDataPlaceholder description="No captures for this filter" />
        ) : (
          <DeviceSlider data={imageOverview} status={status} />
        )}
      </div>
    </div>
  );
};

export default DeviceCaptures;
