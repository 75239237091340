import React, { useState, useCallback, useEffect } from "react";
import { get } from "lodash";
import classNames from "classnames";
import Title from "@client.components/Title";
import ProfileTabs from "@client.components/ProfileTabs";
import Uploader from "@client.components/Uploader";
import Input from "@client.components/Input";
import ProfileModel from "@client.models/profile";
import { useTranslation } from "react-i18next";
import Button from "@client.components/Button";
import { useDispatch, useSelector } from "react-redux";
import { makeProfileLoading, saveUserProfileAsync } from "./reducer";
import Loader from "@client.components/Loader";
import { validateModel } from "@client.utils/form";
import { getProfileAsync, makeProfile } from "@client.pages/Login/reducer";
import UserAsset from "@client.models/userAsset";
import {
  getAdvertiserByIdAsync,
  makeSelectedAdvertiser,
  uploadUserAssetAsync,
} from "@client.pages/Advertiser/reducer";
import useToast from "@client.hooks/useToast";
import "./ProfileGeneral.scss";
import useStableParams from "@client.hooks/useStableParams";
import moment from "moment";
import config from "@client.config";
import RoleDropdown from "@client.components/RoleDropdown";

const ProfileGeneralPage = () => {
  const { t } = useTranslation();
  const { showToastAfterRequest } = useToast();
  const dispatch = useDispatch();
  const { profileId } = useStableParams();
  const selectedAdvertiser = useSelector(makeSelectedAdvertiser);

  const isLoading = useSelector(makeProfileLoading);
  const profile = useSelector(makeProfile);

  useEffect(() => {
    if (profileId) {
      dispatch(getAdvertiserByIdAsync(profileId));
    }
  }, [dispatch, profileId]);

  const [model, setModel] = useState(() => {
    if (profileId && selectedAdvertiser.advertiser) {
      const advertiser = selectedAdvertiser.advertiser;

      return new ProfileModel(
        { ...advertiser },
        {
          firstName: t("pages.ProfileGeneral.validation.firstName"),
          lastName: t("pages.ProfileGeneral.validation.lastName"),
          emailAddress: t("pages.ProfileGeneral.validation.emailAddress"),
          invalidEmail: t("pages.ProfileGeneral.validation.invalidEmail"),
          phoneNumber: t("pages.profileGeneral.validation.phoneNumber"),
          companyName: t("pages.ProfileGeneral.validation.companyName"),
          currencyCode: t("pages.ProfileGeneral.validation.currencyCode"),
          currencySymbol: t("pages.ProfileGeneral.validation.currencySymbol"),
        }
      );
    } else {
      return new ProfileModel(
        { ...profile },
        {
          firstName: t("pages.ProfileGeneral.validation.firstName"),
          lastName: t("pages.ProfileGeneral.validation.lastName"),
          emailAddress: t("pages.ProfileGeneral.validation.emailAddress"),
          invalidEmail: t("pages.ProfileGeneral.validation.invalidEmail"),
          phoneNumber: t("pages.profileGeneral.validation.phoneNumber"),
          companyName: t("pages.ProfileGeneral.validation.companyName"),
          currencyCode: t("pages.ProfileGeneral.validation.currencyCode"),
          currencySymbol: t("pages.ProfileGeneral.validation.currencySymbol"),
        }
      );
    }
  });

  const changeModel = useCallback(
    (v, field) => {
      setModel(
        new ProfileModel({ ...model, [field]: v }, model.validationMsgs)
      );
    },
    [model]
  );

  const saveUser = async (msg) => {
    const resp = await dispatch(saveUserProfileAsync(model));

    showToastAfterRequest(resp, msg).then(() => {
      dispatch(getProfileAsync());
    });
  };

  const renderContent = () => (
    <div className="content">
      <div className="uploader-content">
        <Uploader
          placeholderUrl={model.avatarUrl}
          onChange={(file) => {
            const fileModel = new UserAsset({
              userLookupId: profile.lookupId,
              file: file,
            });

            dispatch(uploadUserAssetAsync(fileModel)).then((resp) => {
              const data = get(resp, "payload", {});
              changeModel(data.secure_url, "avatarUrl");
              model.avatarUrl = data.secure_url;
              saveUser("pages.ProfileGeneral.successPhotoUpload");
            });
          }}
        />
        <div className="toggle-container">
          <label htmlFor="visibleProfie">
            {t("pages.ProfileGeneral.visible")}
          </label>
          <div className="toggle-switch">
            <input type="checkbox" id="visibleProfie" checked={false} />
            <label htmlFor="profileVisisble" className="toggle-label"></label>
          </div>
        </div>
      </div>
      <div className="user-settings-content">
        <Input
          value={model.firstName}
          onChange={(v) => changeModel(v, "firstName")}
          label={t("components.Advertisers.firstName")}
        />
        <Input
          value={model.lastName}
          onChange={(v) => changeModel(v, "lastName")}
          label={t("components.Advertisers.lastName")}
        />
        <Input
          value={model.emailAddress}
          onChange={(v) => changeModel(v, "emailAddress")}
          label={t("components.Advertisers.emailAddress")}
        />
        <Input
          value={model.phoneNumber}
          onChange={(v) => changeModel(v, "phoneNumber")}
          label={t("components.Advertisers.phoneNumber")}
        />
        <Input
          value={model.companyName}
          onChange={(v) => changeModel(v, "companyName")}
          label={t("components.Advertisers.companyName")}
        />

        <Input
          value={model.currencySymbol}
          onChange={(v) => changeModel(v, "currencySymbol")}
          label={t("components.Advertisers.currencySymbol")}
        />
        <Input
          value={model.currencyCode}
          onChange={(v) => changeModel(v, "currencyCode")}
          label={t("components.Advertisers.currencyCode")}
        />
        <RoleDropdown
          label="Role"
          value={model.roleId}
          onSelect={(v) => changeModel(v, "roleId")}
        />

        {selectedAdvertiser.advertiser ? (
          <Input
            value={moment(selectedAdvertiser.advertiser.createdAt).format(
              config.dateTimeFormat
            )}
            label={t("components.Advertisers.created")}
            readOnly={true}
          />
        ) : (
          <Input
            value={moment(profile.createdAt).format(config.dateTimeFormat)}
            label={t("components.Advertisers.created")}
            readOnly={true}
          />
        )}

        {selectedAdvertiser.advertiser ? (
          <>
            <Input
              value={
                selectedAdvertiser &&
                selectedAdvertiser.advertiser &&
                selectedAdvertiser.advertiser.lastLoginTime !== null
                  ? moment(selectedAdvertiser.advertiser.lastLoginTime).format(
                      config.dateTimeFormat
                    )
                  : t("components.Advertisers.notLoggedIn")
              }
              label={t("components.Advertisers.lastLoggedIn")}
              readOnly={true}
            />
            <div>{selectedAdvertiser.advertiser.lastLoginTime}</div>
          </>
        ) : (
          //  moment(profile.lastLoginTime).format(config.dateTimeFormat)
          <>
            <Input
              value={moment(profile.lastLoginTime).format(
                config.dateTimeFormat
              )}
              label={t("components.Advertisers.lastLoggedIn")}
              readOnly={true}
            />
          </>
        )}

        <div>
          <Button
            text={t("pages.ProfileGeneral.save")}
            onClick={async () => {
              const isValid = validateModel(model);
              if (isValid) {
                await saveUser("pages.ProfileGeneral.successSave");
              }
            }}
          />
        </div>
      </div>
    </div>
  );
  return (
    <div className="profile-general page">
      <Title text="pages.ProfileGeneral.myAccount" />
      <ProfileTabs profileId={profileId} />
      <div className={classNames({ "content-loading": isLoading })}>
        {isLoading ? <Loader loading={true} /> : renderContent()}
      </div>
    </div>
  );
};

export default ProfileGeneralPage;
