import React, { useEffect } from "react";
import "./ArchivedCampaigns.scss";
import ArchivedCampaignsOverview from "@client.components/ArchivedsCampaign";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdvertiserCampaignsAsync,
  makeSelectedCampaigns,
} from "@client.pages/Advertiser/reducer";
import useStableParams from "@client.hooks/useStableParams";

const ArchivedCampaignsPage = () => {
  const selectedCampaigns = useSelector(makeSelectedCampaigns);
  const { id } = useStableParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdvertiserCampaignsAsync(id));
  }, [dispatch, id]);
  const statusArchived = selectedCampaigns.campaigns.filter(
    (obj) => obj.isActive === false
  );
  return (
    <div className="paused-campaigns">
      <ArchivedCampaignsOverview data={statusArchived} />
    </div>
  );
};

export default ArchivedCampaignsPage;
