const CampaignStatuses = Object.freeze({
  Draft: 0,
  PendingApproval: 1,
  Queued: 2,
  Active: 3,
  Paused: 4,
  Archive: 5,
});

export default CampaignStatuses;
