import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as Popover from "@radix-ui/react-popover";
import { useNavigate } from "react-router-dom";
import Pages from "@client.enums/pages";
import Icon from "@client.components/Icon";

const DraftActions = ({ campaignId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const actions = [
    {
      name: "components.CampaignActions.preview",
      icon: "eye",
      onClick: () => {
        navigate(`${Pages.CreateCampaign}/${campaignId}`);
      },
    },
  ];
  return (
    <div className="campaign-actions-control">
      <Popover.Root>
        <Popover.Trigger asChild>
          <button className="popover-button">
            <Icon name="vertical-dots" />
          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className="nm-popover-content"
            sideOffset={10}
            alignOffset={110}
          >
            <div className="campaign-actions">
              {actions.map((action) => (
                <div
                  className="action"
                  key={action.name}
                  onClick={action.onClick}
                >
                  <Icon name={action.icon} />
                  {t(action.name)}
                </div>
              ))}
            </div>
            <Popover.Arrow className="popover-arrow" />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

DraftActions.propTypes = {
  campaignId: PropTypes.string,
};

export default React.memo(DraftActions);
