import CircleIcon from "@client.components/CircleIcon";
import React from "react";
import "./Users.scss";

export const getColumns = () => [
  {
    field: "index",
    tooltipField: "index",
    headerName: "#",
    valueGetter: (params) => params.node.childIndex + 1, // Adding 1 to start index from 1
    cellRenderer: (params) => (
      <div className="user-name clickable-cell">{params.value}</div>
    ),
    maxWidth: 80,
  },
  {
    field: "companyName",
    headerName: "Company Name",
    cellRenderer: (params) => (
      <div className="user-name clickable-cell">{params.value || "N/A"}</div>
    ),
  },
  {
    field: "Name",
    headerName: "Name",
    cellRenderer: (params) => {
      const avatarUrlString = String(params.data.avatarUrl);
      return (
        <div className="user-name clickable-cell">
          {params.data.avatarUrl ? (
            <>
              <img className="avatar" src={avatarUrlString} alt="" />
              {params.data.fullName}
            </>
          ) : (
            <>
              <CircleIcon index={params.rowIndex} name={params.data.fullName} />
              <span> {params.data.fullName}</span>
            </>
          )}
        </div>
      );
    },
  },
  {
    field: "emailAddress",
    headerName: "Email",
  },
  {
    field: "Role",
    valueGetter: (params) => {
      switch (params.data.roleId) {
        case 1:
          return "Admin";
        case 2:
          return "Manager";
        case 3:
          return "Employee";
        case 4:
          return "Technician";
        case 5:
          return "User";
        default:
          return "N/A";
      }
    },

    headerName: "Role",
  },
];
