import GridManagementButtons from "@client.components/GridManagementButtons";
import config from "@client.config";
import moment from "moment";
import React from "react";
import DeviceTypes from "@client.enums/deviceTypes";
import "./Devices.scss";

export const getColumns = (t, onView, onEdit, onDelete) => [
  {
    field: "index",
    tooltipField: "index",
    headerName: "#",
    valueGetter: (params) => params.node.childIndex + 1, // Adding 1 to start index from 1
    cellRenderer: (params) => (
      <div className="device-name clickable-cell">{params.value}</div>
    ),
    maxWidth: 80,
  },

  {
    field: "Name",
    cellRenderer: (params) => (
      <div className="device-name clickable-cell">{params.data.deviceName}</div>
    ),
    tooltipField: "DeviceName",
    headerName: t("pages.Devices.columns.name"),
  },
  {
    field: "type",
    headerName: t("pages.Devices.columns.type"),
    cellClass: "data-table-cell",
    cellRenderer: (data) => {
      let label = "pages.Devices.na";
      if (data.data.type) {
        label =
          data.data.type === DeviceTypes.Dynamic
            ? "pages.Devices.type.dynamic"
            : "pages.Devices.type.static";
      }
      return t(label);
    },
  },
  {
    field: "isOnline",
    headerName: t("pages.Devices.columns.status"),
    cellClass: "data-table-cell cell-status",
    cellRenderer: (data) => {
      const statusClassName = data.data.isOnline ? "on" : "off";
      const status = t(
        data.data.isOnline
          ? "pages.Devices.status.online"
          : "pages.Devices.status.offline"
      );
      return <span className={`status ${statusClassName}`}>{status}</span>;
    },
  },
  {
    field: "lastTelemetry",
    headerName: t("pages.Devices.columns.lastTelemetry"),
    cellClass: "data-table-cell",
    cellRenderer: (data) => {
      return data.data.lastTelemetry
        ? moment(data.data.lastTelemetry.createdAt).format(
            config.dateTimeFormat
          )
        : t("pages.Devices.na");
    },
  },
  {
    field: "vpnConfiguration",
    headerName: t("pages.Devices.columns.vpnConfiguration"),
    cellClass: "data-table-cell",
    cellRenderer: (data) => {
      return data.data.vpnConfiguration
        ? data.data.vpnConfiguration
        : t("pages.Devices.na");
    },
  },
  {
    field: "country",
    headerName: t("pages.Devices.columns.country"),
    cellClass: "data-table-cell",
  },
  {
    colId: "actions",
    headerName: "",
    cellRenderer: ({ data }) => (
      <>
        <GridManagementButtons
          item={data}
          onView={onView}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </>
    ),
    maxWidth: 70,
  },
];
