import React from "react";
import PropTypes from "prop-types";
import "./Loader.scss";

const Loader = ({ loading }) => {
  return loading ? <span className="loader" data-testid="Loader"></span> : null;
};

Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default Loader;
