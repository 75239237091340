import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "./ToggleSwitch.scss";

const ToggleSwitch = ({ onChange, value }) => {
  const [isOn, setIsOn] = useState(false);

  useEffect(() => {
    setIsOn(value);
  }, [value]);

  const handleToggle = () => {
    setIsOn(!isOn);
    onChange(!isOn);
  };

  return (
    <div
      className={`toggle-switch-component ${isOn ? "on" : "off"}`}
      onClick={handleToggle}
    >
      <div className={`toggle-knob ${isOn ? "on" : "off"}`} />
    </div>
  );
};

ToggleSwitch.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.bool,
};

export default ToggleSwitch;
