import React, { useState } from "react";
import AdvertiserOverview from "./AdvertiserOverview";
import CampaignsTab from "./Tabs/CampaignsTab";
import DraftsTab from "./Tabs/DraftsTab";
import AdvertiserTabs, { AdvertiserTabsEnum } from "./AdvertiserTabs";
import useStableParams from "@client.hooks/useStableParams";
import "./Advertiser.scss";

const AdvertiserPage = () => {
  const [selectedTab, setSelectedTab] = useState(AdvertiserTabsEnum.Campaigns);
  const { id } = useStableParams();

  const renderTabs = () => {
    switch (selectedTab) {
      case AdvertiserTabsEnum.Campaigns:
        return <CampaignsTab />;
      case AdvertiserTabsEnum.Drafts:
        return <DraftsTab />;
    }
  };

  return (
    <div className="advertiser-page page">
      <AdvertiserOverview advertiserId={id} />
      <AdvertiserTabs value={selectedTab} onSelect={setSelectedTab} />
      {renderTabs()}
    </div>
  );
};

export default React.memo(AdvertiserPage);
