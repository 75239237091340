import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as Popover from "@radix-ui/react-popover";
import { useNavigate } from "react-router-dom";
import Pages from "@client.enums/pages";
import Icon from "@client.components/Icon";
import { useDispatch } from "react-redux";
import { toggleIsActive } from "@client.pages/Campaigns/reducer";

const CampaignActions = ({ campaignId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleToggleActiveAndArchive = () => {
    dispatch(toggleIsActive(campaignId));
  };

  const actions = [
    {
      name: "components.CampaignActions.open",
      icon: "open",
      onClick: () => {
        navigate(`${Pages.Campaign}/${campaignId}`);
      },
    },
    {
      name: "components.CampaignActions.pause",
      icon: "paused-campaigns",
      onClick: () => {
        alert("Under construction " + campaignId);
      },
    },
    {
      name: "components.CampaignActions.archive",
      icon: "archived-campaigns",
      onClick: handleToggleActiveAndArchive,
    },
  ];
  return (
    <div className="campaign-actions-control">
      <Popover.Root>
        <Popover.Trigger asChild>
          <button className="popover-button">
            <Icon name="vertical-dots" />
          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className="nm-popover-content"
            sideOffset={10}
            alignOffset={110}
          >
            <div className="campaign-actions">
              {actions.map((action) => (
                <div
                  className="action"
                  key={action.name}
                  onClick={action.onClick}
                >
                  <Icon name={action.icon} />
                  {t(action.name)}
                </div>
              ))}
            </div>
            <Popover.Arrow className="popover-arrow" />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

CampaignActions.propTypes = {
  advertiserId: PropTypes.string,
  campaignId: PropTypes.string,
};

export default React.memo(CampaignActions);
