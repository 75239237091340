import React, { useEffect, useState } from "react";
import Title from "@client.components/Title";
import ProfileTabs from "@client.components/ProfileTabs";
import "./ProfileNotifications.scss";
import { useTranslation } from "react-i18next";
import Button from "@client.components/Button";
import useStableParams from "@client.hooks/useStableParams";

const ProfileNotificationsPage = () => {
  const { t } = useTranslation();
  const { profileId } = useStableParams();
  const [notificationsEnabled, setNotificationsEnabled] = useState({
    campaignStart: false,
    campaignEnd: false,
    campaignStop: false,
    news: false,
    products: false,
    blog: false,
  });
  useEffect(() => {
   
  }, [notificationsEnabled]);
  const toggleNotifications = (switchName) => {
    setNotificationsEnabled((prev) => ({
      ...prev,
      [switchName]: !prev[switchName],
    }));
  };
  return (
    <div className="profile-notifications">
      <Title text="pages.ProfileGeneral.myAccount" />
      <ProfileTabs profileId={profileId} />
      <div className="toggle-group">
    <div className="toggle-category">
      <div className="toggle-category-title">{t("pages.ProfileNotification.Activity")}</div>
      <div className="d-flex align-items-center  gap-2">

      <div className="toggle-switch">
        <input
          type="checkbox"
          id="campaignStart"
          checked={notificationsEnabled.campaignStart}
          onChange={() => toggleNotifications("campaignStart")}
          />
        <label htmlFor="campaignStart" className="toggle-label"></label>
      </div>
      <label htmlFor="campaignStart">{t("pages.ProfileNotification.Activity.campaignStart")}</label>
          </div>
          <div className="d-flex align-items-center gap-2">

      <div className="toggle-switch">
        <input
          type="checkbox"
          id="campaignEnd"
          checked={notificationsEnabled.campaignEnd}
          onChange={() => toggleNotifications("campaignEnd")}
          />
        <label htmlFor="campaignEnd" className="toggle-label"></label>
      </div>
      <label htmlFor="campaignEnd">{t("pages.ProfileNotification.Activity.campaignEnd")}</label>
          </div>
          <div className="d-flex align-items-center gap-2">

      <div className="toggle-switch">
        <input
          type="checkbox"
          id="campaignStop"
          checked={notificationsEnabled.campaignStop}
          onChange={() => toggleNotifications("campaignStop")}
          />
        <label htmlFor="campaignStop" className="toggle-label"></label>
      </div>
      <label htmlFor="campaignStop">{t("pages.ProfileNotification.Activity.campaignStop")}</label>
          </div>
    </div>
    <div className="toggle-category">
      <div className="toggle-category-title">{t("pages.ProfileNotification.Application")}</div>
      <div className="d-flex align-items-center gap-2">

      <div className="toggle-switch">
        <input
          type="checkbox"
          id="news"
          checked={notificationsEnabled.news}
          onChange={() => toggleNotifications("news")}
          />
        <label htmlFor="news" className="toggle-label"></label>
          </div>
          <label htmlFor="news">{t("pages.ProfileNotification.Application.news")}</label>
      </div>
      <div className="d-flex align-items-center gap-2">
      <div className="toggle-switch">
        <input
          type="checkbox"
          id="products"
          checked={notificationsEnabled.products}
          onChange={() => toggleNotifications("products")}
          />
        <label htmlFor="products" className="toggle-label"></label>
          </div>
          <label htmlFor="products">{t("pages.ProfileNotification.Application.products")}</label>
      </div>
      <div className="d-flex align-items-center gap-2">
      <div className="toggle-switch">
        <input
          type="checkbox"
          id="blog"
          checked={notificationsEnabled.blog}
          onChange={() => toggleNotifications("blog")}
          />
        <label htmlFor="blog" className="toggle-label"></label>
          </div>
          <label htmlFor="blog">{t("pages.ProfileNotification.Application.blog")}</label>
      </div>
    </div>
  </div>
  <div className="d-flex justify-content-end ">
    <Button text="Save Changes"/>
    </div>
    </div>
  );
};

export default ProfileNotificationsPage;
