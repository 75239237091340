import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import GoogleMap from "@client.components/GoogleMap";
import { makeDashboardFilter } from "./reducer";
import DashboardMapFilter from "./DashboardMapFilter";
import DeviceStatuses from "@client.enums/deviceStatuses";
import DeviceTypes from "@client.enums/deviceTypes";

const DevicesOverview = ({ deviceMap, deviceLocation, deviceHeatmap }) => {
  const dashboardFilter = useSelector(makeDashboardFilter);
  const [recenterMapTimestamp, setRecenterMapTimestamp] = useState(undefined);
  const [selectedClusterMarkers, setSelectedClusterMarkers] = useState([]);

  const typeStatistic = {
    dynamicQty: 0,
    staticQty: 0,
    unknownQty: 0,
  };

  const statusStatistic = {
    onlineQty: 0,
    offlineQty: 0,
  };

  const markerPositions = deviceMap
    ? deviceMap
        .filter((item) => {
          let statusMatch = false;
          let typeMatch = false;

          if (item.status) {
            statusStatistic.onlineQty++;
          } else {
            statusStatistic.offlineQty++;
          }

          switch (item.modelType) {
            case DeviceTypes.Unknown:
              typeStatistic.unknownQty++;
              break;
            case DeviceTypes.Static:
              typeStatistic.staticQty++;
              break;
            case DeviceTypes.Dynamic:
              typeStatistic.dynamicQty++;
              break;
          }

          if (
            dashboardFilter.isOnline &&
            item.status == DeviceStatuses.Online
          ) {
            statusMatch = true;
          }

          if (
            dashboardFilter.isOffline &&
            item.status == DeviceStatuses.Offline
          ) {
            statusMatch = true;
          }

          if (
            dashboardFilter.isDynamic &&
            DeviceTypes.Dynamic === item.modelType
          ) {
            typeMatch = true;
          }

          if (
            dashboardFilter.isUnknown &&
            DeviceTypes.Unknown === item.modelType
          ) {
            typeMatch = true;
          }

          if (
            dashboardFilter.isStatic &&
            DeviceTypes.Static === item.modelType
          ) {
            typeMatch = true;
          }

          return statusMatch && typeMatch;
        })
        .map((device) => ({
          lat: device.latitude,
          lng: device.longitude,
          deviceId: device.lookupId,
          status: device.status,
        }))
    : [];

  return (
    <div>
      <div className="devices-map">
        <GoogleMap
          className="dashboard-google-map"
          options={{
            mapTypeControl: false,
            disableDefaultUI: true,
          }}
          markers={markerPositions}
          polymarks={dashboardFilter.enableLocations ? deviceLocation : []}
          heatmapData={dashboardFilter.enableHeatmap ? deviceHeatmap : []}
          recenterMapTimestamp={recenterMapTimestamp}
          onClusterClick={(markersData) => {
            const deviceIds = markersData.map((d) => d.deviceId);
            setSelectedClusterMarkers(
              deviceMap.filter((d) => deviceIds.includes(d.lookupId))
            );
          }}
          onMarkerClick={(marker) => {
            setSelectedClusterMarkers(
              deviceMap.filter((d) => d.lookupId === marker.deviceId)
            );
          }}
        >
          <DashboardMapFilter
            onRecenterClick={() =>
              setRecenterMapTimestamp(new Date().getTime())
            }
            onFilterChange={console.log}
            typeStatistic={typeStatistic}
            statusStatistic={statusStatistic}
            selectedDeviceInfos={selectedClusterMarkers}
          />
        </GoogleMap>
      </div>
    </div>
  );
};
DevicesOverview.propTypes = {
  deviceMap: PropTypes.array.isRequired,
  deviceHeatmap: PropTypes.array.isRequired,
  deviceLocation: PropTypes.array.isRequired,
};
export default DevicesOverview;
