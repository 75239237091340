import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./Title.scss";

export const Title = ({ text, children }) => {
  const { t } = useTranslation();

  return (
    <div className="page-title">
      {t(text)} {children}
    </div>
  );
};

Title.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
};
