import React, { useCallback, forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Input from "@client.components/Input";
import { useTranslation } from "react-i18next";
import { validateModel } from "@client.utils/form";
import DeviceModel from "@client.models/device";
import Dropdown from "@client.components/Dropdown";
import Countries from "@client.enums/countries";
import ServiceProvider from "@client.services/provider";
import DeviceTypes from "@client.enums/deviceTypes";
import "./DeviceManagement.scss";

const DeviceManagement = forwardRef(({ device }, ref) => {
  const { t } = useTranslation();

  const [model, setModel] = useState(new DeviceModel());
  const [vpnList, setVpnList] = useState([]);
  const [accountList, setAccountList] = useState([]);

  useEffect(() => {
    Promise.all([
      ServiceProvider.Advertiser.getAll(),
      ServiceProvider.Vpn.getAll(),
    ]).then((result) => {
      setAccountList(result[0].data.fetchAccounts.results);
      setVpnList(result[1].data.fetchVpnServers);
    });
  }, []);

  useEffect(() => {
    setModel(
      new DeviceModel(
        { ...device },
        {
          name: t("pages.Devices.validation.name"),
          softwareVersion: t("pages.Devices.validation.softwareVersion"),
          hardwareVersion: t("pages.Devices.validation.hardwareVersion"),
          country: t("pages.Devices.validation.country"),
          accountId: t("pages.Devices.validation.accountId"),
          vpnId: t("pages.Devices.validation.vpnId"),
          typeId: t("pages.Devices.validation.typeId"),
        }
      )
    );
  }, [t, device]);

  React.useImperativeHandle(ref, () => ({
    managementItem: model,
    validate: () => {
      const isValid = validateModel(model, model.validationRules);
      return isValid;
    },
  }));

  const changeModel = useCallback(
    (v, field) => {
      setModel(new DeviceModel({ ...model, [field]: v }, model.validationMsgs));
    },
    [model]
  );

  const countries = Object.keys(Countries).map((k) => ({
    id: parseInt(k),
    name: Countries[k],
  }));

  const types = [
    {
      id: DeviceTypes.Dynamic,
      name: t("pages.Devices.type.dynamic"),
    },
    {
      id: DeviceTypes.Static,
      name: t("pages.Devices.type.static"),
    },
  ];

  return (
    <div className="create-device" ref={ref}>
      {model.lookupId && (
        <Input
          value={model.deviceName}
          onChange={(v) => changeModel(v, "deviceName")}
          label={t("pages.Devices.columns.name")}
        />
      )}
      <Input
        value={model.softwareVersion}
        type="number"
        onChange={(v) => changeModel(v, "softwareVersion")}
        label={t("pages.Devices.columns.softwareVersion")}
      />
      <Input
        value={model.hardwareVersion}
        type="number"
        onChange={(v) => changeModel(v, "hardwareVersion")}
        label={t("pages.Devices.columns.hardwareVersion")}
      />
      <Dropdown
        items={countries}
        defaultValue={model.countryId}
        label={t("pages.Devices.columns.country")}
        valueKey="id"
        labelKey="name"
        onPrepareValue={(v) => parseInt(v)}
        onSelect={(val) => {
          changeModel(val, "countryId");
        }}
      />
      <Dropdown
        items={types}
        defaultValue={model.boardTypeId}
        label={t("pages.Devices.columns.typeId")}
        valueKey="id"
        labelKey="name"
        onPrepareValue={(v) => parseInt(v)}
        onSelect={(val) => {
          changeModel(val, "boardTypeId");
        }}
      />
      <Dropdown
        items={vpnList}
        defaultValue={model.vpnServerId}
        label={t("pages.Devices.columns.vpnId")}
        valueKey="lookupId"
        labelKey="serverName"
        onSelect={(val) => {
          changeModel(val, "vpnServerId");
        }}
      />
      <Dropdown
        items={accountList}
        defaultValue={model.assignedToId}
        label={t("pages.Devices.columns.accountId")}
        valueKey="lookupId"
        labelKey="companyName"
        onSelect={(val) => {
          changeModel(val, "assignedToId");
        }}
      />
    </div>
  );
});

DeviceManagement.displayName = "DeviceManagement";

DeviceManagement.defaultProps = {
  device: {},
};

DeviceManagement.propTypes = {
  device: PropTypes.object,
};

export default DeviceManagement;
