import BaseModel from "./base";
import { get } from "lodash";

class ForgotPasswordModel extends BaseModel {
  constructor(user = {}, msg) {
    super(msg);

    this.emailAddress = get(user, "emailAddress", "");

    this.validationMsgs = msg;
    this.validationRules = {
      emailAddress: {
        presence: { allowEmpty: false, message: msg.email },
        emailAddress: { message: msg.invalidEmail },
      },
    };
  }
}

export default ForgotPasswordModel;
