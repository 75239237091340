import GoogleMap from "@client.components/GoogleMap";
import React from "react";
import PropTypes from "prop-types";

const Ads = ({ deviceMap }) => {
  const markerPositions = deviceMap
    ? deviceMap.map((device) => ({
        lat: device.latitude,
        lng: device.longitude,
        deviceId: device.lookupId,
      }))
    : [];

  return (
    <GoogleMap
      ads={markerPositions}
      interval={markerPositions}
      width={"100%"}
      height={"380px"}
    />
  );
};

export default Ads;

Ads.propTypes = {
  deviceMap: PropTypes.any,
};
