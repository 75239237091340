import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "@client.components/Button";
import Pages from "@client.enums/pages";
import CampaignList from "../CampaignList";
import { useDispatch, useSelector } from "react-redux";
import { getAdvertiserCampaignsAsync, makeSelectedCampaigns } from "../reducer";
import useStableParams from "@client.hooks/useStableParams";

const CampaignsTab = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedCampaigns = useSelector(makeSelectedCampaigns);

  const { id } = useStableParams();

  useEffect(() => {
    dispatch(getAdvertiserCampaignsAsync(id));
  }, [dispatch, id]);
  

  return (
    <>
      <div className="tab-title">
        <span>{t("pages.Advertiser.CampaignsTab.title")}</span>
        <Button
          icon="plus"
          text={t("components.NavigationBar.createCampaign")}
          onClick={() => {
            navigate(Pages.CreateCampaign);
          }}
        />
      </div>
      <CampaignList
        advertiserId={id}
        loading={selectedCampaigns.loading}
        campaigns={selectedCampaigns.campaigns}
      />
    </>
  );
};

export default CampaignsTab;
