import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { makeProfile } from "@client.pages/Login/reducer";
import moment from "moment";
import config from "@client.config";
import classNames from "classnames";
import CampaignStatuses from "@client.enums/campaignStatuses";
import CampaignActions from "./CampaignActions";
import DraftActions from "./DraftActions";
import MediaFile from "@client.components/MediaFile";
import "./CampaignOverview.scss";

export const CampaignOverview = ({ campaign, advertiserId, isDraft }) => {
  const profile = useSelector(makeProfile);

  const { t } = useTranslation();
  const currentDate = new Date();
  const views = campaign.totalViews || 0;
  const spent = campaign.totalSpent || 0;
  const pricePerView = views === 0 || spent === 0 ? 0 : spent / views;
  const startdate = new Date(campaign.startDate);
  const endDate = new Date(campaign.endDate);
  const isStartdateStarted = startdate > currentDate;
  const isEndDatePast = endDate < currentDate;
  const getStatus = () => {
    if (isDraft) return "Draft";
    if (campaign.status === CampaignStatuses.Paused) return "Paused";
    if (isStartdateStarted) return "Pending";
    if (isEndDatePast) return "Ended";
    return "Live";
  };

  const status = getStatus();

  return (
    <div className="campaign-overview">
      <div className="preview">
        <MediaFile
          src={campaign.file.locationPath}
          isNotFound={!campaign.file || !campaign.file.locationPath}
        />
      </div>
      <div className="status">
        <span className={classNames("badge", status.toLowerCase())}>
          {t(`pages.Campaign.status.${status.toLowerCase()}`)}
        </span>
        <span className="time">
          {isEndDatePast
            ? t("components.CampaignOverview.ended")
            : t("components.CampaignOverview.ending")}

          <span>{moment(campaign.endDate).format(config.dateFormat)}</span>
        </span>
      </div>
      <div className="name">
        {campaign.displayName}
        {isDraft ? (
          <DraftActions campaignId={campaign.lookupId} />
        ) : (
          <CampaignActions
            advertiserId={advertiserId}
            campaignId={campaign.lookupId}
          />
        )}
      </div>
      {!isDraft && (
        <div className="statistic">
          <div className="section-payment">
            <div className="price">{views.toLocaleString()}</div>
            <div className="description">
              {t("components.CampaignOverview.plays")}
            </div>
          </div>
          <div className="section-payment">
            <div className="price">
              {profile.currency} {pricePerView.toLocaleString()}
            </div>
            <div className="description">
              {t("components.CampaignOverview.perPlay")}
            </div>
          </div>
          <div className="section-payment no-border">
            <div className="price">
              {profile.currency} {spent.toLocaleString()}
            </div>
            <div className="description">
              {t("components.CampaignOverview.spent")}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

CampaignOverview.propTypes = {
  isDraft: PropTypes.bool,
  advertiserId: PropTypes.string,
  campaign: PropTypes.shape({
    lookupId: PropTypes.string,
    displayName: PropTypes.string,
    status: PropTypes.number,
    endDate: PropTypes.string,
    startDate: PropTypes.string,
    file: PropTypes.shape({
      locationPath: PropTypes.string,
    }),
    totalViews: PropTypes.number,
    pricePerView: PropTypes.number,
    totalSpent: PropTypes.number,
  }),
};
