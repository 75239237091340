import config from "@client.config";
import BaseService from "@client.services/baseService";

export default class AdvertiserService extends BaseService {
  constructor() {
    super("advertiser", true);

    // TODO remove later on.
    this.advertisers = [
      {
        id: "1",
        firstName: "Mc Donald's",
        lastName: "",
        activeCampaigns: 1,
        adPlays: 10,
        adViews: 20,
        budgetSpent: 300,
      },
      {
        id: "2",
        firstName: "Cafe Cafe",
        lastName: "",
        activeCampaigns: 2,
        adPlays: 33,
        adViews: 64,
        budgetSpent: 153,
      },
      {
        id: "3",
        firstName: "Aroma",
        lastName: "",
        activeCampaigns: 2,
        adPlays: 22,
        adViews: 333,
        budgetSpent: 324,
      },
    ];
  }

  async getOverview() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(
          this.advertisers.reduce(
            (accumulator, currentValue) => {
              accumulator.activeCampaigns += currentValue.activeCampaigns;
              accumulator.adPlays += currentValue.adPlays;
              accumulator.adViews += currentValue.adViews;
              accumulator.budgetSpent += currentValue.budgetSpent;

              return accumulator;
            },
            {
              activeCampaigns: 0,
              adPlays: 0,
              adViews: 0,
              budgetSpent: 0,
            }
          )
        );
      }, 500);
    });
  }

  async getAll() {
    return super.post("", {
      query: `query {
        fetchAccounts {
          results {
            lookupId
            companyName
          }
        }
    }`,
    });
  }

  async create(advertiser) {
    return super.post("", {
      query: `mutation CreateUser($fields: UserInputType!) {
        createUser(fields: $fields) {
          message
        }
      }`,
      variables: {
        fields: advertiser.fields,
      },
    });
  }

  async getById(id) {
    return super.post("", {
      query: `query($lookupId: String) {
        fetchUser (id: $lookupId) {
          fullName
          lastName
          firstName
          lookupId
          roleId
          emailAddress
          phoneNumber
          companyName
          currencyCode
          currencySymbol
          createdAt
          lastLoginTime
        }
      }`,
      variables: {
        lookupId: id,
      },
    });
  }

  async uploadAsset(asset) {
    const formData = new FormData();
    formData.append("file", asset.file);
    formData.append("filename", asset.file.name);
    formData.append("user_lookup_id", asset.userLookupId);

    return super.upload(config.uploadUrl, formData, true);
  }

  async getAllAssets(userId) {
    return super.post("", {
      query: `query($userId: String) {
        fetchUserFiles(id: $userId) {
          lookupId
          locationPath
        }
      }`,
      variables: {
        userId: userId,
      },
    });
  }
}
