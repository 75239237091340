import React from "react";
import moment from "moment";
import config from "@client.config";
import RenderXYZ from "@client.components/RenderXYZ/RenderXYZ";

export const getColumns = (t) => [
  {
    field: "createdAt",
    headerName: t("pages.Device.telemetry.RecievedAt"),
    tooltipValueGetter: (params) =>
      moment(params.data.createdAt).format(config.dateTimeSecondsFormat),
    cellRenderer: (params) =>
      moment(params.data.createdAt).format(config.dateTimeSecondsFormat),
  },
  {
    field: "coresUtilization",
    tooltipField: "coresUtilization",
    headerName: t("pages.Device.telemetry.CoreUtilizationt"),
  },
  {
    field: "memoryUtilization",
    tooltipField: "memoryUtilization",
    headerName: t("pages.Device.telemetry.MemoryUtilization"),
  },
  {
    field: "diskUtilization",
    tooltipField: "diskUtilization",
    headerName: t("pages.Device.telemetry.DiskUtilization"),
  },
  {
    field: "uptime",
    tooltipField: "uptime",
    headerName: t("pages.Device.telemetry.DeviceUptime"),
  },
  {
    field: "luxLight",
    tooltipField: "luxLight",
    headerName: t("pages.Device.telemetry.AvaliableLight"),
  },
  {
    field: "gyroscope",
    headerName: t("pages.Device.telemetry.Gyroscope"),
    tooltipValueGetter: (params) =>
      `X: ${params.data.gyroscopeX || "N/A"} Y: ${
        params.data.gyroscopeY || "N/A"
      } Z: ${params.data.gyroscopeZ || "N/A"}`,
    cellRenderer: (params) => (
      <RenderXYZ
        x={params.data.gyroscopeX}
        y={params.data.gyroscopeY}
        z={params.data.gyroscopeZ}
      />
    ),
  },
  {
    field: "accelerometerX",
    headerName: t("pages.Device.telemetry.Acceleration"),
    tooltipValueGetter: (params) =>
      `X: ${params.data.accelerometerX || "N/A"} Y: ${
        params.data.accelerometerY || "N/A"
      } Z: ${params.data.accelerometerZ || "N/A"}`,
    cellRenderer: (params) => (
      <RenderXYZ
        x={params.data.accelerometerX}
        y={params.data.accelerometerY}
        z={params.data.accelerometerZ}
      />
    ),
  },
];
