import React from "react";
import DataTable from "@client.components/DataTable";
import { getColumns } from "./DemoConst";
const DemoAnalysis = () => {
    const demographicData = [
        { id: 1, age: 25, gender: 'Female', income: 45000, education: "Bachelor's Degree", location: 'New York, NY' },
        { id: 2, age: 34, gender: 'Male', income: 60000, education: "Master's Degree", location: 'Los Angeles, CA' },
        { id: 3, age: 29, gender: 'Female', income: 52000, education: "Associate's Degree", location: 'Chicago, IL' },
        { id: 4, age: 42, gender: 'Male', income: 85000, education: 'PhD', location: 'Houston, TX' },
        { id: 5, age: 37, gender: 'Female', income: 70000, education: "Bachelor's Degree", location: 'Phoenix, AZ' },
        { id: 6, age: 50, gender: 'Male', income: 120000, education: 'Professional Degree', location: 'Philadelphia, PA' },
        { id: 7, age: 31, gender: 'Female', income: 48000, education: "Bachelor's Degree", location: 'San Antonio, TX' },
        { id: 8, age: 28, gender: 'Male', income: 51000, education: 'High School Diploma', location: 'San Diego, CA' },
        { id: 9, age: 45, gender: 'Female', income: 92000, education: "Master's Degree", location: 'Dallas, TX' },
        { id: 10, age: 53, gender: 'Male', income: 115000, education: "Bachelor's Degree", location: 'San Jose, CA' },
      ];
    return ( <div>
        <h4 className="analasysis-perfomance analysis-header">Demographic Analysis</h4>
        <div className="demo-analysis">
        <DataTable
        columns={getColumns()}
        data={demographicData}
        isLoading={false}
        />
        </div>
        </div> );
}
 
export default DemoAnalysis ;