import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "@client.components/DataTable";
import { getColumns } from "./consts";
import Title from "@client.components/Title";
import ServiceProvider from "@client.services/provider";
import { MODAL_EVENT } from "@client.components/Modal";
import ApproveCampaign from "./components/ApproveCampaign";
import "./CampaignApprovals.scss";
import CampaignStatuses from "@client.enums/campaignStatuses";

const ITEMS_PER_PAGE = 15;

const CampaignApprovals = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const offset = (currentPage - 1) * ITEMS_PER_PAGE;

    setIsLoading(true);
    ServiceProvider.Campaign.getAllCampaigns({
      endDate: "",
      startDate: "",
      limit: ITEMS_PER_PAGE,
      offset: offset,
      status: CampaignStatuses.PendingApproval,
    })
      .then((res) => {
        setTotalItems(res.data.fetchCampaigns.count);
        setData(res.data.fetchCampaigns.results);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSelectionChanged = (d) => {
    ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
      show: true,
      className: "approve-campaign-modal",
      title: t("pages.CampaignApprovals.approveCampaign"),
      component: <ApproveCampaign campaign={d.data} />,
    });
  };

  return (
    <div className="campaign-approvals page">
      <Title text="components.NavigationBar.campaignApprovals" />
      <DataTable
        columns={getColumns(t)}
        data={data}
        isLoading={isLoading}
        onSelectionChanged={onSelectionChanged}
        pagination={{
          total: totalItems,
          ITEMS_PER_PAGE: ITEMS_PER_PAGE,
          onChange: setCurrentPage,
        }}
      />
    </div>
  );
};

export default CampaignApprovals;
