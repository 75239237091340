import moment from "moment";
import config from "@client.config";

export const getColumns = (t) => [
  {
    field: "displayName",
    tooltipField: "displayName",
    headerName: t("pages.CampaignApproval.columns.name"),
  },
  {
    field: "account.companyName",
    tooltipField: "account.companyName",
    headerName: t("pages.CampaignApproval.columns.createdBy"),
  },
  {
    field: "createdAt",
    headerName: t("pages.CampaignApproval.columns.createdAt"),
    tooltipValueGetter: (params) =>
      moment(params.data.createdAt).format(config.dateTimeSecondsFormat),
    cellRenderer: (params) =>
      moment(params.data.createdAt).format(config.dateTimeSecondsFormat),
  },
  {
    field: "status",
    tooltipField: "status",
    headerName: t("pages.CampaignApproval.columns.status"),
  },
  {
    field: "notes",
    tooltipField: "notes",
    headerName: t("pages.CampaignApproval.columns.notes"),
  },
];
