import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./SectionDescription.scss";

const SectionDescription = ({ title, description, descriptionDict }) => {
  const { t } = useTranslation();

  return (
    <div className="section-description">
      <div className="title">{t(title)}</div>
      {description && (
        <div
          className="description"
          dangerouslySetInnerHTML={{
            __html: descriptionDict
              ? t(description, descriptionDict)
              : t(description),
          }}
        ></div>
      )}
    </div>
  );
};

SectionDescription.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  descriptionDict: PropTypes.object,
};

export default SectionDescription;
