import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CampaignList from "@client.pages/Advertiser/CampaignList";
import { useDispatch, useSelector } from "react-redux";
import useStableParams from "@client.hooks/useStableParams";
import {
  getAdvertiserDraftsAsync,
  makeSelectedDrafts,
} from "@client.pages/Advertiser/reducer";
import Button from "@client.components/Button";
import Pages from "@client.enums/pages";
import Title from "@client.components/Title";
import { useNavigate } from "react-router-dom";

const DraftCampaignsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedDrafts = useSelector(makeSelectedDrafts);

  const { id } = useStableParams();
  useEffect(() => {
    dispatch(getAdvertiserDraftsAsync(id));
  }, [dispatch, id]);

  return (
    <div className="draft-campaigns page">
      <Title text={t("components.NavigationBar.draftCampaigns")}>
        <Button
          icon="plus"
          text={t("components.NavigationBar.createCampaign")}
          onClick={() => {
            navigate(Pages.CreateCampaign);
          }}
        />
      </Title>
      <CampaignList
        advertiserId={id}
        loading={selectedDrafts.loading}
        campaigns={selectedDrafts.campaigns}
        isDraft={true}
      />
    </div>
  );
};

export default DraftCampaignsPage;
