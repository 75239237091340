import config from "@client.config";
import BaseService from "@client.services/baseService";

export default class GoogleMapService extends BaseService {
  constructor() {
    super("", true);
  }

  async getGeoCode(city) {
    return await super.api(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        city
      )}&key=${config.googleMapsApiKey}`,
      {},
      true
    );
  }
}
