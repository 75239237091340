import BaseModel from "./base";
import { get } from "lodash";

class RegisterUserModel extends BaseModel {
  constructor(user = {}, msg) {
    super(msg);

    this.firstName = get(user, "firstName", "");
    this.lastName = get(user, "lastName", "");
    this.email = get(user, "email", "");
    this.password = get(user, "password", "");
    this.phone = get(user, "phone", "");
    this.customerTypeId = get(user, "customerTypeId", "");
    this.company = get(user, "company", "");
    this.website = get(user, "website", "");

    this.validationMsgs = msg;
    this.validationRules = {
      firstName: {
        presence: { allowEmpty: false, message: msg.firstName },
      },
      lastName: {
        presence: { allowEmpty: false, message: msg.lastName },
      },
      email: {
        presence: { allowEmpty: false, message: msg.email },
        email: { message: msg.invalidEmail },
      },
      phone: {
        presence: { allowEmpty: false, message: msg.phone },
      },
      customerTypeId: {
        presence: { allowEmpty: false, message: msg.customerTypeId },
      },
      password: {
        passwordComplexity: {
          message: msg.password,
        },
      },
    };
  }
}

export default RegisterUserModel;
