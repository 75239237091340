import React from "react";
import PropTypes from "prop-types";
import Title from "@client.components/Title";
import CampaignOverview from "@client.components/CampaignOverview";
import NoDataPlaceholder from "@client.components/NoDataPlaceholder";

const PausedCampaignsOverview = ({ data }) => {
  return (
    <div>
      <Title text="components.NavigationBar.pausedCampaigns" />

      <div className="campaign-list">
        {data && data.length > 0 ? (
          data.map((d, index) => <CampaignOverview key={index} campaign={d} />)
        ) : (
          <NoDataPlaceholder description="No Paused Campaigns" />
        )}
      </div>
    </div>
  );
};
PausedCampaignsOverview.propTypes = {
  data: PropTypes.array.isRequired,
};

export default PausedCampaignsOverview;
