import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "@client.services/provider";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";
import DeviceTypes from "@client.enums/deviceTypes";
import DeviceStatuses from "@client.enums/deviceStatuses";

const DEFAULT_DEVICES = { results: [], loading: false, count: 0 };
const DEFAULT_SELECTED_DEVICE = { device: null, loading: false };
const DEFAULT_APPROVAL_DEVICE = { device: null, loading: false };
const DEFAULT_DEVICE_CAPTURES = { results: [], loading: false };

const initialState = {
  devices: DEFAULT_DEVICES,
  approval: DEFAULT_APPROVAL_DEVICE,
  selected: DEFAULT_SELECTED_DEVICE,
  deviceCaptures: DEFAULT_DEVICE_CAPTURES,
  error: null,
  loading: false,
  defaultMedia: null,
  filter: {
    accountLookupId: "",
    startDate: "",
    endDate: "",
    filterType: DeviceTypes.Unknown,
    isOnline: DeviceStatuses.All,
    limit: 10,
    offset: 0,
  },
};

export const getAllDevicesAsync = createAsyncThunk(
  "devices/getAll",
  async (_query, thunkAPI) => {
    thunkAPI.dispatch(setAllDeviceLoading(true));
    try {
      const state = thunkAPI.getState();
      const filter = makeDevicesFilter(state);
      const response = await ServiceProvider.Device.getAll(filter);

      return response;
    } finally {
      thunkAPI.dispatch(setAllDeviceLoading(false));
    }
  }
);
export const getApprovalsAsync = createAsyncThunk(
  "devices/getApporvals",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setAllApprovalsLoading(true));
    try {
      const response = await ServiceProvider.Device.getApprovals();
      return response;
    } finally {
      thunkAPI.dispatch(setAllApprovalsLoading(false));
    }
  }
);
export const approveDeviceAsync = createAsyncThunk(
  "devices/approveDevice",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(setAllApprovalsLoading(true));
    try {
      const response = await ServiceProvider.Device.approveDevice(id);
      return response;
    } finally {
      thunkAPI.dispatch(setAllApprovalsLoading(false));
    }
  }
);

export const getDeviceByIdAsync = createAsyncThunk(
  "devices/getById",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(setSelectedLoading(true));
    try {
      const response = await ServiceProvider.Device.getById(id);
      return response;
    } finally {
      thunkAPI.dispatch(setSelectedLoading(false));
    }
  }
);

export const fetchDeviceCapturesAsync = createAsyncThunk(
  "devices/fetchCaptures",
  async (filter, thunkAPI) => {
    thunkAPI.dispatch(setSelectedLoading(true));
    try {
      const response = await ServiceProvider.Device.getDeviceCaptures(filter);
      return response;
    } finally {
      thunkAPI.dispatch(setSelectedLoading(false));
    }
  }
);

export const createDeviceAsync = createAsyncThunk(
  "devices/create",
  async (device, thunkAPI) => {
    const resp = await ServiceProvider.Device.create(device);

    if (!resp.errors) {
      thunkAPI.dispatch(getAllDevicesAsync(10, 0));
    }
    return resp;
  }
);

export const updateDeviceAsync = createAsyncThunk(
  "devices/update",
  async (device, thunkAPI) => {
    const resp = await ServiceProvider.Device.update(device);
    if (!resp.errors) {
      thunkAPI.dispatch(getAllDevicesAsync());
    }
    return resp;
  }
);
export const updateDeviceDefaultMediaAsync = createAsyncThunk(
  "devices/updateDefaultMedia",
  async ({ lookupId, defaultMedia }, thunkAPI) => {
    try {
      const response = await ServiceProvider.Device.updateDeviceMedia(
        lookupId,
        defaultMedia
      );
      if (!response.errors) {
        thunkAPI.dispatch(getAllDevicesAsync());
      }
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteDeviceAsync = createAsyncThunk(
  "devices/delete",
  async (deviceId, thunkAPI) => {
    const resp = await ServiceProvider.Device.delete(deviceId);

    if (!resp.errors) {
      thunkAPI.dispatch(getAllDevicesAsync());
    }
    return resp;
  }
);

export const processFlickrSignalAsync = createAsyncThunk(
  "devices/flickrSignal",
  async ({ macAddress, signal }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const response = await ServiceProvider.Device.flickrSignal(
        macAddress,
        signal
      );
      return response;
    } finally {
      thunkAPI.dispatch(setLoading(false));
    }
  }
);

export const devicesSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = get(action, "payload", false);
    },
    setAllDeviceLoading: (state, action) => {
      state.devices.loading = get(action, "payload", false);
    },
    setSelectedLoading: (state, action) => {
      state.selected.loading = get(action, "payload", false);
    },
    setAllApprovalsLoading: (state, action) => {
      state.approval.loading = get(action, "payload", false);
    },
    clearDeviceError: (state) => {
      state.error = null;
    },
    setFilter: (state, action) => {
      state.filter = get(action, "payload", false);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDevicesAsync.fulfilled, (state, action) => {
        const response = get(
          action,
          "payload.data.fetchDevices",
          DEFAULT_DEVICES.results
        );
        state.devices.results = response.results;
        state.devices.count = response.count;
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getAllDevicesAsync.rejected, (state, action) => {
        state.devices = {
          ...DEFAULT_DEVICES,
        };
        state.error = action.payload; // Store the error message directly
      })
      .addCase(getApprovalsAsync.fulfilled, (state, action) => {
        state.approval.device = get(
          action,
          "payload.data.fetchPendingDevices",
          null
        );
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getApprovalsAsync.rejected, (state, action) => {
        state.approval = {
          ...DEFAULT_APPROVAL_DEVICE,
        };
        state.error = getResponseError(action);
      })
      .addCase(approveDeviceAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(approveDeviceAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      })
      .addCase(getDeviceByIdAsync.fulfilled, (state, action) => {
        state.selected.device = get(action, "payload.data.fetchDevice", null);
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getDeviceByIdAsync.rejected, (state, action) => {
        state.selected = {
          ...DEFAULT_SELECTED_DEVICE,
        };
        state.error = getResponseError(action);
      })
      .addCase(fetchDeviceCapturesAsync.pending, (state) => {
        state.deviceCaptures.loading = true;
      })
      .addCase(fetchDeviceCapturesAsync.fulfilled, (state, action) => {
        state.deviceCaptures.loading = false;
        const results = get(
          action,
          "payload.data.fetchDeviceCaptures.results",
          []
        );
        state.deviceCaptures.results = results;
        // Calculate overview data
        const overview = {
          totalImages: results.length,
          statuses: {},
        };
        results.forEach((image) => {
          overview.statuses[image.status] =
            (overview.statuses[image.status] || 0) + 1;
        });

        // Set overview data in state
        state.overview = overview;

        state.error = getGraphqlResponseError(action);
      })
      .addCase(fetchDeviceCapturesAsync.rejected, (state, action) => {
        state.deviceCaptures.loading = false;
        state.deviceCaptures.results = [];
        state.error = getResponseError(action);
      })

      .addCase(createDeviceAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(createDeviceAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      })
      .addCase(updateDeviceAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(updateDeviceAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      })
      .addCase(updateDeviceDefaultMediaAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(updateDeviceDefaultMediaAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      })
      .addCase(deleteDeviceAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(deleteDeviceAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      })
      .addCase(processFlickrSignalAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(processFlickrSignalAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      });
  },
});

export const {
  setAllApprovalsLoading,
  setAllDeviceLoading,
  setSelectedLoading,
  clearDeviceError,
  setLoading,
  setFilter,
} = devicesSlice.actions;

export const makeDevicesFilter = (state) => state.device.filter;
export const makeDevices = (state) => state.device.devices;
export const makeApprovals = (state) => state.device.approval;
export const makeDeviceInfo = (state) => state.device.selected;
export const makeCaptures = (state) => state.device.deviceCaptures;
export const makeDevicesError = (state) => state.device.error;
export const makeDevicesLoading = (state) => state.device.loading;

export default devicesSlice.reducer;
