import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaignLocationsAsync,
  makeLocations,
} from "@client.pages/Campaign/reducer";
import CampaignModel from "@client.models/campaign";
import TagList from "@client.components/TagList";
import SectionDescription from "../SectionDescription";
import GoogleMap, { PlacesAutocomplete } from "@client.components/GoogleMap";
import Button from "@client.components/Button";
import { validateModel } from "@client.utils/form";
import RadioGroup from "@client.components/RadioGroup";
import LocationTypes from "@client.enums/locationTypes";
import Input from "@client.components/Input";
import "./Locations.scss";

const Locations = ({ campaign, edit, onSave, onEdit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const locations = useSelector(makeLocations);
  const [selectedCityLocation, setSelectedCityLocation] = useState(null);
  const [model, setModel] = useState();
  const [isEdit, setIsEdit] = useState(true);
  const [locationInputValue, setLocationInputValue] = useState("");
  const handleLocationChange = (v) => {
    setLocationInputValue(v); // Update the input value state
  };

  useEffect(() => {
    setModel(new CampaignModel(campaign));
  }, [campaign]);

  useEffect(() => {
    setIsEdit(!!edit);
  }, [edit]);

  useEffect(() => {
    dispatch(getCampaignLocationsAsync());
  }, [dispatch]);

  const changeModel = useCallback(
    (v, field) => {
      setModel(
        new CampaignModel({ ...model, [field]: v }, model.validationMsgs)
      );
    },
    [model]
  );

  useEffect(() => {}, [model]);

  const locationTypes = [
    {
      value: LocationTypes.Radius,
      label: t("pages.CreateCampaign.locationType.radius"),
    },
    {
      value: LocationTypes.Locations,
      label: t("pages.CreateCampaign.locationType.locations"),
    },
  ];

  if (!model) {
    return null;
  }

  const title = t("pages.CreateCampaign.section.locations");
  if (!isEdit) {
    const isReadyToEdit = model.isLocationSet() || model.isRadiusSet();

    return (
      <div className="locations minimized">
        <div>
          <div className="step-title">{title}</div>
          {isReadyToEdit && model.locationType === LocationTypes.Locations && (
            <span className="step-description">
              {t("pages.CreateCampaign.minimizedLocationFormat", {
                city: model.locationCity,
                selectedArea: locations
                  .filter((d) => model.locations.includes(d.lookupId))
                  .map(
                    (d, index) =>
                      d.displayName ||
                      `${t("pages.Campaign.locationDefaultName")} ${index + 1}`
                  )
                  .join(","),
              })}
            </span>
          )}
          {isReadyToEdit && model.locationType === LocationTypes.Radius && (
            <span className="step-description">
              {t("pages.CreateCampaign.minimizedLocationWithRadiusFormat", {
                city: model.locationCity,
                radius: model.locationRadius,
              })}
            </span>
          )}
        </div>
        {isReadyToEdit && (
          <Button
            icon="edit"
            text={t("pages.CreateCampaign.edit")}
            className="wizard"
            onClick={onEdit}
          />
        )}
      </div>
    );
  }
  const interval = () => {
    return false;
  };

  const renderLocations = () => {
    if (model.locationType !== LocationTypes.Locations) {
      return null;
    }

    return (
      <>
        <SectionDescription title="pages.CreateCampaign.selectArea" />
        <PlacesAutocomplete
          location={locationInputValue}
          setLocation={setSelectedCityLocation}
          onChange={(v) => {
            handleLocationChange(v); // Call the handler to update the input value state
            changeModel(v, "locationCity"); // Update the model with the input value
          }}
          label={t("pages.CreateCampaign.city")}
        />
        <TagList
          items={locations}
          selected={model.locations}
          multi
          onSelect={(v) => {
            if (model.locations.includes(v)) {
              model.locations = model.locations.filter((d) => d !== v);
            } else {
              model.locations.push(v);
            }

            changeModel(model.locations, "locations");
          }}
          nameKey="displayName"
          nameDefaultValue={t("pages.Campaign.locationDefaultName")}
          valueKey="lookupId"
        />
      </>
    );
  };

  const renderRadius = () => {
    if (model.locationType !== LocationTypes.Radius) {
      return null;
    }
    return (
      <>
        <PlacesAutocomplete
          label={t("pages.CreateCampaign.city")}
          location={model.locationCity}
          setLocation={setSelectedCityLocation}
          onChange={(v) => changeModel(v, "locationCity")}
        />
        <Input
          type="number"
          readOnly={model.locationCity ? false : true}
          label={t("pages.CreateCampaign.locationType.radiusLabel")}
          value={model.locationRadius}
          onChange={(v) => changeModel(parseFloat(v), "locationRadius")}
          optional={{
            min: 1,
            max: 100,
            step: 0.1,
          }}
        />
      </>
    );
  };

  return (
    <div className="locations">
      <div className="step-title">{title}</div>
      <div className="locations-body">
        <div className="locations-inputs">
          <RadioGroup
            groupName="location-type"
            items={locationTypes}
            value={model.locationType}
            onChange={(v) => {
              setModel(
                new CampaignModel(
                  {
                    ...model,
                    locationType: v,
                    locationRadius: null,
                    locations: [],
                  },
                  model.validationMsgs
                )
              );
            }}
          />
          {renderLocations()}
          {renderRadius()}
        </div>
        <GoogleMap
          width={"100%"}
          height={"350px"}
          interval={interval}
          location={selectedCityLocation}
          radiusInKm={model.locationRadius}
          polygons={
            model && model.locations.length > 0
              ? locations
                  .filter(
                    (l) => l.polygon && model.locations.includes(l.lookupId)
                  )
                  .map((l) => JSON.parse(l.polygon))
              : []
          }
          selectedPolygon={
            model && model.locations.length > 0
              ? locations
                  .filter(
                    (l) => l.polygon && model.locations.includes(l.lookupId)
                  )
                  .map((l) => JSON.parse(l.polygon))[0]
              : null
          }
        />
      </div>
      <Button
        text={t("pages.CreateCampaign.next")}
        className="btn-next wizard"
        onClick={() => {
          const validationRules = {
            locationType: {
              presence: {
                allowEmpty: false,
                message: t(
                  "pages.CreateCampaign.location.validation.locationType"
                ),
              },
            },
          };
          if (model.locationType === LocationTypes.Radius) {
            validationRules["locationCity"] = {
              presence: {
                allowEmpty: false,
                message: t("pages.CreateCampaign.location.validation.city"),
              },
            };
            validationRules["locationRadius"] = {
              presence: {
                allowEmpty: false,
                message: t("pages.CreateCampaign.location.validation.radius"),
              },
            };
          }

          if (model.locationType === LocationTypes.Locations) {
            validationRules["locations"] = {
              presence: {
                allowEmpty: false,
                message: t(
                  "pages.CreateCampaign.location.validation.selectedArea"
                ),
              },
            };
          }

          const isValid = validateModel(model, validationRules);

          if (isValid) onSave(model);
        }}
      />
    </div>
  );
};

Locations.propTypes = {
  edit: PropTypes.bool,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  campaign: PropTypes.shape({
    name: PropTypes.string,
    targetId: PropTypes.string,
  }),
};

export default Locations;
