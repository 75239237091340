import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { UploaderPlaceholder } from "@client.components/Uploader";
import UserAssets from "./UserAssets";
import { motion, AnimatePresence } from "framer-motion";
import "./CampaignUploaderPlaceholder.scss";
import { useSelector } from "react-redux";
import { makeProfile } from "@client.pages/Login/reducer";

const CampaignUploaderPlaceholder = ({ model, onSelect }) => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const profile = useSelector(makeProfile);
  return (
    <>
      <UploaderPlaceholder
        url={model.file.locationPath}
        onClick={() => setShowForm(!showForm)}

        // onClick={() => {
        //   if (model.user && model.user.lookupId) {
        //     ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
        //       show: true,
        //       withBodyRef: true,
        //       className: "user-asset-modal",
        //       title: t("pages.CreateCampaign.generalInfo.addAsset"),
        //       component: (
        //         <UserAssets
        //           advertiserId={model.user.lookupId}
        //           selectedFileId={model.fileId}
        //           onSelect={(fileId, fileUrl) => {
        //             onSelect(fileId, fileUrl);

        //             ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
        //               show: false,
        //             });
        //           }}
        //         />
        //       ),
        //     });
        //   } else {
        //     showToastWarning(
        //       "pages.CreateCampaign.generalInfo.validation.userId"
        //     );
        //   }
        // }}
      />
      {showForm && (
        <div className="overlay">
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0, y: -100 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -100 }}
              transition={{ duration: 0.3 }}
              className="container"
            >
              <div className="modal-title top-container">
                <div>{t("pages.CreateCampaign.generalInfo.addAsset")}</div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowForm(!showForm)}
                >
                  X
                </div>
              </div>
              <UserAssets
                userId={profile.lookupId}
                selectedFileId={model.fileId}
                onSelect={(fileId, fileUrl) => {
                  onSelect(fileId, fileUrl);
                  setShowForm(false);
                }}
              />
              {/* <div className="user-assets">
          <Uploader/>{files && files.map((item) => ( <div key={index}></div> ))}
        </div> */}
            </motion.div>
          </AnimatePresence>
        </div>
      )}
    </>
  );
};

CampaignUploaderPlaceholder.propTypes = {
  model: PropTypes.any,
  onSelect: PropTypes.func,
};

export default CampaignUploaderPlaceholder;
