import React, { useEffect, useRef, useState } from "react";
import DataTable from "@client.components/DataTable";
import { getColumns } from "./consts";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  approveDeviceAsync,
  getAllDevicesAsync,
  getApprovalsAsync,
  makeApprovals,
} from "../reducer";
import "./DevicesToApprove.scss";

const DevicesToApprove = () => {
  const [filterdApproval, setFilterdApproval] = useState([]);
  const gridRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const approvalData = useSelector(makeApprovals);

  useEffect(() => {
    dispatch(getApprovalsAsync());
  }, [dispatch]);

  useEffect(() => {
    setFilterdApproval(approvalData.device);
  }, [approvalData]);

  const onApprove = async (lookupId) => {
    try {
      await dispatch(approveDeviceAsync(lookupId));

      const updatedApprovals = filterdApproval.filter(
        (approval) => approval.lookupId !== lookupId
      );
      setFilterdApproval(updatedApprovals);
      // Optionally, you can update other related data as well, like profile data
      dispatch(getAllDevicesAsync());
      // You might also need to update other relevant data based on your application's logic
    } catch (error) {
      console.log("Error approving", error);
    }
  };

  return (
    <div className="devices-to-approve">
      <DataTable
        data={filterdApproval}
        ref={gridRef}
        isLoading={approvalData.loading}
        columns={getColumns(t, onApprove)}
      />
    </div>
  );
};

DevicesToApprove.propTypes = {};

export default DevicesToApprove;
