import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import "./NoCreditCard.scss";
const NoCreditCard = ({ description, onclick, cardType }) => {
  const { t } = useTranslation();
  return (
    <div onClick={onclick} className="no-data-placeholder no-credit-card">
      <div className="icon">
        <span>{cardType}</span>
        <FontAwesomeIcon icon={faCreditCard} />
      </div>

      {description && <div className="description">{t(description)}</div>}
    </div>
  );
};

NoCreditCard.propTypes = {
  description: PropTypes.string,
  cardType: PropTypes.string,
  onclick: PropTypes.func.isRequired,
};

export default NoCreditCard;
