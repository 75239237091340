import React from "react";
import PropTypes from "prop-types";
import Loader from "@client.components/Loader";
import CampaignOverview from "@client.components/CampaignOverview";
import NoDataPlaceholder from "@client.components/NoDataPlaceholder";

const CampaignList = ({ campaigns, loading, advertiserId, isDraft }) => {
  if (loading) {
    return (
      <div className="campaign-list">
        <Loader loading={true} />
      </div>
    );
  }
  return (
    <div className="campaign-list">
      {campaigns && campaigns.length === 0 && (
        <NoDataPlaceholder description={isDraft && "No Draft Campaigns"} />
      )}
      {campaigns &&
        campaigns.map((c) => (
          <CampaignOverview
            campaign={c}
            key={c.lookupId}
            advertiserId={advertiserId}
            isDraft={isDraft}
          />
        ))}
    </div>
  );
};

CampaignList.propTypes = {
  campaigns: PropTypes.arrayOf(
    PropTypes.shape({
      lookupId: PropTypes.string,
      displayName: PropTypes.string,
      status: PropTypes.number,
      endDate: PropTypes.string,
      file: PropTypes.shape({
        locationPath: PropTypes.string,
      }),
      totalViews: PropTypes.number,
      pricePerView: PropTypes.number,
      totalSpent: PropTypes.number,
    })
  ),
  loading: PropTypes.bool,
  isDraft: PropTypes.bool,
  advertiserId: PropTypes.string,
};

export default React.memo(CampaignList);
