import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Input from "@client.components/Input";
import SectionDescription from "../SectionDescription";
import {
  getCampaignTargetAsync,
  makeTargets,
} from "@client.pages/Campaign/reducer";
import CampaignModel from "@client.models/campaign";
import TagList from "@client.components/TagList";
import Button from "@client.components/Button";
import { validateModel } from "@client.utils/form";
import AdvertiserDropdown from "@client.components/AdvertiserDropdown";
import {
  getAllAdvertisersAsync,
  makeAdvertisers,
} from "@client.pages/Advertiser/reducer";
import "./GeneralInformation.scss";
import CampaignUploaderPlaceholder from "../CampaignUploaderPlaceholder";
import { getProfileAsync, makeProfile } from "@client.pages/Login/reducer";

const GeneralInformation = ({ campaign, edit, onSave, onEdit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const targets = useSelector(makeTargets);
  const advertisers = useSelector(makeAdvertisers);
  const profile = useSelector(makeProfile);

  const [model, setModel] = useState(new CampaignModel({}));

  const [isEdit, setIsEdit] = useState(true);

  // Function to handle option selection
  useEffect(() => {
    dispatch(getAllAdvertisersAsync());
    dispatch(getProfileAsync());
  }, [dispatch]);

  useEffect(() => {
    const model = { ...campaign };

    if (advertisers) {
      advertisers.forEach((item) => {
        model.user = {
          lookupId: item.lookupId,
        };
      });
    }
    setModel(new CampaignModel(model));
  }, [campaign, advertisers]);

  useEffect(() => {
    setIsEdit(!!edit);
  }, [edit]);

  useEffect(() => {
    dispatch(getCampaignTargetAsync());
  }, [dispatch]);

  useEffect(() => {
    if (profile && profile.lookupId && advertisers) {
      const defaultAdvertiser = advertisers.find(
        (ad) => ad.lookupId === profile.lookupId
      );
      if (defaultAdvertiser) {
        changeModel(defaultAdvertiser, "user");
      }
    }
  }, [profile, advertisers]);
  const changeModel = useCallback(
    (v, field) => {
      setModel(
        new CampaignModel({ ...model, [field]: v }, model.validationMsgs || {})
      );
    },
    [model]
  );
  if (!model) {
    return null;
  }

  const handleOptionSelect = (option) => {
    changeModel(option, "accountId");
  };
  useEffect(() => {}, [model]);

  const title = t("pages.CreateCampaign.section.generalInformation");

  if (!isEdit) {
    const target = targets.find((t) => t.id === model.targetId);

    return (
      <div className="general-information minimized">
        <div>
          <div className="step-title">{title}</div>
          <span className="step-description">
            {t("pages.CreateCampaign.minimizedInfoFormat", {
              name: model.displayName,
              targetName: target.name,
            })}
          </span>
        </div>
        <Button
          icon="edit"
          text={t("pages.CreateCampaign.edit")}
          className="wizard"
          onClick={onEdit}
        />
      </div>
    );
  }

  return (
    <div className="general-information">
      <div className="step-title">{title}</div>
      <div className="campaign-section">
        <Input
          label={t("pages.CreateCampaign.campaignName")}
          value={model.displayName}
          onChange={(v) => changeModel(v, "displayName")}
        />
        <AdvertiserDropdown label="Advertisers" onSelect={handleOptionSelect} />
      </div>
      <SectionDescription
        title="pages.CreateCampaign.campaignTarget"
        description="pages.CreateCampaign.campaignTarget.description"
      />
      <TagList
        items={targets}
        selected={model.targetId}
        valueKey="id"
        nameKey="name"
        onSelect={(id) => changeModel(id, "targetId")}
      />
      <SectionDescription
        title="pages.CreateCampaign.uploadCampaignImage"
        description="pages.CreateCampaign.uploadCampaignImage.description"
      />
      <CampaignUploaderPlaceholder
        model={model}
        onSelect={(fileId, fileUrl) =>
          setModel(
            new CampaignModel(
              {
                ...model,
                file: {
                  lookupId: fileId,
                  locationPath: fileUrl,
                },
              },
              model.validationMsgs
            )
          )
        }
      />
      <Button
        text={t("pages.CreateCampaign.next")}
        className="btn-next wizard"
        onClick={() => {
          const isValid = validateModel(model, {
            displayName: {
              presence: {
                allowEmpty: false,
                message: t("pages.CreateCampaign.generalInfo.validation.name"),
              },
            },
            accountId: {
              presence: {
                allowEmpty: false,
                message: t(
                  "pages.CreateCampaign.generalInfo.validation.userId"
                ),
              },
            },
            targetId: {
              presence: {
                allowEmpty: false,
                message: t(
                  "pages.CreateCampaign.generalInfo.validation.campaignTarget"
                ),
              },
            },
            fileId: {
              presence: {
                allowEmpty: false,
                message: t("pages.CreateCampaign.generalInfo.validation.file"),
              },
            },
          });

          if (isValid) {
            onSave(model);
          }
        }}
      />
    </div>
  );
};

GeneralInformation.propTypes = {
  edit: PropTypes.bool,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  campaign: PropTypes.shape({
    name: PropTypes.string,
    targetId: PropTypes.string,
  }),
};

export default GeneralInformation;
