export const getColumns = () => [
    {
        field: 'index',
        headerName: '#',
        valueGetter: (params) => params.node.rowIndex + 1, // Adding 1 to start index from 1
        maxWidth: 80
    },
      { field: 'age', headerName: 'Age' },
      { field: 'gender', headerName: 'Gender' },
      { field: 'income', headerName: 'Income' },
      { field: 'education', headerName: 'Education Level' },
      { field: 'location', headerName: 'Location' },
]