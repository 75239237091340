import BaseService from "@client.services/baseService";

export default class CampaignDraftService extends BaseService {
  async save(campaign) {
    return super.post("", {
      query: `mutation CreateDraftCampaign($fields: CampaignInputType!) {
        createDraftCampaign(fields: $fields) {
          message
        }
      }`,
      variables: {
        fields: campaign.fields,
      },
    });
  }

  async getById(lookupId) {
    return super.post("", {
      query: `query($lookupId: String) {
        fetchDraftCampaign(id: $lookupId) {
          budgetAmount
          budgetLimit
          currentBalance
          displayName
          file {
            lookupId
            locationPath
          }
          user {
            lookupId
          }
          lookupId
          selectedDays
          status
          target
        }
      }`,
      variables: {
        lookupId,
      },
    });
  }

  async getAll(userId) {
    return super.post("", {
      query: `query($userId: String) {
        fetchUserDraftCampaigns(id: $userId) {
          budgetAmount
          budgetLimit
          currentBalance
          displayName
          lookupId
          status
          file {
            locationPath
            lookupId
          }
        }
      }`,
      variables: {
        userId,
      },
    });
  }
}
