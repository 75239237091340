import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import CampaignModel from "@client.models/campaign";
import Button from "@client.components/Button";
import TagList from "@client.components/TagList";
import DateRangePicker from "@client.components/DateRangePicker";
import SectionDescription from "../SectionDescription";
import {
  getCampaignDurationTimeSlotsAsync,
  makeTimeSlots,
} from "@client.pages/Campaign/reducer";
import {
  validateModel,
  getDurationDays,
  getDurationDay,
} from "@client.utils/form";
import config from "@client.config";
import CampaignWizardStages from "@client.enums/campaignWizardStages";
import "./Duration.scss";

const Duration = ({ campaign, edit, onSave, onEdit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const timeSlots = useSelector(makeTimeSlots);

  const [model, setModel] = useState();
  const [isEdit, setIsEdit] = useState(true);
  const valueKey = null;
  const durationDayTypes = getDurationDays(t);
  useEffect(() => {}, [model]);
  useEffect(() => {
    setModel(new CampaignModel(campaign));
  }, [campaign]);

  useEffect(() => {
    setIsEdit(!!edit);
  }, [edit]);

  useEffect(() => {
    dispatch(getCampaignDurationTimeSlotsAsync());
  }, [dispatch]);

  const changeModel = useCallback(
    (v, field) => {
      setModel(
        (prevModel) =>
          new CampaignModel(
            {
              ...prevModel,
              [field]: v,
            },
            prevModel.validationMsgs
          )
      );
    },
    [model]
  );

  const getSelectedDaysLabels = () => {
    return model.durationDaysType
      .map((day) => getDurationDay(day, t))
      .join(", ");
  };

  const handleDurationDaySelect = (v) => {
    if (model.durationDaysType.includes(v)) {
      model.durationDaysType = model.durationDaysType.filter((d) => d !== v);
    } else {
      if (v === "0") {
        model.durationDaysType = ["0"]; // If "1" is selected, only "1" remains selected
      } else if (!model.durationDaysType.includes("0")) {
        model.durationDaysType.push(v); // Otherwise, add the selected value
      }
    }
    changeModel(model.durationDaysType, "durationDaysType");
  };
  if (!model) {
    return null;
  }

  const title = t("pages.CreateCampaign.section.duration");
  if (!isEdit) {
    const isReadyToEdit =
      model.isStageFilled(CampaignWizardStages.Budget) &&
      !!model.durationDaysType &&
      model.durationTimeRanges.length > 0;
    return (
      <div className="duration minimized">
        <div>
          <div className="step-title">{title}</div>
          {isReadyToEdit && (
            <span className="step-description">
              {t("pages.CreateCampaign.minimizedDurationFormat", {
                dateFrom: moment(model.startDate).format(config.dateFormat),
                dateTo: moment(model.endDate).format(config.dateFormat),
                durationDaysType: getSelectedDaysLabels(),

                durationTimeRanges: timeSlots
                  .filter((d) => model.durationTimeRanges.indexOf(d.id) > -1)
                  .map((t) => t.name)
                  .join(", "),
              })}
            </span>
          )}
        </div>
        {isReadyToEdit && (
          <Button
            icon="edit"
            text={t("pages.CreateCampaign.edit")}
            className="wizard"
            onClick={onEdit}
          />
        )}
      </div>
    );
  }
  return (
    <div className="duration">
      <div className="step-title">{title}</div>

      <DateRangePicker
        dateFrom={model.startDate}
        dateTo={model.endDate}
        label={t("pages.CreateCampaign.dartion.duration")}
        onChange={(v) => {
          changeModel(v === null ? null : v[0], "startDate");
          changeModel(v === null ? null : v[1], "endDate");
        }}
      />
      <SectionDescription title={"pages.CreateCampaign.dartion.days"} />
      <TagList
        items={durationDayTypes}
        selected={model.durationDaysType}
        onSelect={handleDurationDaySelect}
        // {
        //   console.log(model.durationDaysType)
        // changeModel(v, "durationDaysType")}
        // }
        nameKey="label"
        valueKey="value"
        multi
      />
      <SectionDescription
        title={"pages.CreateCampaign.dartion.time"}
        description={"pages.CreateCampaign.dartion.time.description"}
      />
      <TagList
        items={timeSlots}
        selected={model.durationTimeRanges}
        onSelect={(v) => {
          if (model.durationTimeRanges.includes(v)) {
            model.durationTimeRanges = model.durationTimeRanges.filter(
              (d) => d !== v
            );
          } else {
            if (v === "1") {
              model.durationTimeRanges = ["1"]; // If "1" is selected, only "1" remains selected
            } else if (!model.durationTimeRanges.includes("1")) {
              model.durationTimeRanges.push(v); // Otherwise, add the selected value
            }
          }
          changeModel(model.durationTimeRanges, "durationTimeRanges");
        }}
        nameKey="name"
        valueKey="id"
        multi={valueKey !== 1 || model.durationTimeRanges.length === 0}
      />
      <Button
        text={t("pages.CreateCampaign.next")}
        className="btn-next wizard"
        onClick={() => {
          const isValid = validateModel(model, {
            durationDaysType: {
              presence: {
                allowEmpty: false,
                message: t("pages.CreateCampaign.duation.validation.daysType"),
              },
            },
            durationTimeRanges: {
              presence: {
                allowEmpty: false,
                message: t("pages.CreateCampaign.duation.validation.timeRange"),
              },
            },
          });

          if (isValid) onSave(model);
        }}
      />
    </div>
  );
};

Duration.propTypes = {
  edit: PropTypes.bool,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  campaign: PropTypes.shape({
    name: PropTypes.string,
    targetId: PropTypes.string,
  }),
};

export default Duration;
