import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DataTable from "@client.components/DataTable";
import { getColumns } from "./consts";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaignPerformanceAsync,
  makeSelectedPerformance,
} from "./reducer";

const PerformanceTable = ({ campaignId }) => {
  const { t } = useTranslation();
  const [exportClicked, setExportClicked] = useState(false);
  const performance = useSelector(makeSelectedPerformance);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCampaignPerformanceAsync(campaignId));
  }, [dispatch, campaignId]);
  const handleColumns = () => {
    if (exportClicked) {
      // Execute export functionality here

      setExportClicked(false); // Reset exportClicked state
    }
    return getColumns(t, performance.data);
  };
  return (
    <DataTable
      columns={getColumns(t)}
      data={performance.data}
      isLoading={performance.loading}
      onHeaderExportButtonClick={handleColumns}
    />
  );
};

PerformanceTable.propTypes = {
  campaignId: PropTypes.string,
};

export default PerformanceTable;
