import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MapFilter from "@client.components/MapFilter/MapFilter";

const DeviceMapFilter = ({ onFilterChange }) => {
  const [filter, setFilter] = useState({
    isFollow: false,
    enableLocations: false,
    enableHeatmap: false,
  });

  useEffect(() => {
    onFilterChange(filter);
  }, [filter]);

  const handleFollowClick = () =>
    setFilter({
      ...filter,
      isFollow: !filter.isFollow,
    });

  const handleMapLocationsClick = () =>
    setFilter({
      ...filter,
      enableLocations: !filter.enableLocations,
    });

  const handleHeamapClick = () =>
    setFilter({
      ...filter,
      enableHeatmap: !filter.enableHeatmap,
    });

  const mapFilterItems = [
    {
      icon: filter.isFollow ? "user-minus" : "user-plus",
      title: filter.isFollow
        ? "components.Device.MapFilter.Unfollow"
        : "components.Device.MapFilter.Follow",
      onClick: handleFollowClick,
      enabled: filter.isFollow,
    },
    {
      icon: "pointer",
      title: "components.Device.MapFilter.ShowLocations",
      onClick: handleMapLocationsClick,
      enabled: filter.enableLocations,
    },
    {
      icon: "satellite",
      title: "components.Device.MapFilter.ShowHeatmap",
      onClick: handleHeamapClick,
      enabled: filter.enableHeatmap,
    },
  ];

  return (
    <MapFilter
      items={mapFilterItems}
      onFilterChange={(filter) => console.log(filter)}
    />
  );
};
DeviceMapFilter.propTypes = {
  onRecenterClick: PropTypes.func,
  onlineQty: PropTypes.number,
  offlineQty: PropTypes.number,
  onFilterChange: PropTypes.func,
};
export default DeviceMapFilter;
