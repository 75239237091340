import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDeviceError,
  makeDevicesError,
} from "@client.pages/Devices/reducer";
import ServiceProvider from "@client.services/provider";
import { TOAST_EVENT } from "@client.components/Toast";
import { clearLoginError, makeLoginError } from "@client.pages/Login/reducer";
import {
  clearAdvertiserError,
  makeAdvertiserError,
} from "@client.pages/Advertiser/reducer";
import {
  clearCampaignError,
  makeCampaignError,
} from "@client.pages/Campaign/reducer";
import {
  clearProfileError,
  makeProfileError,
} from "@client.pages/ProfileGeneral/reducer";
import {
  clearLocationsError,
  makeLocationsError,
} from "@client.pages/Locations/reducer";

export const ErrorMessages = () => {
  const loginError = useSelector(makeLoginError);
  const devicesError = useSelector(makeDevicesError);
  const advertiserError = useSelector(makeAdvertiserError);
  const campaignError = useSelector(makeCampaignError);
  const profileError = useSelector(makeProfileError);
  const locationsError = useSelector(makeLocationsError);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      devicesError ||
      loginError ||
      campaignError ||
      advertiserError ||
      profileError ||
      locationsError
    ) {
      const errors = (
        devicesError ||
        loginError ||
        campaignError ||
        advertiserError ||
        profileError ||
        locationsError
      ).map((e) => ({
        type: "error",
        msg: e.message,
      }));
      ServiceProvider.EventEmitter.emit(TOAST_EVENT, errors);
    }
    if (devicesError) dispatch(clearDeviceError());
    if (loginError) dispatch(clearLoginError());
    if (advertiserError) dispatch(clearAdvertiserError());
    if (campaignError) dispatch(clearCampaignError());
    if (profileError) dispatch(clearProfileError());
    if (locationsError) dispatch(clearLocationsError());
  }, [
    dispatch,
    devicesError,
    loginError,
    advertiserError,
    campaignError,
    profileError,
    locationsError,
  ]);

  return null;
};
