import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "./ApproveCampaign.scss";
import Input from "@client.components/Input";
import moment from "moment";
import config from "@client.config";
import MediaFile from "@client.components/MediaFile";
import Button from "@client.components/Button";

const ApproveCampaign = ({ campaign }) => {
  const { t } = useTranslation();

  return (
    <div className="approve-campaign">
      <MediaFile src={campaign.file.locationPath} />
      <div className="campaign-info-block">
        <Input
          readOnly={true}
          label={t("pages.CampaignApproval.columns.name")}
          value={campaign.displayName}
        />
        <div className="info-block-row">
          <Input
            readOnly={true}
            label={t("pages.CampaignApproval.columns.createdBy")}
            value={campaign.account.companyName}
          />
          <Input
            readOnly={true}
            label={t("pages.CampaignApproval.columns.createdAt")}
            value={moment(campaign.createdAt).format(config.dateTimeFormat)}
          />
        </div>
        <Input
          readOnly={true}
          label={t("pages.CampaignApproval.columns.status")}
          value={campaign.status}
        />
        <div className="info-block-notes">
          <div className="notes-label">
            {t("pages.CampaignApproval.columns.notes")}
          </div>
          <div className="notes-value" title={campaign.notes || "N/A"}>
            {campaign.notes || "N/A"}
          </div>
        </div>
        <div className="info-block-row">
          <Button
            text={t("pages.CampaignApproval.deny")}
            className="alternative"
            onClick={() => alert("Deny")}
          />
          <Button
            text={t("pages.CampaignApproval.approve")}
            onClick={() => alert("Approve")}
          />
        </div>
      </div>
    </div>
  );
};

ApproveCampaign.propTypes = {
  campaign: PropTypes.shape({
    file: PropTypes.shape({
      locationPath: PropTypes.string,
    }),
    displayName: PropTypes.string,
    createdAt: PropTypes.string,
    status: PropTypes.number,
    notes: PropTypes.string,
    account: PropTypes.shape({
      companyName: PropTypes.string,
    }),
  }),
};

export default ApproveCampaign;
