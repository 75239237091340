import DataTable from "@client.components/DataTable";
import React from "react";
import { getColumns } from "./TimeConst";
const TimeAnalysis = () => {
    const timeData = [
        { timestamp: '2023-01-01 00:00:00', value: 100 },
        { timestamp: '2023-01-02 00:00:00', value: 150 },
        { timestamp: '2023-01-03 00:00:00', value: 200 },
        { timestamp: '2023-01-04 00:00:00', value: 250 },
        { timestamp: '2023-01-05 00:00:00', value: 300 },
        { timestamp: '2023-01-06 00:00:00', value: 350 },
        { timestamp: '2023-01-07 00:00:00', value: 400 },
        { timestamp: '2023-01-08 00:00:00', value: 450 },
        { timestamp: '2023-01-09 00:00:00', value: 500 },
        { timestamp: '2023-01-10 00:00:00', value: 550 },
      ];
    return ( <div>
        <h4 className="analasysis-perfomance analysis-header">Time Analysis</h4>
        <div className="time analysis">
        <DataTable
        columns={getColumns()}
        data={timeData}
        isLoading={false}
        />
        </div>
        </div> );
}
 
export default TimeAnalysis;