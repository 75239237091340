import React from "react";
import PropTypes from "prop-types";
import Tag from "@client.components/Tag";
import "./TagList.scss";

const TagList = ({
  items,
  selected,
  onSelect,
  nameKey,
  valueKey,
  multi,
  nameDefaultValue,
}) => {
  
  return (
    <div className="tag-list">
      {items.map((t, index) => (
        <Tag
          key={t[valueKey]}
          value={t[valueKey]}
          name={t[nameKey] || `${nameDefaultValue} ${index + 1}`}
          active={
            multi
              ? selected.indexOf(t[valueKey]) > -1
              : t[valueKey] === selected
          }
          onSelect={onSelect}
        />
      ))}
    </div>
  );
};

TagList.defaultProps = {
  multi: false,
};

TagList.propTypes = {
  items: PropTypes.array,
  nameKey: PropTypes.string,
  nameDefaultValue: PropTypes.string,
  valueKey: PropTypes.string,
  selected: PropTypes.any,
  onSelect: PropTypes.func,
  multi: PropTypes.bool,
};

export default TagList;
