import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import HeatMap from "./HeatMap";
import CampaignStatistic from "./CampaignStatistic";
import StatisticChart from "./StatisticChart";
import PerformanceTable from "./PerformanceTable";
import useStableParams from "@client.hooks/useStableParams";
import Ads from "@client.components/Ads";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignMonitoringAsync, makeCampaignMonitor } from "./reducer";
import Loader from "@client.components/Loader";
import Select from "react-select";

const dateFrom = new Date();
const dateTo = new Date();

const Overview = ({ campaign }) => {
  const { t } = useTranslation();
  const { id } = useStableParams();
  const dispatch = useDispatch();
  const statistic = useSelector(makeCampaignMonitor);
  const currentDate = new Date();
  const [endate, setEndate] = useState(false);

  useEffect(() => {
    const endDate = new Date(campaign.endDate);

    const isEndDatePast = endDate < currentDate;
    setEndate(isEndDatePast);
  }, [campaign, currentDate]);
  useEffect(() => {
    dispatch(getCampaignMonitoringAsync(id));
  }, [dispatch, id]);

  if (!statistic || statistic.loading) {
    return (
      <div>
        <Loader loading={true} />
      </div>
    );
  }

  const deviceMap = [
    {
      lookupId: "fake_lookup_id_19696",
      deviceName: "Device 45432",
      latitude: 55.6761, // Copenhagen, Denmark
      longitude: 12.5683,
    },
    {
      lookupId: "fake_lookup_id_83822",
      deviceName: "Device 88776",
      latitude: 22.3193, // Hong Kong
      longitude: 114.1694,
    },
    {
      lookupId: "fake_lookup_id_13084",
      deviceName: "Device 67812",
      latitude: 40.7128, // New York, USA
      longitude: -74.006,
    },
  ];

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "200px", // Adjust this value as needed
    }),
  };

  const roleOptions = deviceMap.map((item) => ({
    value: item.lookupId,
    label: item.deviceName,
  }));

  return (
    <div className="campaign-statistic">
      <CampaignStatistic
        campaign={statistic}
        dateFrom={dateFrom}
        dateTo={dateTo}
        campaignId={id}
      />
      <StatisticChart
        statistic={statistic}
        campaignId={id}
        dateFrom={dateFrom}
        dateTo={dateTo}
      />
      <PerformanceTable campaignId={id} />

      {!endate && (
        <>
          <div className="campaign-header d-flex justify-content-between ">
            <div> {t("pages.campaign.ads")} </div>
            <Select
              styles={customStyles}
              options={roleOptions}
              placeholder="Select a device"
            />
          </div>
          <Ads campaignId={id} deviceMap={deviceMap} />
        </>
      )}
      {endate && (
        <>
          <div className="campaign-header d-flex justify-content-between ">
            <div> {t("pages.Campaign.heatmap")} </div>{" "}
            <Select
              options={roleOptions}
              styles={customStyles}
              placeholder="Select a city"
            />{" "}
          </div>
          <HeatMap campaignId={id} />
          {/* <Ads campaignId={id} deviceMap={deviceMap}/> */}
        </>
      )}

      {/* <div className="campaign-header">{t("pages.Campaign.heatmap")}</div>
      <HeatMap campaignId={id} dateFrom={dateFrom} dateTo={dateTo} /> */}
    </div>
  );
};

Overview.propTypes = {
  campaignId: PropTypes.string,
  campaign: PropTypes.any,
};

export default Overview;
