const DurationDayTypes = Object.freeze({
  AllWeek: "0",
  Monday: "1",
  Tuesday: "2",
  Wednesday: "3",
  Thursday: "4",
  Friday: "5",
  Saturday: "6",
  Sunday: "7",
});

export default DurationDayTypes;
