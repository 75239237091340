export const getColumns = (t) => [
  {
    field: "serverName",
    tooltipField: "serverName",
    headerName: t("pages.VpnManagement.columns.name"),
  },
  {
    field: "ipAddress",
    tooltipField: "ipAddress",
    headerName: t("pages.VpnManagement.columns.ipAddress"),
  },
  {
    field: "location",
    tooltipField: "location",
    headerName: t("pages.VpnManagement.columns.location"),
  },
  {
    field: "availableProfiles",
    headerName: t("pages.VpnManagement.columns.availableProfiles"),
  },
  {
    field: "status",
    tooltipField: "status",
    headerName: t("pages.VpnManagement.columns.status"),
  },
];
