import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Routes, Route } from "react-router-dom";
import LoginPage from "@client.pages/Login";
import ForgotPasswordPage from "@client.pages/ForgotPassword";
import ProfileGeneralPage from "@client.pages/ProfileGeneral";
import ProfileBillingPage from "@client.pages/ProfileBilling";
import ProfileNotificationsPage from "@client.pages/ProfileNotifications";
import ProfileChangePasswordPage from "@client.pages/ProfileChangePassword";
import LogoutPage from "@client.pages/Logout";
import DevicesPage from "@client.pages/Devices";
import ActiveCampaignsPage from "@client.pages/Campaigns";
import PausedCampaignsPage from "@client.pages/PausedCampaigns";
import ArchivedCampaignsPage from "@client.pages/ArchivedCampaigns";
import MediaLibraryPage from "@client.pages/MediaLibrary/MediaLibrary";
import LocationsPage from "@client.pages/Locations";
import CreateCampaignPage from "@client.pages/CreateCampaign";
import DashboardPage from "@client.pages/Dashboard";
import AdvertiserPage from "@client.pages/Advertiser";
import AuthorizedLayout from "@client.components/AuthorizedLayout";
import UnauthorizedLayout from "@client.components/UnauthorizedLayout";
import RequireAuth from "@client.components/RequireAuth";
import Pages from "@client.enums/pages";
import CampaignPage from "@client.pages/Campaign/Campaign";
import FlickrDemoPage from "@client.pages/FlickrDemo";
import Loader from "@client.components/Loader";
import DevicePage from "@client.pages/Devices/Device";
import UsersPage from "@client.pages/Users";
import ProfilePaymentPage from "@client.pages/ProfilePayment/ProfilePayment";
import VpnServerPage from "@client.pages/VpnServer";
import DraftCampaignsPage from "@client.pages/DraftCampaigns/DraftCampaigns";
import ReportsPage from "@client.pages/ReportsPage";
import InviteUser from "@client.pages/InviteUser";
import "./i18n";
import RegisterUser from "@client.pages/RegisterUser";
import CampaignApprovals from "@client.pages/CampaignApprovals/CampaignApprovals";
import VpnManagement from "@client.pages/VpnManagement/VpnManagement";

const AppRoutes = () => {
  const { i18n } = useTranslation();
  const [translationsLoaded, setTranslationsLoaded] = useState(false);

  useEffect(() => {
    const defaultLanguge = "en";
    const existingLanguges = [defaultLanguge];
    let languageToUse = existingLanguges.find((l) => l === i18n.language);
    if (!languageToUse) {
      languageToUse = existingLanguges.find((l) => i18n.language.startsWith(l));
    }

    const language = languageToUse || defaultLanguge;
    import(`@client.i18n/${language}.json`)
      .then((translations) => {
        i18n.changeLanguage(language);
        i18n.addResourceBundle(
          language,
          "translation",
          translations.default,
          true,
          true
        );
        setTranslationsLoaded(true);
      })
      .catch((error) => {
        console.error("Error loading translations:", error);
      });
  }, [i18n]);
  // if this is needed /:advertiserId

  if (!translationsLoaded) {
    return (
      <div className="app page-loading">
        <Loader loading={true} />
      </div>
    );
  }
  return (
    <div className="app">
      <Routes>
        <Route element={<UnauthorizedLayout />}>
          <Route path={Pages.Login} element={<LoginPage />} />
          <Route path={Pages.InviteUser} element={<InviteUser />} />
          <Route path={Pages.RegisterUser} element={<RegisterUser />} />
          <Route path={Pages.ForgotPassword} element={<ForgotPasswordPage />} />
          <Route path={Pages.FlickrDemo} element={<FlickrDemoPage />} />
          <Route path="*" element={<LoginPage />} />
        </Route>
        <Route element={<RequireAuth />}>
          <Route element={<AuthorizedLayout withNavBar={true} />}>
            <Route path={Pages.Dashboard} element={<DashboardPage />} />
            <Route path={Pages.Devices} element={<DevicesPage />} />
            <Route path={`${Pages.Devices}/:id`} element={<DevicePage />} />
            <Route path={Pages.Users} element={<UsersPage />} />
            <Route path={Pages.MediaLibrary} element={<MediaLibraryPage />} />
            <Route
              path={`${Pages.CreateCampaign}/:draftId?`}
              element={<CreateCampaignPage />}
            />
            <Route path={Pages.Campaigns} element={<ActiveCampaignsPage />} />
            <Route
              path={Pages.ActiveCampaigns}
              element={<ActiveCampaignsPage />}
            />
            <Route
              path={Pages.PausedCampaigns}
              element={<PausedCampaignsPage />}
            />
            <Route
              path={Pages.ArchivedCampaigns}
              element={<ArchivedCampaignsPage />}
            />
            <Route
              path={Pages.DraftCampaigns}
              element={<DraftCampaignsPage />}
            />
            <Route path={Pages.Reports} element={<ReportsPage />} />
            <Route path={Pages.VpnServer} element={<VpnServerPage />} />
            <Route
              path={`${Pages.ProfileGeneral}/:profileId`}
              element={<ProfileGeneralPage />}
            />
            <Route
              path={Pages.ProfileGeneral}
              element={<ProfileGeneralPage />}
            />
            <Route
              path={Pages.ProfileChangePassword}
              element={<ProfileChangePasswordPage />}
            />
            <Route
              path={`${Pages.ProfileChangePassword}/:profileId`}
              element={<ProfileChangePasswordPage />}
            />

            <Route
              path={`${Pages.ProfileNotifications}/:profileId`}
              element={<ProfileNotificationsPage />}
            />
            <Route
              path={Pages.ProfileNotifications}
              element={<ProfileNotificationsPage />}
            />
            <Route
              path={`${Pages.ProfileBilling}/:profileId`}
              element={<ProfileBillingPage />}
            />
            <Route
              path={Pages.ProfileBilling}
              element={<ProfileBillingPage />}
            />

            <Route
              path={Pages.ProfilePayment}
              element={<ProfilePaymentPage />}
            />
            <Route
              path={`${Pages.ProfilePayment}/:profileId`}
              element={<ProfilePaymentPage />}
            />

            <Route path={Pages.Locations} element={<LocationsPage />} />
            <Route
              path={`${Pages.Advertiser}/:id`}
              element={<AdvertiserPage />}
            />
            <Route path={`${Pages.Campaign}/:id`} element={<CampaignPage />} />
            <Route path={Pages.Logout} element={<LogoutPage />} />
            <Route
              path={Pages.CampaignApprovals}
              element={<CampaignApprovals />}
            />
            <Route path={Pages.VpnManagement} element={<VpnManagement />} />
            <Route path={"*"} element={<DashboardPage />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
};

export default AppRoutes;
