import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Tabs from "@client.components/Tabs";

const AdvertiserTabsEnum = Object.freeze({
  Campaigns: 0,
  Drafts: 1,
});

const AdvertiserTabs = ({ value, onSelect }) => {
  const { t } = useTranslation();

  const tabs = [
    {
      id: AdvertiserTabsEnum.Campaigns,
      name: t("pages.Advertiser.tabs.campaigns"),
      icon: "speaker",
    },
    {
      id: AdvertiserTabsEnum.Drafts,
      name: t("pages.Advertiser.tabs.drafts"),
      icon: "draft",
    },
  ];

  return <Tabs tabs={tabs} value={value} onSelect={onSelect} />;
};

AdvertiserTabs.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSelect: PropTypes.func,
};

export { AdvertiserTabsEnum };
export default AdvertiserTabs;
