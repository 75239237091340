import React from "react";
import { useNavigate } from "react-router-dom";
import Pages from "@client.enums/pages";
import SvgIcon from "@client.components/SvgIcon";
import { useTranslation } from "react-i18next";
import Button from "@client.components/Button";
import { NavigationSection } from "./NavigationSection";
import { useSelector } from "react-redux";
import { makeProfile } from "@client.pages/Login/reducer";
import "./NavigationBar.scss";
import CustomerType from "@client.enums/customerType";

export const NavigationBar = () => {
  const profile = useSelector(makeProfile);

  const generalItems = [
    {
      icon: "dashboard",
      url: Pages.Dashboard,
      name: "components.NavigationBar.dashboard",
    },
  ];
  if (profile && CustomerType.Roadwarez === profile.selectedAccount.entityId) {
    generalItems.push({
      icon: "device",
      url: Pages.Devices,
      name: "components.NavigationBar.devices",
    });
    generalItems.push({
      icon: "locations",
      url: Pages.Locations,
      name: "components.NavigationBar.locations",
    });
  }
  const campaignItems = [
    {
      icon: "media",
      url: "MediaLibrary",
      name: "components.NavigationBar.mediaLibrary",
    },
    {
      icon: "active-campaigns",
      url: Pages.ActiveCampaigns,
      name: "components.NavigationBar.activeCampaigns",
    },
    {
      icon: "paused-campaigns",
      url: Pages.PausedCampaigns,
      name: "components.NavigationBar.pausedCampaigns",
    },
    {
      icon: "paused-campaigns",
      url: Pages.DraftCampaigns,
      name: "components.NavigationBar.draftCampaigns",
    },
    {
      icon: "archived-campaigns",
      url: Pages.ArchivedCampaigns,
      name: "components.NavigationBar.archivedCampaigns",
    },
  ];

  if (profile && CustomerType.Roadwarez === profile.selectedAccount.entityId) {
    campaignItems.splice(1, 0, {
      icon: "circle-check",
      url: Pages.CampaignApprovals,
      name: "components.NavigationBar.campaignApprovals",
    });
  }

  const billingItems = [];

  if (
    profile &&
    [CustomerType.Roadwarez, CustomerType.Reseller].includes(
      profile.selectedAccount.entityId
    )
  ) {
    billingItems.push({
      icon: "sitemap",
      url: Pages.Accounts,
      name: "components.NavigationBar.accounts",
    });
  }

  if (
    profile &&
    [
      CustomerType.Roadwarez,
      CustomerType.Reseller,
      CustomerType.Advertiser,
    ].includes(profile.selectedAccount.entityId)
  ) {
    billingItems.push({
      icon: "profile",
      url: Pages.Users,
      name: "components.NavigationBar.Users",
    });
  }

  billingItems.push({
    icon: "report",
    url: Pages.Reports,
    name: "components.NavigationBar.reports",
  });

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="navigation-bar" data-testid="NavigationBar">
      <div className="top">
        <div className="logo-container">
          <SvgIcon
            name="logo"
            className="logo"
            onClick={() => {
              navigate(Pages.Dashboard);
            }}
          />
        </div>
        <Button
          icon="plus"
          text={t("components.NavigationBar.createCampaign")}
          onClick={() => {
            navigate(Pages.CreateCampaign);
          }}
        />
        <NavigationSection
          title="components.NavigationBar.general"
          items={generalItems}
          // dropdownItems={campaignItems}
          // dropdownTitle="Campaigns"
        />
        <NavigationSection
          title="components.NavigationBar.campaigns"
          items={campaignItems}
        />
        <NavigationSection
          title="components.NavigationBar.billing"
          items={billingItems}
        />
      </div>
    </div>
  );
};
