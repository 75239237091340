const formatNumber = (num, fractionalPart = 2) => {
  const options = {
    minimumFractionDigits: fractionalPart,
    maximumFractionDigits: fractionalPart,
  };
  const formattedNumber = new Intl.NumberFormat("en-US", options).format(num);

  return formattedNumber;
};

export { formatNumber };
