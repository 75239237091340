import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";
import ServiceProvider from "@client.services/provider";
import { get } from "lodash";

// Async action creator
export const getAdvertiserCampaignsAsync = createAsyncThunk(
  "advertiser/getCampaigns",
  async (advertiserId, thunkAPI) => {
    thunkAPI.dispatch(setLoadingSelectedCampaigns(true));
    try {
      return await ServiceProvider.Campaign.getAll(advertiserId);
    } finally {
      thunkAPI.dispatch(setLoadingSelectedCampaigns(false));
    }
  }
);

// Initial state
const DEFAULT_SELECTED_CAMPAIGNS = { campaigns: [], loading: false };
const initialState = {
  error: null,
  loading: false,
  selectedCampaigns: DEFAULT_SELECTED_CAMPAIGNS,
};

// Reducer
const reducer = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    setLoadingSelectedCampaigns: (state, action) => {
      state.selectedCampaigns.loading = action.payload;
    },
    toggleIsActive(state) {
      state.isActive = !state.isActive;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdvertiserCampaignsAsync.fulfilled, (state, action) => {
        state.selectedCampaigns.campaigns = get(
          action,
          "payload.data.fetchUserCampaigns",
          []
        );
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getAdvertiserCampaignsAsync.rejected, (state, action) => {
        state.selectedCampaigns = {
          ...DEFAULT_SELECTED_CAMPAIGNS,
        };
        state.error = getResponseError(action);
      });
  },
});

// Export actions, selectors, and reducer
export const { setLoadingSelectedCampaigns } = reducer.actions;
export const { toggleIsActive } = reducer.actions;
export const makeSelectedCampaigns = (state) =>
  state.advertiser.selectedCampaigns;

export default reducer;

// import ServiceProvider from "@client.services/provider";
// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { get } from "lodash";
// import { getGraphqlResponseError, getResponseError } from "@client.utils/error";

// const DEFAULT_SELECTED_CAMPAIGNS = { campaigns: [], loading: false  }
// const TOGGLE_IS_ACTIVE = 'TOGGLE_IS_ACTIVE'

// export const toggleIsActive = () => ({
// type: TOGGLE_IS_ACTIVE
// })

// const initialState = {
// error: null,
// loading: false,
// selectedCampaigns: DEFAULT_SELECTED_CAMPAIGNS,
// isActive: true
// }

// export const getAdvertiserCampaignsAsync = createAsyncThunk(
//     "advertiser/getCampaigns",
//     async (advertiserId, thunkAPI) => {
//       thunkAPI.dispatch(setLoadingSelectedCampaigns(true));
//       try {
//         return await ServiceProvider.Campaign.getAll(advertiserId);
//       } finally {
//         thunkAPI.dispatch(setLoadingSelectedCampaigns(false));
//       }
//     }
//   );
// const reducer = (state = initialState, action) => {
//   switch (action.type){
//   case TOGGLE_IS_ACTIVE:
//     return {
//     ...state,
//     isActive: !state.isActive
//     };
//     default:
//       return state;
//   }
// };
// export const campaignsSlice = createSlice({
// name: "Campaigns",
// initialState,
// reducers: {
//     setLoadingSelectedCampaigns: (state, action) => {
//         state.selectedCampaigns.loading = get(action, "payload", false);
//       },
// },
// extraReducers: (builder) => {
//     builder
//       .addCase(getAdvertiserCampaignsAsync.fulfilled, (state, action) => {
//         state.selectedCampaigns.campaigns = get(
//           action,
//           "payload.data.fetchUserCampaigns",
//           []
//         );
//         state.error = getGraphqlResponseError(action);
//       })
//       .addCase(getAdvertiserCampaignsAsync.rejected, (state, action) => {
//         state.selectedCampaigns = {
//           ...DEFAULT_SELECTED_CAMPAIGNS,
//         };
//         state.error = getResponseError(action);
//       });
//   },
// })

// export const {
//     setLoadingSelectedCampaigns,
// } = campaignsSlice.actions;

//   export const makeSelectedCampaigns = (state) =>
//   state.advertiser.selectedCampaigns;
//   export default reducer
