import React from "react";
import PropTypes from "prop-types";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import DateTimePicker from "react-datetime-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import Icon from "@client.components/Icon";
import "./DateRangePicker.scss";

const DateRangeComponent = ({ time, dateFrom, dateTo, onChange, label }) => {
  return (
    <div className="date-range-picker">
      {label && <span className="label">{label}</span>}
      {time ? (
        <DateTimePicker
          calendarIcon={<Icon name="calendar" />}
          clearIcon={null}
          dayAriaLabel="Day"
          monthAriaLabel="Month"
          nativeInputAriaLabel="Date"
          minuteAriaLabel="Minute"
          hourAriaLabel="Hour"
          secondAriaLabel="Second"
          format="y/MM/dd h:mm:ss a"
          onChange={onChange}
          value={[dateFrom, dateTo]}
          yearAriaLabel="Year"
        />
      ) : (
        <DateRangePicker
          calendarIcon={<Icon name="calendar" />}
          clearIcon={null}
          dayAriaLabel="Day"
          monthAriaLabel="Month"
          nativeInputAriaLabel="Date"
          onChange={onChange}
          value={[dateFrom, dateTo]}
          yearAriaLabel="Year"
        />
      )}
    </div>
  );
};

DateRangeComponent.defaultProps = {
  label: "",
  time: false,
};

DateRangeComponent.propTypes = {
  time: PropTypes.bool,
  label: PropTypes.string,
  dateFrom: PropTypes.any,
  dateTo: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

export default React.memo(DateRangeComponent);
