import React from "react";
import PropTypes from "prop-types";
import "./Checkbox.scss";

export const Checkbox = ({ value, onChange, label }) => {
  return (
    <div className="checkbox-container">
      <label className="container">
        {label}
        <input
          type="checkbox"
          checked={value}
          onChange={(v) => onChange(v.target.checked)}
        />
        <span className="checkmark"></span>
      </label>
    </div>
  );
};

Checkbox.defaultProps = {
  value: false,
  label: "",
};

Checkbox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};
