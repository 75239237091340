const LS_PREFIX = "adport";

class LocalStorageService {
  setItem = (key, value) => {
    const fullKey = this.getKey(key);
    window.localStorage.setItem(fullKey, JSON.stringify(value));
  };

  getItem = (key, defaultValue = null) => {
    const fullKey = this.getKey(key);
    const item = window.localStorage.getItem(fullKey);
    
    return item ? JSON.parse(item) : defaultValue;
  };

  removeItem = (key) => {
    const fullKey = this.getKey(key);
    window.localStorage.removeItem(fullKey);
  };

  getKey = (key) => {
    return `${LS_PREFIX}:${key}`;
  };
}

export default LocalStorageService;
