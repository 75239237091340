import CampaignWizardStages from "@client.enums/campaignWizardStages";
import BaseModel from "./base";
import { get } from "lodash";
import BudgetLimit from "@client.enums/budgetLimit";
import CampaignStatuses from "@client.enums/campaignStatuses";
import LocationTypes from "@client.enums/locationTypes";

class CampaignModel extends BaseModel {
  constructor(model = {}, msg) {
    super(msg);

    this.displayName = get(model, "displayName", "");
    this.targetId = get(model, "targetId", null);
    this.accountId = get(model, "accountId", "");
    this.budgetLimit = get(model, "budgetLimit", BudgetLimit.Total);
    this.budgetAmount = get(model, "budgetAmount", 300);
    this.startDate = get(model, "startDate", new Date());
    this.endDate = get(model, "endDate", new Date());
    this.durationDaysType = get(model, "durationDaysType", []);
    this.durationTimeRanges = get(model, "durationTimeRanges", []);
    this.locationType = get(model, "locationType", LocationTypes.Locations);
    this.locationRadius = get(model, "locationRadius", null);
    this.locationCity = get(model, "locationCity", "");
    this.locations = get(model, "locations", []);
    this.lookupId = get(model, "lookupId", null);
    this.file = get(model, "file", { lookupId: null, locationPath: "" });

    this.validationMsgs = msg;
    this.validationRules = {};
  }

  isStageFilled = (stage) => {
    switch (stage) {
      case CampaignWizardStages.Info:
        return !!this.displayName && !!this.targetId;
      case CampaignWizardStages.Budget:
        return this.isStageFilled(CampaignWizardStages.Info);
      case CampaignWizardStages.Duration:
        return (
          this.isStageFilled(CampaignWizardStages.Budget) &&
          !!this.durationDaysType &&
          this.durationTimeRanges.length > 0
        );
      case CampaignWizardStages.Location: {
        return (
          this.isStageFilled(CampaignWizardStages.Duration) &&
          (this.isLocationSet() || this.isRadiusSet())
        );
      }
    }
  };

  isLocationSet = () => {
    return (
      this.locationType === LocationTypes.Locations && this.locations.length > 0
    );
  };

  isRadiusSet = () => {
    return (
      this.locationType === LocationTypes.Radius &&
      this.locationCity &&
      this.locationRadius > 0
    );
  };

  isValid = () => {
    return [
      CampaignWizardStages.Info,
      CampaignWizardStages.Budget,
      CampaignWizardStages.Duration,
      CampaignWizardStages.Location,
    ].every((s) => this.isStageFilled(s));
  };

  get fileId() {
    return this.file.lookupId;
  }

  get fields() {
    return {
      displayName: this.displayName,
      startDate: this.startDate,
      endDate: this.endDate,
      fileId: this.file.lookupId,
      currentBalance: 1500,
      oud: "xxxxxx",
      status: CampaignStatuses.Active,
      locations: this.locations,
      budgetAmount: this.budgetAmount,
      budgetLimit: this.budgetLimit,
      accountId: this.accountId,
    };
  }
}

export default CampaignModel;
