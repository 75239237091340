import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CampaignModel from "@client.models/campaign";
import Slider from "@client.components/Slider/Slider";
import Button from "@client.components/Button";
import RadioGroup from "@client.components/RadioGroup";
import TagList from "@client.components/TagList";
import BudgetLimit from "@client.enums/budgetLimit";
import { makeProfile } from "@client.pages/Login/reducer";
import { validateModel } from "@client.utils/form";
import CampaignWizardStages from "@client.enums/campaignWizardStages";
import "./Budget.scss";

const MIN_AMOUNT = 10;

const Budget = ({ campaign, edit, onSave, onEdit }) => {
  const { t } = useTranslation();
  const profile = useSelector(makeProfile);

  const [model, setModel] = useState();
  const [isEdit, setIsEdit] = useState(true);

  const budgetLimits = [
    { value: BudgetLimit.Total, label: t("pages.CreateCampaign.budget.total") },
    { value: BudgetLimit.Daily, label: t("pages.CreateCampaign.budget.daily") },
  ];

  const amountTags = [
    { value: 50, label: `${profile.currency}50` },
    { value: 200, label: `${profile.currency}200` },
    { value: 300, label: `${profile.currency}300` },
    { value: 450, label: `${profile.currency}450` },
  ];

  useEffect(() => {
    setModel(new CampaignModel(campaign));
  }, [campaign]);

  useEffect(() => {
    setIsEdit(!!edit);
  }, [edit]);

  const changeModel = useCallback(
    (v, field) => {
      setModel(
        new CampaignModel({ ...model, [field]: v }, model.validationMsgs)
      );
    },
    [model]
  );

  if (!model) {
    return null;
  }

  const title = t("pages.CreateCampaign.section.budget");
  if (!isEdit) {
    const isReadyToEdit = model.isStageFilled(CampaignWizardStages.Info);
    return (
      <div className="budget minimized">
        <div>
          <div className="step-title">{title}</div>
          {isReadyToEdit && (
            <span className="step-description">
              {t("pages.CreateCampaign.minimizedBudgetFormat", {
                type: budgetLimits.find((t) => t.value === model.budgetLimit)
                  .label,
                value: model.budgetAmount,
                currency: profile.currency,
              })}
            </span>
          )}
        </div>
        {isReadyToEdit && (
          <Button
            icon="edit"
            text={t("pages.CreateCampaign.edit")}
            className="wizard"
            onClick={onEdit}
          />
        )}
      </div>
    );
  }

  return (
    <div className="budget">
      <div className="step-title">{title}</div>
      <RadioGroup
        groupName="budget-type"
        items={budgetLimits}
        value={model.budgetLimit}
        onChange={(v) => changeModel(v, "budgetLimit")}
      />
      <div className="daily-amount-title">
        {t("pages.CreateCampaign.budget.insertDailyAmount")}
      </div>
      <Slider
        min={MIN_AMOUNT}
        max={5000}
        currency={profile.currency}
        value={model.budgetAmount}
        onChange={(v) => changeModel(parseInt(v), "budgetAmount")}
      />
      <TagList
        items={amountTags}
        selected={model.budgetAmount}
        onSelect={(v) => changeModel(v, "budgetAmount")}
        nameKey="label"
        valueKey="value"
      />
      <div className="minimum-amount-title">
        {t("pages.CreateCampaign.budget.minAmountDescription", {
          currency: profile.currency,
          value: MIN_AMOUNT,
        })}
      </div>
      <Button
        text={t("pages.CreateCampaign.next")}
        className="btn-next wizard"
        onClick={() => {
          const isValid = validateModel(model, {
            budgetLimit: {
              presence: {
                allowEmpty: false,
                message: t("pages.CreateCampaign.budget.validation.type"),
              },
            },
          });

          if (isValid) onSave(model);
        }}
      />
    </div>
  );
};

Budget.propTypes = {
  edit: PropTypes.bool,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  campaign: PropTypes.shape({
    name: PropTypes.string,
    targetId: PropTypes.string,
  }),
};

export default Budget;
