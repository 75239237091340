import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import StatisticView from "@client.components/StatisticView/StatisticView";

// eslint-disable-next-line no-unused-vars
const CampaignStatistic = ({ campaign, campaignId, dateFrom, dateTo }) => {
  function formatDate(date) {
    const options = {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  }

  // Function to get the start and end of a week for a given date
  function getWeekRange(date) {
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - date.getDay() + 1); // Monday
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // Sunday
    return [startOfWeek, endOfWeek];
  }

  // State to store weeks
  const [weeks, setWeeks] = useState([]);

  // Populate the select box with the last 12 weeks
  useEffect(() => {
    const today = new Date();
    const weeksData = [];

    for (let i = 0; i < 12; i++) {
      const date = new Date();
      date.setDate(today.getDate() - i * 7);
      const [startOfWeek, endOfWeek] = getWeekRange(date);
      weeksData.push({
        start: startOfWeek,
        end: endOfWeek,
        label: `${formatDate(startOfWeek)} - ${formatDate(endOfWeek)}`,
        value: `${startOfWeek.toISOString()} - ${endOfWeek.toISOString()}`,
      });
    }

    setWeeks(weeksData);
  }, []);
  const weekMap = weeks.map((week) => ({
    value: week.value,
    label: week.label,
  }));
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "500px", // Adjust this value as needed
    }),
  };

  const latestWeek = weeks[0]?.label;
  // if (selectedCampaignStatistic.loading) {
  //   return <Loader loading={true} />;
  // }

  return (
    campaign.data.fetchCampaignMonitoring && (
      <StatisticView
        title="pages.Campaign.campaignMonitoring"
        data={{
          green: {
            value: campaign.data.fetchCampaignMonitoring.totalPlays,
            title: "pages.Campaign.Overview.plays",
          },
          orange: {
            value: campaign.data.fetchCampaignMonitoring.totalViews,
            title: "pages.Campaign.Overview.views",
          },
          blue: {
            value: campaign.data.fetchCampaignMonitoring.totalSpent,
            title: "pages.Campaign.Overview.spent",
          },
        }}
      >
        <Select
          options={weekMap}
          styles={customStyles}
          placeholder={latestWeek}
        />
      </StatisticView>
    )
  );
};

CampaignStatistic.propTypes = {
  campaignId: PropTypes.string,
  campaign: PropTypes.any,
  dateFrom: PropTypes.instanceOf(Date),
  dateTo: PropTypes.instanceOf(Date),
};

export default CampaignStatistic;
