import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import ServiceProvider from "@client.services/provider";
import "react-toastify/dist/ReactToastify.css";
import "./Toast.scss";

export const TOAST_EVENT = "Toast";

const Toast = () => {
  const { t } = useTranslation();

  useEffect(() => {
    ServiceProvider.EventEmitter.on(TOAST_EVENT, (messages) => {
      messages.forEach((message) => {
        switch (message.type) {
          case "info":
            toast.info(message.msg);
            break;
          case "warning":
            toast.warn(message.msg);
            break;
          case "error":
            toast.error(t(message.msg));
            break;
          case "success":
            toast.success(message.msg);
            break;
        }
      });
    });
    return () => ServiceProvider.EventEmitter.removeAllListeners(TOAST_EVENT);
  }, []);

  return (
    <div className="toast">
      <ToastContainer />
    </div>
  );
};

export default Toast;
