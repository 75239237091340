import Title from "@client.components/Title";

import React from "react";

import ReportsOverview from "./ReportsOverview";
import DemoAnalysis from "./DemoAnalysis";
import TimeAnalysis from "./TimeAnalysis";
import "./ReportsPage.scss";

const ReportsPage = () => {
  return (
    <div className="reports page">
      <Title text="components.NavigationBar.reports" />
      <ReportsOverview />
      <div className="analasysis">
        <div>
          <DemoAnalysis />
        </div>
        <div>
          <TimeAnalysis />
        </div>
      </div>
    </div>
  );
};

export default ReportsPage;
