import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./Countdown.scss";
import { faClockFour } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const Countdown = ({ campaign }) => {
  const { t } = useTranslation();
  const calculateTimeLeft = () => {
    const difference = new Date(campaign.startDate) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  const timerComponents = [
    `${formatTime(timeLeft.days)}:${formatTime(timeLeft.hours)}:${formatTime(timeLeft.minutes)}:${formatTime(timeLeft.seconds)}`,
  ];

  return (
    <div className="countdown-content">
      <FontAwesomeIcon size={"5x"} icon={faClockFour} />
      <div>{t("pages.Campaign.CountDown")}</div>
      <div className="timer">{timerComponents}</div>
    </div>
  );
};

Countdown.propTypes = {
  campaign: PropTypes.any,
};

export default Countdown;
