import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as Popover from "@radix-ui/react-popover";
import Icon from "@client.components/Icon";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./MediaActions.scss";

// eslint-disable-next-line no-unused-vars
const MediaActions = ({ advertiserId, mediaId }) => {
  const { t } = useTranslation();

  const handleDelete = () => {};

  const actions = [
    {
      name: "Delete",
      icon: faTrash,
      onClick: handleDelete,
    },
  ];

  return (
    <div className="media-actions-control">
      <Popover.Root>
        <Popover.Trigger asChild>
          <button className="three-dots-btn three-dots">
            <Icon name="vertical-dots" />
          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className="nm-popover-content custom-popover"
            sideOffset={10}
            alignOffset={110}
          >
            <div className="campaign-actions">
              {actions.map((action) => (
                <div
                  className="action"
                  key={action.name}
                  onClick={action.onClick}
                >
                  <FontAwesomeIcon icon={action.icon} />
                  {t(action.name)}
                </div>
              ))}
            </div>
            <Popover.Arrow className="popover-arrow" />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

MediaActions.propTypes = {
  advertiserId: PropTypes.string,
  mediaId: PropTypes.string,
};

export default React.memo(MediaActions);
