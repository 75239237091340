import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Button from "@client.components/Button";
import "./ToggleButton.scss";

export const ToggleButton = ({ value, onChange, className, text }) => {
  return (
    <Button
      text={text}
      className={classNames("toggle-button", className, { toggled: value })}
      onClick={() => onChange(!value)}
    />
  );
};

ToggleButton.defaultProps = {
  className: "",
  text: "",
};

ToggleButton.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
