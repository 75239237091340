import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DateRangePicker from "@client.components/DateRangePicker";
import DataTable from "@client.components/DataTable";
import Dropdown from "@client.components/Dropdown";
import { getColumns } from "./consts";
import { useTranslation } from "react-i18next";
import {
  createDeviceAsync,
  deleteDeviceAsync,
  getAllDevicesAsync,
  makeDevices,
  makeDevicesFilter,
  setFilter,
  updateDeviceAsync,
} from "./reducer";
import Button from "@client.components/Button";
import ServiceProvider from "@client.services/provider";
import { MODAL_EVENT } from "@client.components/Modal";
import DeviceManagement from "./DeviceManagement";
import DeviceDetails from "./DeviceDetails";
import SvgIcon from "@client.components/SvgIcon";
import { useCallback } from "react";
import useToast from "@client.hooks/useToast";
import { useNavigate } from "react-router-dom";
import Pages from "@client.enums/pages";
import DeviceModel from "@client.models/device";
import Input from "@client.components/Input";
import Title from "@client.components/Title";
import DeviceStatuses from "@client.enums/deviceStatuses";
import DeviceTypes from "@client.enums/deviceTypes";
import DevicesToApprove from "./DevicesToApprove/DevicesToApprove";
import "./Devices.scss";

const ITEMS_PER_PAGE = 10;

const DevicesPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showToastAfterRequest } = useToast();
  const data = useSelector(makeDevices);
  const filter = useSelector(makeDevicesFilter);
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const gridRef = useRef(null);
  const [model, setModel] = React.useState(new DeviceModel());
  const [formData, setFormData] = useState({
    deviceName: "",
    machineId: "",
    softwareVersion: "",
    hardwareVersion: "",
    timezoneCode: "",
    defaultMedia: "",
  });
  useEffect(() => {
    setModel(
      new DeviceModel(
        {
          ...model,
          timezoneCode: formData.timezoneCode, // Update timezoneCode in the model with the value from formData
        },
        model.validationMsgs
      )
    );
  }, [formData, setModel]);

  useEffect(() => {
    setModel(
      new DeviceModel(
        { ...setFormData },
        {
          deviceName: t("pages.Devices.validation.name"),
          softwareVersion: t("pages.Devices.validation.softwareVersion"),
          hardwareVersion: t("pages.Devices.validation.hardwareVersion"),
          accountId: t("pages.Devices.validation.accountId"),
          vpnId: t("pages.Devices.validation.vpnId"),
        }
      )
    );
  }, [t, setFormData]);

  useEffect(() => {
    const offset = (currentPage - 1) * ITEMS_PER_PAGE;
    handleFilterChange({
      offset,
      limit: ITEMS_PER_PAGE,
    });
  }, [currentPage]);

  useEffect(() => {
    dispatch(getAllDevicesAsync());
  }, [dispatch, currentPage, filter]);

  const onSelectionChanged = (d) => {
    switch (d.colDef.field) {
      case "Name":
      case "index":
        navigate(`${Pages.Devices}/${d.data.lookupId}`);
        break;
      case undefined:
        break;
      default:
        // eslint-disable-next-line no-case-declarations
        const row = gridRef.current.api.getSelectedRows()[0];
        setSelectedRow(row);
        break;
    }
  };

  const createTxt = t("pages.Devices.createDevice");
  const updateTxt = t("pages.Devices.updateDevice");
  const approvalTxt = t("pages.Devices.deviceApproval");

  const onDeviceManagement = useCallback(
    (device) => {
      ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
        show: true,
        withBodyRef: true,
        title: device.lookupId ? updateTxt : createTxt,
        component: <DeviceManagement device={device} />,
        buttonSettings: {
          text: t(
            device.lookupId ? "pages.Devices.update" : "pages.Devices.create"
          ),
          onClick: async (component) => {
            const isValid = component.validate();
            return isValid
              ? dispatch(
                  component.managementItem.lookupId
                    ? updateDeviceAsync(component.managementItem)
                    : createDeviceAsync(component.managementItem)
                ).then((resp) => {
                  showToastAfterRequest(
                    resp,
                    "pages.Devices.deviceCreated"
                  ).then(() => {
                    ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
                      show: false,
                    });
                  });
                })
              : Promise.resolve();
          },
        },
      });
    },
    [createTxt, dispatch, showToastAfterRequest, t]
  );

  const statuses = [
    {
      value: DeviceStatuses.All,
      label: t("pages.Devices.status.all"),
    },
    {
      value: DeviceStatuses.Online,
      label: t("pages.Devices.status.online"),
    },
    {
      value: DeviceStatuses.Offline,
      label: t("pages.Devices.status.offline"),
    },
  ];

  const types = [
    {
      value: DeviceTypes.Unknown,
      label: t("pages.Devices.status.all"),
    },
    {
      value: DeviceTypes.Static,
      label: t("pages.Devices.type.static"),
    },
    {
      value: DeviceTypes.Dynamic,
      label: t("pages.Devices.type.dynamic"),
    },
  ];

  const onEdit = useCallback(
    (rowData) => {
      onDeviceManagement(rowData);
    },
    [onDeviceManagement]
  );

  const onView = useCallback((id) => {
    navigate(`${Pages.Devices}/${id}`);
  }, []);

  const handleDeviceApprovalClick = () => {
    ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
      show: true,
      title: approvalTxt,
      className: "devices-to-approve-modal",
      component: <DevicesToApprove />,
    });
  };

  const onDelete = useCallback(
    (deviceId) => {
      dispatch(deleteDeviceAsync(deviceId)).then((resp) => {
        if (resp.payload.errors[0].message) {
          alert("this device is in use");
        }

        showToastAfterRequest(resp, "pages.Devices.deviceDeleted");
        setSelectedRow(null);
        ServiceProvider.EventEmitter.emit(MODAL_EVENT, {});
      });
    },
    [dispatch, showToastAfterRequest]
  );

  const handleFilterChange = (request) => {
    dispatch(
      setFilter({
        ...filter,
        ...request,
      })
    );
  };
  return (
    <div className="devices page">
      <div className="content">
        <div className="content-grid">
          <Title text="components.NavigationBar.devices">
            <div className="management-buttons">
              <Button
                icon="check"
                text={approvalTxt}
                onClick={handleDeviceApprovalClick}
                className="button-create"
              />
              <Button
                icon="plus"
                text={createTxt}
                onClick={() => onDeviceManagement({})}
                className="button-create"
              />
            </div>
          </Title>
          <div className="search">
            <Input
              label="Search"
              onChange={(v) =>
                handleFilterChange({
                  accountLookupId: v,
                })
              }
              value={filter.accountLookupId}
            />
            <DateRangePicker
              dateFrom={
                filter.startDate === "" ? undefined : new Date(filter.startDate)
              }
              dateTo={
                filter.endDate === "" ? undefined : new Date(filter.endDate)
              }
              label={t("pages.Devices.dateRange")}
              onChange={(v) => {
                handleFilterChange({
                  startDate: v === null ? "" : v[0].toISOString(),
                  endDate: v === null ? "" : v[1].toISOString(),
                });
              }}
            />
            <Dropdown
              items={statuses}
              defaultValue={filter.isOnline}
              label={t("pages.Devices.status")}
              valueKey="value"
              labelKey="label"
              onSelect={(v) => {
                handleFilterChange({
                  isOnline: v,
                });
              }}
            />
            <Dropdown
              items={types}
              defaultValue={filter.filterType}
              label={t("pages.Devices.type")}
              valueKey="value"
              labelKey="label"
              onPrepareValue={(v) => parseInt(v)}
              onSelect={(v) => {
                handleFilterChange({
                  filterType: v,
                });
              }}
            />
          </div>
          <DataTable
            ref={gridRef}
            columns={getColumns(t, onView, onEdit, onDelete)}
            data={data.results}
            isLoading={data.loading}
            onSelectionChanged={onSelectionChanged}
            pagination={{
              total: data.count,
              itemsPerPage: ITEMS_PER_PAGE,
              onChange: setCurrentPage,
            }}
          />
        </div>
        {selectedRow ? (
          <DeviceDetails
            id={selectedRow.lookupId}
            onView={() => onView(selectedRow.lookupId)}
            onDelete={() => onDelete(selectedRow.lookupId)}
          />
        ) : (
          <div className="no-selection">
            <SvgIcon name="no-address" />
            <span>{t("pages.Devices.noDeviceSelected")}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default DevicesPage;
