const Pages = Object.freeze({
  VpnManagement: "vpn/management",
  CampaignApprovals: "campaign/approvals",
  RegisterUser: "/user/register",
  InviteUser: "/user/invite",
  Login: "/login",
  Logout: "/logout",
  ProfileGeneral: "/profile",
  ProfileBilling: "/profile/billing",
  ProfileNotifications: "/profile/notifications",
  ProfileChangePassword: "/profile/changePassword",
  ProfilePayment: "/profile/payment",
  Dashboard: "/dashboard",
  CreateCampaign: "/createCampaign",
  Devices: "/devices",
  Users: "/users",
  Campaigns: "/campaigns",
  Locations: "/locations",
  ForgotPassword: "/forgotPassword",
  FlickrDemo: "/flickrDemo",
  Advertiser: "/advertiser",
  Campaign: "/campaign",
  MediaLibrary: "/mediaLibrary",
  ActiveCampaigns: "/activeCampaigns",
  PausedCampaigns: "/pausedCampaigns",
  DraftCampaigns: "/draftCampaigns",
  ArchivedCampaigns: "/archivedCampaigns",
  Reports: "/reports",
  VpnServer: "/vpnServer",
  Accounts: "/accounts",
});

export default Pages;
