/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./UsersForm.scss";
import Input from "@client.components/Input";
import { useTranslation } from "react-i18next";
import RoleDropdown from "@client.components/RoleDropdown";
import Password from "@client.components/Password";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

const UsersForm = ({
  formData,
  onInputChange,
  onSubmit,
  model,
  changeModel,
  setShowForm,
  handleOptionSelect,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`blur-container`}>
      <div className="form-container">
        <form className="form-container user-form" onSubmit={onSubmit}>
          <span className="close-form" onClick={() => setShowForm(false)}>
            <FontAwesomeIcon icon={faX} />
          </span>
          <h2>Create New User</h2>
          <div>
            <Input
              label="First Name"
              value={model.firstName}
              onChange={(v) => changeModel(v, "firstName")}
            />
          </div>
          <div>
            <Input
              label="Last Name"
              value={model.lastName}
              onChange={(v) => changeModel(v, "lastName")}
            />
          </div>
          <div>
            <Password
              id="pswrd"
              value={model.password}
              label={t("components.Advertisers.password")}
              onChange={(v) => {
                changeModel(v, "password");
              }}
            />
          </div>

          <div>
            <Input
              value={model.emailAddress}
              onChange={(v) => changeModel(v, "emailAddress")}
              label={t("components.Advertisers.emailAddress")}
            />
          </div>
          <div>
            <Input
              type="tel"
              value={model.phoneNumber}
              onChange={(v) => changeModel(v, "phoneNumber")}
              label={t("components.Advertisers.phoneNumber")}
              maxLength={15}
            />
          </div>

          <div>
            <Input
              value={model.companyName}
              onChange={(v) => changeModel(v, "companyName")}
              label={t("components.Advertisers.companyName")}
            />
          </div>
          <div style={{ marginBottom: "40px" }}>
            <RoleDropdown
              value={model.roleId}
              label={t("components.Advertisers.role")}
              onSelect={(val) => {
                changeModel(val, "roleId");
              }}
            />
          </div>

          <button className="button" type="submit">
            <span>Create</span>
          </button>
        </form>
      </div>
    </div>
  );
};

UsersForm.propTypes = {
  formData: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    emailAddress: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    samePassword: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    roleId: PropTypes.string.isRequired,
    // Add PropTypes for other fields as needed
  }).isRequired,
  onInputChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  changeModel: PropTypes.func.isRequired,
  model: PropTypes.any,
  handleOptionSelect: PropTypes.func.isRequired,
  setShowForm: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
};
export default UsersForm;
