import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Tabs from "@client.components/Tabs";
import { useLocation, useNavigate } from "react-router-dom";
import Pages from "@client.enums/pages";
import PropTypes from "prop-types";
import "./ProfileTabs.scss";

const ProfileTabsEnum = Object.freeze({
  General: "general",
  Billing: "billing",
  Payment: "payment",
  Notifications: "notifications",
  ChangePassword: "changePassword",
});

const ProfileTabs = ({ profileId }) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(ProfileTabsEnum.General);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (profileId) {
      switch (location.pathname) {
        case `${Pages.ProfileGeneral}/${profileId}`:
          setSelectedTab(ProfileTabsEnum.General);
          break;
        case `${Pages.ProfileBilling}/${profileId}`:
          setSelectedTab(ProfileTabsEnum.Billing);
          break;
        case `${Pages.ProfileNotifications}/${profileId}`:
          setSelectedTab(ProfileTabsEnum.Notifications);
          break;
        case `${Pages.ProfileChangePassword}/${profileId}`:
          setSelectedTab(ProfileTabsEnum.ChangePassword);
          break;
        case `${Pages.ProfilePayment}/${profileId}`:
          setSelectedTab(ProfileTabsEnum.Payment);
          break;
      }
    } else {
      switch (location.pathname) {
        case Pages.ProfileGeneral:
          setSelectedTab(ProfileTabsEnum.General);
          break;
        case Pages.ProfileBilling:
          setSelectedTab(ProfileTabsEnum.Billing);
          break;
        case Pages.ProfileNotifications:
          setSelectedTab(ProfileTabsEnum.Notifications);
          break;
        case Pages.ProfileChangePassword:
          setSelectedTab(ProfileTabsEnum.ChangePassword);
          break;
        case Pages.ProfilePayment:
          setSelectedTab(ProfileTabsEnum.Payment);
          break;
      }
    }
  }, [location]);

  const tabs = [
    {
      id: ProfileTabsEnum.General,
      name: t("components.ProfileTabs.general"),
      icon: "profile",
    },
    {
      id: ProfileTabsEnum.Billing,
      name: t("components.ProfileTabs.billing"),
      icon: "billing",
    },
    {
      id: ProfileTabsEnum.Payment,
      name: t("components.ProfileTabs.paymentmethod"),
      icon: "credit-card",
    },
    {
      id: ProfileTabsEnum.Notifications,
      name: t("components.ProfileTabs.notifications"),
      icon: "bell",
    },
    {
      id: ProfileTabsEnum.ChangePassword,
      name: t("components.ProfileTabs.changePassword"),
      icon: "changePassword",
    },
  ];

  return (
    <>
      {profileId ? (
        <Tabs
          tabs={tabs}
          className="profile-tabs"
          value={selectedTab}
          onSelect={(v) => {
            switch (v) {
              case ProfileTabsEnum.General:
                navigate(`${Pages.ProfileGeneral}/${profileId}`);
                break;
              case ProfileTabsEnum.Billing:
                navigate(`${Pages.ProfileBilling}/${profileId}`);
                break;
              case ProfileTabsEnum.Notifications:
                navigate(`${Pages.ProfileNotifications}/${profileId}`);
                break;
              case ProfileTabsEnum.ChangePassword:
                navigate(`${Pages.ProfileChangePassword}/${profileId}`);
                break;
              case ProfileTabsEnum.Payment:
                navigate(`${Pages.ProfilePayment}/${profileId}`);
                break;
            }
          }}
        />
      ) : (
        <Tabs
          tabs={tabs}
          className="profile-tabs"
          value={selectedTab}
          onSelect={(v) => {
            switch (v) {
              case ProfileTabsEnum.General:
                navigate(Pages.ProfileGeneral);
                break;
              case ProfileTabsEnum.Billing:
                navigate(Pages.ProfileBilling);
                break;
              case ProfileTabsEnum.Notifications:
                navigate(Pages.ProfileNotifications);
                break;
              case ProfileTabsEnum.ChangePassword:
                navigate(Pages.ProfileChangePassword);
                break;
              case ProfileTabsEnum.Payment:
                navigate(Pages.ProfilePayment);
                break;
            }
          }}
        />
      )}
    </>
  );
};
ProfileTabs.propTypes = {
  profileId: PropTypes.string.isRequired,
};

export default React.memo(ProfileTabs);
