import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "@client.pages/Login/reducer";
import advertiserReducer from "@client.pages/Advertiser/reducer";
import dashboardReducer from "@client.pages/Dashboard/reducer";
import campaignReducer from "@client.pages/Campaign/reducer";
import deviceReducer from "@client.pages/Devices/reducer";
import profileReducer from "@client.pages/ProfileGeneral/reducer";
import locationReducer from "@client.pages/Locations/reducer";
import vpnManagementReducer from "@client.pages/VpnManagement/reducer";

export const getStore = (initialState = null) => {
  const optionalSettings = {};
  if (initialState) {
    optionalSettings.preloadedState = initialState;
  }
  return configureStore({
    reducer: {
      login: loginReducer,
      campaign: campaignReducer,
      advertiser: advertiserReducer,
      dashboard: dashboardReducer,
      device: deviceReducer,
      profile: profileReducer,
      location: locationReducer,
      vpnManagement: vpnManagementReducer,
    },
    ...optionalSettings,
  });
};
