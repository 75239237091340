import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import { AuthProvider } from "@client.hooks/useAuth";
import reportWebVitals from "./reportWebVitals";
import { getStore } from "./store";
import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={getStore()}>
    <BrowserRouter>
      <AuthProvider>
        <Routes />
        <div id="root-portal"></div>
      </AuthProvider>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
