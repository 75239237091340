import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "@client.services/provider";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";

const DEFAULT_SELECTED_ADVERTISER = { advertiser: null, loading: false };
const DEFAULT_SELECTED_CAMPAIGNS = { campaigns: [], loading: false };
const DEFAULT_SELECTED_ASSETS = { assets: [], loading: false };
const DEFAULT_SELECTED_DRAFT = { campaign: null, loading: false };

const initialState = {
  advertisers: [],
  error: null,
  loading: false,
  selected: DEFAULT_SELECTED_ADVERTISER,
  selectedCampaigns: DEFAULT_SELECTED_CAMPAIGNS,
  selectedDrafts: DEFAULT_SELECTED_CAMPAIGNS,
  selectedAssets: DEFAULT_SELECTED_ASSETS,
  selectedDraft: DEFAULT_SELECTED_DRAFT,
};

const getAdvertiserIndex = (state, advertiser) => {
  let index = 0;
  const { advertisers } = state;
  if (advertiser && advertisers.length > 0) {
    for (let i = 0; i < advertisers.length; i++) {
      if (advertiser.lookupId === advertisers[i].lookupId) {
        break;
      }
      index++;
    }
  }
  return index;
};

export const getAllAdvertisersAsync = createAsyncThunk(
  "advertiser/getall",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      return await ServiceProvider.Advertiser.getAll();
    } finally {
      thunkAPI.dispatch(setLoading(false));
    }
  }
);

export const createAdvertiserAsync = createAsyncThunk(
  "advertiser/create",
  async (advertiser, thunkAPI) => {
    const resp = await ServiceProvider.Advertiser.create(advertiser);

    if (!resp.errors) {
      thunkAPI.dispatch(getAllAdvertisersAsync());
    }
    return resp;
  }
);

export const getAdvertiserByIdAsync = createAsyncThunk(
  "advertiser/getById",
  async (advertiserId, thunkAPI) => {
    thunkAPI.dispatch(setLoadingSelectedAdvertiser(true));
    try {
      return await ServiceProvider.Advertiser.getById(advertiserId);
    } finally {
      thunkAPI.dispatch(setLoadingSelectedAdvertiser(false));
    }
  }
);

export const getAdvertiserCampaignsAsync = createAsyncThunk(
  "advertiser/getCampaigns",
  async (advertiserId, thunkAPI) => {
    thunkAPI.dispatch(setLoadingSelectedCampaigns(true));
    try {
      return await ServiceProvider.Campaign.getAll(advertiserId);
    } finally {
      thunkAPI.dispatch(setLoadingSelectedCampaigns(false));
    }
  }
);

export const getAdvertiserDraftsAsync = createAsyncThunk(
  "advertiser/getDrafts",
  async (advertiserId, thunkAPI) => {
    thunkAPI.dispatch(setLoadingSelectedDrafts(true));
    try {
      return await ServiceProvider.CampaignDraft.getAll(advertiserId);
    } finally {
      thunkAPI.dispatch(setLoadingSelectedDrafts(false));
    }
  }
);

export const getCampaignDraftByIdAsync = createAsyncThunk(
  "advertiser/getDraftById",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(setLoadingSelectedDraft(true));
    try {
      return await ServiceProvider.CampaignDraft.getById(id);
    } finally {
      thunkAPI.dispatch(setLoadingSelectedDraft(false));
    }
  }
);

export const getSelectedAssetsAsync = createAsyncThunk(
  "advertiser/getAllAssets",
  async (advertiserId, thunkAPI) => {
    thunkAPI.dispatch(setLoadingAssets(true));
    try {
      return await ServiceProvider.Advertiser.getAllAssets(advertiserId);
    } finally {
      thunkAPI.dispatch(setLoadingAssets(false));
    }
  }
);

export const uploadUserAssetAsync = createAsyncThunk(
  "advertiser/uploadAsset",
  async (asset, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const response = await ServiceProvider.Advertiser.uploadAsset(asset);
      return response;
    } finally {
      thunkAPI.dispatch(setLoading(false));
    }
  }
);

export const saveCampaignDraftAsync = createAsyncThunk(
  "advertiser/saveDraft",
  async (asset, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      return await ServiceProvider.CampaignDraft.save(asset);
    } finally {
      thunkAPI.dispatch(setLoading(false));
    }
  }
);

export const advertiserSlice = createSlice({
  name: "advertiser",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = get(action, "payload", false);
    },
    setLoadingSelectedAdvertiser: (state, action) => {
      state.selected.loading = get(action, "payload", false);
    },
    setLoadingSelectedCampaigns: (state, action) => {
      state.selectedCampaigns.loading = get(action, "payload", false);
    },
    setLoadingSelectedDrafts: (state, action) => {
      state.selectedDrafts.loading = get(action, "payload", false);
    },
    setLoadingSelectedDraft: (state, action) => {
      state.selectedDraft.loading = get(action, "payload", false);
    },
    setLoadingAssets: (state, action) => {
      state.selectedAssets.loading = get(action, "payload", false);
    },
    resetSelectedAdvertiser: (state) => {
      state.selected = { ...DEFAULT_SELECTED_ADVERTISER };
    },
    resetSelectedDraft: (state) => {
      state.selectedDraft = { ...DEFAULT_SELECTED_DRAFT };
    },
    clearAdvertiserError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdvertiserCampaignsAsync.fulfilled, (state, action) => {
        state.selectedCampaigns.campaigns = get(
          action,
          "payload.data.fetchUserCampaigns",
          []
        );
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getAdvertiserCampaignsAsync.rejected, (state, action) => {
        state.selectedCampaigns = {
          ...DEFAULT_SELECTED_CAMPAIGNS,
        };
        state.error = getResponseError(action);
      })
      .addCase(getAdvertiserDraftsAsync.fulfilled, (state, action) => {
        state.selectedDrafts.campaigns = get(
          action,
          "payload.data.fetchUserDraftCampaigns",
          []
        );
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getAdvertiserDraftsAsync.rejected, (state, action) => {
        state.selectedDrafts = {
          ...DEFAULT_SELECTED_CAMPAIGNS,
        };
        state.error = getResponseError(action);
      })
      .addCase(getCampaignDraftByIdAsync.fulfilled, (state, action) => {
        state.selectedDraft.campaign = get(
          action,
          "payload.data.fetchDraftCampaign",
          null
        );
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getCampaignDraftByIdAsync.rejected, (state, action) => {
        state.selectedDraft = {
          ...DEFAULT_SELECTED_DRAFT,
        };
        state.error = getResponseError(action);
      })
      .addCase(getSelectedAssetsAsync.fulfilled, (state, action) => {
        state.selectedAssets.assets =
          get(action, "payload.data.fetchUserFiles", []) || [];
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getSelectedAssetsAsync.rejected, (state, action) => {
        state.selectedAssets = DEFAULT_SELECTED_ASSETS;
        state.error = getResponseError(action);
      })
      .addCase(getAllAdvertisersAsync.fulfilled, (state, action) => {
        state.advertisers = get(
          action,
          "payload.data.fetchAccounts.results",
          []
        );
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getAllAdvertisersAsync.rejected, (state, action) => {
        state.advertisers = [];
        state.error = getResponseError(action);
      })
      .addCase(uploadUserAssetAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(uploadUserAssetAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      })
      .addCase(getAdvertiserByIdAsync.fulfilled, (state, action) => {
        const advertiser = get(action, "payload.data.fetchUser", null);
        state.selected.advertiser = {
          ...advertiser,
          index: getAdvertiserIndex(state, advertiser),
        };
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getAdvertiserByIdAsync.rejected, (state, action) => {
        state.selected.advertiser = { ...DEFAULT_SELECTED_ADVERTISER };
        state.error = getResponseError(action);
      })
      .addCase(createAdvertiserAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(createAdvertiserAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      });
  },
});

export const {
  setLoadingSelectedAdvertiser,
  setLoading,
  resetSelectedAdvertiser,
  resetSelectedDraft,
  setLoadingSelectedCampaigns,
  setLoadingSelectedDrafts,
  setLoadingSelectedDraft,
  setLoadingAssets,
  clearAdvertiserError,
} = advertiserSlice.actions;

export const makeAdvertisers = (state) => state.advertiser.advertisers;
export const makeAdvertisersLoading = (state) => state.advertiser.loading;
export const makeSelectedAdvertiser = (state) => state.advertiser.selected;
export const makeSelectedCampaigns = (state) =>
  state.advertiser.selectedCampaigns;
export const makeSelectedDrafts = (state) => state.advertiser.selectedDrafts;
export const makeSelectedDraft = (state) => state.advertiser.selectedDraft;
export const makeSelectedAssets = (state) => state.advertiser.selectedAssets;
export const makeAdvertiserError = (state) => state.advertiser.error;

export default advertiserSlice.reducer;
