import React, { useState } from "react";
import SvgIcon from "@client.components/SvgIcon";

export const MediaFile = (params) => {
  const [isImageNotFound, setIsImageNotFound] = useState(false);
  const { fallbackElement, isNotFound, onLoad, ...mediaProps } = params;
  const isVideoFile = (url) => {
    const urlParts = url.split(".");
    const extension = urlParts[urlParts.length - 1].toLowerCase();
    if (extension === "mp4") return extension === "mp4";
  };

  const renderMediaFile = (mediaParams) => {
    return isVideoFile(mediaParams.src) ? (
      <video
        {...mediaParams}
        muted
        autoPlay
        loop={true}
        onLoadedMetadata={(e) => {
          if (onLoad) {
            onLoad({
              width: e.nativeEvent.target.videoWidth,
              height: e.nativeEvent.target.videoHeight,
            });
          }
        }}
        onError={() => {
          setIsImageNotFound(true);
        }}
      >
        <source src={mediaParams.src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    ) : (
      <img
        {...mediaParams}
        onLoad={(e) => {
          if (onLoad) {
            onLoad({
              width: e.target.naturalWidth,
              height: e.target.naturalHeight,
            });
          }
        }}
        onError={() => {
          setIsImageNotFound(true);
        }}
      />
    );
  };

  return isNotFound || isImageNotFound ? (
    fallbackElement ? (
      fallbackElement()
    ) : (
      <SvgIcon name="file-not-found" className={params.className} />
    )
  ) : (
    renderMediaFile(mediaProps)
  );
};
