import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Pages from "@client.enums/pages";
import CampaignInfo from "@client.components/CampaignInfo";
import { getCampaignByIdAsync, makeSelectedCampaign } from "./reducer";
import Loader from "@client.components/Loader";
import { useTranslation } from "react-i18next";
import Overview from "./Overview";
import useStableParams from "@client.hooks/useStableParams";
import "./Campaign.scss";
import Countdown from "@client.components/Countdown";

const CampaignPage = () => {
  const context = useOutletContext();
  const dispatch = useDispatch();
  const selectedCampaign = useSelector(makeSelectedCampaign);

  const navigate = useNavigate();
  const { campaign } = selectedCampaign;
  const { advertiserId, id } = useStableParams();
  const { t } = useTranslation();
  const [endate, setEndate] = useState(false);
  const [startdate, setStartdate] = useState(false);
  const currentDate = new Date();
  
  useEffect(() => {
    if (!campaign || campaign.lookupId != id) {
      dispatch(getCampaignByIdAsync(id));
      
    }
    const campaignStart = new Date(campaign && campaign.startDate)
    const campaignEnd = new Date(campaign && campaign.endDate)
    const isStartdateStarted = currentDate >= campaignStart;
      const isEndDatePast = campaignEnd < currentDate;
      setEndate(isEndDatePast)
      setStartdate(isStartdateStarted)
     
      
  }, [dispatch, campaign, id, endate, startdate]);

  useEffect(() => {
    context.setShowAdvertiser(true);

    context.setNavigationLink({
      to: `${Pages.Advertiser}/${advertiserId}`,
      name: "pages.Campaign.TopBar.backToAdvertiser",
    });

    return () => {
      context.setShowAdvertiser(false);
      context.setNavigationLink(null);
    };
  }, [navigate, dispatch, advertiserId]);

  if (selectedCampaign.loading) {
    return (
      <div className="campaign page-loading">
        <Loader loading={true} />
      </div>
    );
  }

  if (!campaign) {
    return (
      <div className="campaign not-found">
        {t("pages.Campaign.campaignNotFound")}
      </div>
    );
  }
  

  return (
    <div className="campaign">
     {campaign && !startdate && !endate && (
  <div className="countdown-container">
    <Countdown campaign={campaign} />
  </div>
)}
{campaign && (startdate || endate) && (
  
    <Overview campaign={campaign} campaignId={id} />
  
)}

<CampaignInfo model={campaign} estimation={null} />

    </div>
  );
};

export default CampaignPage;
