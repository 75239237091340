import React, { useEffect } from "react";
import Title from "@client.components/Title";
import TopBoardOverview from "@client.components/TopBoardOverview";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPlatformDashboardAsync,
  makeDashboardFilter,
  selectPlatformDashboard,
} from "./reducer";
import DevicesOverview from "./DevicesOverview";
import "./Dashboard.scss";

const DashboardPage = () => {
  const overview = useSelector(selectPlatformDashboard);
  const dashboardFilter = useSelector(makeDashboardFilter);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPlatformDashboardAsync(dashboardFilter));
  }, [
    dispatch,
    dashboardFilter.enableLocations,
    dashboardFilter.enableHeatmap,
    dashboardFilter.id,
  ]);

  return (
    <div className="dashboard page">
      <Title text="components.NavigationBar.dashboard" />
      <TopBoardOverview
        activeCampaigns={
          overview
            ? overview.data.platformDashboard.infoBoxes.activeCampaigns
            : null
        }
        adPlays={
          overview
            ? overview.data.platformDashboard.infoBoxes.dailyAdPlays
            : null
        }
        adViews={
          overview
            ? overview.data.platformDashboard.infoBoxes.dailyAdViews
            : null
        }
        budgetSpent={
          overview
            ? overview.data.platformDashboard.infoBoxes.dailyBudgetSpent
            : null
        }
      />
      <DevicesOverview
        deviceMap={overview ? overview.data.platformDashboard.deviceMap : []}
        deviceHeatmap={
          overview ? overview.data.platformDashboard.deviceHeatmap : []
        }
        deviceLocation={
          overview ? overview.data.platformDashboard.deviceLocations : []
        }
      />
    </div>
  );
};

export default DashboardPage;
