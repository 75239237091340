import React, { useState } from "react";
import PropTypes from "prop-types";
import SvgIcon from "@client.components/SvgIcon";
import CampaignModel from "@client.models/campaign";
import "./WizardSteps.scss";

const WizardSteps = ({ steps, model, setModel }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className="wizard-steps">
      {steps.map((step, index) => (
        <div className="step" key={index}>
          <div className="identification">
            <SvgIcon
              name={
                model.isStageFilled(index)
                  ? "step-marker-active"
                  : "step-marker"
              }
            />
            <div className="connection"></div>
          </div>
          <div className="content">
            {React.cloneElement(step, {
              campaign: model,
              edit: activeIndex === index,
              onSave: (m) => {
                setActiveIndex(index + 1);
                setModel(new CampaignModel(m));
              },
              onEdit: () => setActiveIndex(index),
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

WizardSteps.propTypes = {
  steps: PropTypes.array,
  model: PropTypes.any,
  setModel: PropTypes.func,
};

export default WizardSteps;
