import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as Popover from "@radix-ui/react-popover";
import Icon from "@client.components/Icon";
import "./GridManagementButtons.scss";

const GridManagementButtons = ({ item, onView, onEdit, onDelete }) => {
  const { t } = useTranslation();
  return (
    <div
      className="grid-management-buttons"
      title={t("pages.Locations.GridManagementButtons.managementButtons")}
    >
      <Popover.Root>
        <Popover.Trigger asChild>
          <button className={"management-button"}>
            <Icon name="vertical-dots" />
          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content className="mb-popover-content mb-popover-locations">
          <div onClick={() => onView(item.lookupId)}>
              {t("pages.Locations.GridManagementButtons.View")}
            </div>
            <div onClick={() => onEdit(item)}>
              {t("pages.Locations.GridManagementButtons.update")}
            </div>
            <div onClick={() => onDelete(item.lookupId)}>
              {t("pages.Locations.GridManagementButtons.delete")}
            </div>
            <Popover.Arrow className="popover-arrow" />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

GridManagementButtons.propTypes = {
  onView: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  item: PropTypes.shape({
    lookupId: PropTypes.string,
  }),
};

export default GridManagementButtons;
