import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WizardSteps from "@client.pages/CreateCampaign/WizardSteps";
import GeneralInformation from "./Steps/GeneralInformation";
import Budget from "./Steps/Budget";
import Duration from "./Steps/Duration";
import Locations from "./Steps/Locations";
import CampaignModel from "@client.models/campaign";
import CampaignWizardStages from "@client.enums/campaignWizardStages";
import CampaignInfo from "@client.components/CampaignInfo";
import {
  getEstimationAsync,
  makeEstimation,
} from "@client.pages/Campaign/reducer";
import useStableParams from "@client.hooks/useStableParams";
import {
  getCampaignDraftByIdAsync,
  makeSelectedDraft,
  resetSelectedDraft,
} from "@client.pages/Advertiser/reducer";
import Loader from "@client.components/Loader";
import { useTranslation } from "react-i18next";
import "./CreateCampaign.scss";

const CreateCampaignPage = () => {
  const [model, setModel] = useState(new CampaignModel());
  const estimation = useSelector(makeEstimation);
  const selectedDraft = useSelector(makeSelectedDraft);
  const dispatch = useDispatch();
  const { draftId } = useStableParams();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getEstimationAsync());
  }, [dispatch]);

  useEffect(() => {
    if (draftId) {
      dispatch(getCampaignDraftByIdAsync(draftId));
    }

    return () => {
      dispatch(resetSelectedDraft());
    };
  }, [dispatch, draftId]);

  useEffect(() => {
    setModel(new CampaignModel(selectedDraft.campaign));
  }, [selectedDraft]);

  if (draftId && (selectedDraft.loading || !selectedDraft.campaign)) {
    return (
      <div className="create-campaign not-found">
        <Loader loading={selectedDraft.loading} />
        {!selectedDraft.loading &&
          !selectedDraft.campaign &&
          t("pages.CreateCampaign.draftNotFound")}
      </div>
    );
  }

  return (
    <div className="create-campaign">
      <WizardSteps
        model={model}
        setModel={(m) => setModel(m)}
        steps={[
          <GeneralInformation key={CampaignWizardStages.Info} />,
          <Budget key={CampaignWizardStages.Budget} />,
          <Duration key={CampaignWizardStages.Duration} />,
          <Locations key={CampaignWizardStages.Location} />,
        ]}
      />
      <CampaignInfo model={model} estimation={estimation} />
    </div>
  );
};

export default CreateCampaignPage;
