import React from "react";
import PropTypes from "prop-types";
import Title from "@client.components/Title";
import CampaignOverview from "@client.components/CampaignOverview";
import Button from "@client.components/Button";
import Pages from "@client.enums/pages";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import NoDataPlaceholder from "@client.components/NoDataPlaceholder";

const ArchivedCampaignsOverviews = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="archived-campaigns page">
      <Title text="components.NavigationBar.archivedCampaigns">
        <Button
          icon="plus"
          text={t("components.NavigationBar.createCampaign")}
          onClick={() => {
            navigate(Pages.CreateCampaign);
          }}
        />
      </Title>
      <div className="campaign-list">
        {data && data.length > 0 ? (
          data.map((d, index) => <CampaignOverview key={index} campaign={d} />)
        ) : (
          <NoDataPlaceholder description="No Archived Campaigns" />
        )}
      </div>
    </div>
  );
};
ArchivedCampaignsOverviews.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ArchivedCampaignsOverviews;
