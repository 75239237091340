import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Dropdown from "@client.components/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAdvertisersAsync,
  makeAdvertisers,
  makeAdvertisersLoading,
} from "@client.pages/Advertiser/reducer";
import "./AdvertiserDropdown.scss";

const AdvertiserDropdown = ({ value, onSelect, label, disabled }) => {
  const dispatch = useDispatch();
  const advertisers = useSelector(makeAdvertisers);
  const isLoading = useSelector(makeAdvertisersLoading);

  useEffect(() => {
    dispatch(getAllAdvertisersAsync());
  }, [dispatch]);

  return (
    <Dropdown
      items={advertisers.map((a) => {
        return {
          ...a,
          fullName: a.companyName || `${a.firstName} ${a.lastName}`,
        };
      })}
      disabled={isLoading || disabled}
      defaultValue={value}
      className="advertiser-dropdown"
      labelClassName="advertiser-background"
      label={label}
      valueKey="lookupId"
      labelKey="fullName"
      onSelect={onSelect}
    />
  );
};

AdvertiserDropdown.defaultProps = {
  value: null,
  label: null,
  disabled: false,
};

AdvertiserDropdown.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};

export default AdvertiserDropdown;
