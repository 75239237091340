import React, { useEffect, useState } from "react";
import GoogleMap from "@client.components/GoogleMap";
import "./Device.scss";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { useDispatch, useSelector } from "react-redux";
import { getDeviceByIdAsync, makeDeviceInfo } from "../reducer";
import Loader from "@client.components/Loader";
import useStableParams from "@client.hooks/useStableParams";
import { getProfileAsync } from "@client.pages/Login/reducer";
import Title from "@client.components/Title";
import DeviceOverview from "./DeviceOverview";
import NoDataPlaceholder from "@client.components/NoDataPlaceholder";
import DeviceMapFilter from "./DeviceMapFilter";

let followIntervalId = null;

const Device = () => {
  const dispatch = useDispatch();

  const { id } = useStableParams();
  const [isFollow, setIsFollow] = useState(false);
  const overview = useSelector(makeDeviceInfo);
  const loading = !overview || !overview.device;

  useEffect(() => {
    dispatch(getDeviceByIdAsync(id));
  }, [dispatch, id]);

  useEffect(() => {
    const clearFollowInterval = () => {
      if (followIntervalId) {
        clearInterval(followIntervalId);
      }
    };
    if (isFollow) {
      clearFollowInterval();
      followIntervalId = setInterval(
        () => dispatch(getDeviceByIdAsync(id)),
        1000
      );
    } else {
      clearFollowInterval();
    }

    return () => {
      clearFollowInterval();
    };
  }, [isFollow]);

  useEffect(() => {
    dispatch(getProfileAsync());
  }, []);

  if (loading) {
    return (
      <div>
        <Loader loading={true} />
      </div>
    );
  }
  if (!overview || !overview.device) {
    return <NoDataPlaceholder description="No device data available" />;
  }

  const interval = () => {};

  return (
    <div className="device-page page">
      <Title text={`Device: ${overview.device.deviceName}`} />
      <div className="content">
        <DeviceOverview />
        <GoogleMap
          interval={interval}
          width="100%"
          height="76vh"
          options={{
            mapTypeControl: false,
          }}
          markers={[
            {
              lat: overview.device.lastTelemetry
                ? overview.device.lastTelemetry.latitude
                : 55.6761,
              lng: overview.device.lastTelemetry
                ? overview.device.lastTelemetry.longitude
                : 12.5683,
            },
          ]}
        >
          <DeviceMapFilter
            onFilterChange={(filter) => {
              setIsFollow(filter.isFollow);
            }}
          />
        </GoogleMap>
      </div>
    </div>
  );
};

export default Device;
