import React from "react";
import PropTypes from "prop-types";
import Title from "@client.components/Title";
import CampaignOverview from "@client.components/CampaignOverview";
import Button from "@client.components/Button";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import Pages from "@client.enums/pages";
import NoDataPlaceholder from "@client.components/NoDataPlaceholder";

const ActiveCampaignsOverviews = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div className="active-campaigns page">
      <Title text="components.TopBoardOverview.activeCampaigns">
        <Button
          icon="plus"
          text={t("components.NavigationBar.createCampaign")}
          onClick={() => {
            navigate(Pages.CreateCampaign);
          }}
        />
      </Title>
      <div className="campaign-list">
        {data.length > 0 ? (
          data.map((d, index) => <CampaignOverview key={index} campaign={d} />)
        ) : (
          <NoDataPlaceholder description="No Active Campaigns" />
        )}
      </div>
    </div>
  );
};
ActiveCampaignsOverviews.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ActiveCampaignsOverviews;
