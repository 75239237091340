import BaseService from "@client.services/baseService";

export default class StatisticService extends BaseService {
  constructor() {
    super("statistic", true);
  }

  async getRevenue() {
    return new Promise((resolve) => {
      resolve([
        {
          name: "Mar",
          amt: 4000,
        },
        {
          name: "Apr",
          amt: 3000,
        },
        {
          name: "May",
          amt: 2000,
        },
        {
          name: "Jun",
          amt: 2500,
        },
        {
          name: "Jul",
          amt: 3100,
        },
        {
          name: "Aug",
          amt: 1000,
        },
        {
          name: "Sep",
          amt: 2100,
        },
        {
          name: "Oct",
          amt: 3500,
        },
      ]);
    });
  }

  async getTopSenders() {
    return new Promise((resolve) => {
      resolve([
        {
          name: "Aroma",
          amt: 2000,
        },
        {
          name: "Cafe Cafe",
          amt: 1500,
        },
        {
          name: "Mc Donald's",
          amt: 3500,
        },
      ]);
    });
  }
}
