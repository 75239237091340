import BaseModel from "./base";
import {get} from "lodash"
class ProfileModel extends BaseModel {
  constructor(user = {}, msg) {
    super(msg);

    this.lookupId = get(user, "lookupId", "");
    this.emailAddress = get(user, "emailAddress", "");
    this.lastName = get(user, "lastName", "");
    this.firstName = get(user, "firstName", "");
    this.avatarUrl = get(user, "avatarUrl", "");
    this.phoneNumber = get(user, "phoneNumber", "");
    this.companyName = get(user, "companyName", "");
    this.currencyCode = get(user, "currencyCode", "");
    this.currencySymbol = get(user, "currencySymbol", "");
    this.roleId = get(user, "roleId", "");

    this.validationMsgs = msg;
    this.validationRules = {
      firstName: {
        presence: { allowEmpty: false, message: msg.firstName },
      },
      lastName: {
        presence: { allowEmpty: false, message: msg.lastName },
      },
      emailAddress: {
        presence: { allowEmpty: false, message: msg.emailAddress },
        email: { message: msg.invalidEmail },
      },
      phoneNumber: {
        presence: { allowEmpty: false, message: msg.phoneNumber },
        
      },
      companyName: {
        presence: { allowEmpty: false, message: msg.companyName },
        
      },
      currencyCode: {
        presence: { allowEmpty: false, message: msg.currencyCode },
        
      },
      currencySymbol: {
        presence: { allowEmpty: false, message: msg.currencySymbol },
        
      },
      roleId: {
        presence: { allowEmpty: false, message: msg.roleId },
        
      },
    };
  }

  get fields() {
    return {
      emailAddress: this.emailAddress,
      lastName: this.lastName,
      firstName: this.firstName,
      avatarUrl: this.avatarUrl,
      phoneNumber: this.phoneNumber,
      companyName: this.companyName,
      currencyCode: this.currencyCode,
      currencySymbol: this.currencySymbol,
      roleId: this.roleId
    };
  }
}
export default ProfileModel