import React, { useEffect, useState } from "react";
import DataTable from "@client.components/DataTable";
import { useTranslation } from "react-i18next";
import moment from "moment";
import config from "@client.config";
import ServiceProvider from "@client.services/provider";

const ITEMS_PER_PAGE = 10;

const getColumns = () => [
  {
    field: "id",
    tooltipField: "id",
    headerName: "ID",
  },
  {
    field: "createdAt",
    headerName: "Created At",
    tooltipValueGetter: (params) =>
      moment(params.data.createdAt).format(config.dateTimeSecondsFormat),
    cellRenderer: (params) =>
      moment(params.data.createdAt).format(config.dateTimeSecondsFormat),
  },
  {
    field: "title",
    tooltipField: "title",
    headerName: "Title",
  },
  {
    field: "unitType",
    tooltipField: "unitType",
    headerName: "Unit Type",
  },
  {
    field: "unitId",
    tooltipField: "unitId",
    headerName: "Unit ID",
  },
  {
    field: "icon",
    tooltipField: "icon",
    headerName: "Icon",
  },
  {
    field: "description",
    tooltipField: "description",
    headerName: "Description",
  },
];

const LogsAlertsDataTable = () => {
  const { t } = useTranslation();
  const [data, setData] = useState({ count: 0, results: [] });
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const offset = (currentPage - 1) * ITEMS_PER_PAGE;
    setLoading(true);
    ServiceProvider.Dashboard.getSystemLogs(ITEMS_PER_PAGE, offset)
      .then((resp) => {
        setData(resp.data.fetchSystemLogs);
      })
      .finally(() => {
        setLoading(false);
      });
    setTimeout(() => setLoading(false), 1000);
  }, [currentPage]);

  return (
    <div className="logs-alerts">
      <DataTable
        columns={getColumns(t)}
        data={data.results}
        isLoading={loading}
        pagination={{
          total: data.count,
          ITEMS_PER_PAGE: ITEMS_PER_PAGE,
          onChange: setCurrentPage,
        }}
      />
    </div>
  );
};

export default LogsAlertsDataTable;
