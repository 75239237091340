import React, { useEffect } from "react";
import "./PausedCampaigns.scss";
import PausedCampaignsOverview from "@client.components/PausedCampaign";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdvertiserCampaignsAsync,
  makeSelectedCampaigns,
} from "@client.pages/Advertiser/reducer";
import useStableParams from "@client.hooks/useStableParams";

const PausedCampaignsPage = () => {
  const selectedCampaigns = useSelector(makeSelectedCampaigns);
  const { id } = useStableParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdvertiserCampaignsAsync(id));
  }, []);
  const statusPaused = selectedCampaigns.campaigns.filter(
    (obj) => obj.status === 1
  );
  return (
    <div className="paused-campaigns page">
      <PausedCampaignsOverview data={statusPaused} />
    </div>
  );
};

export default PausedCampaignsPage;
